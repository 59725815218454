/* Grid */
.fwc-container {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
}

.fwc-row {
  display: flex;
  max-width: calc(144 * var(--fwcu));
  margin: 0 auto;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.fwc-col-1 {
  width: 8.33%;
}
.fwc-col-2 {
  width: 16.6667%;
}
.fwc-col-3 {
  width: 25%;
}
.fwc-col-4 {
  width: 33.3333%;
}
.fwc-col-5 {
  width: 41.6667%;
}
.fwc-col-6 {
  width: 50%;
}
.fwc-col-7 {
  width: 58.3333%;
}
.fwc-col-8 {
  width: 66.6667%;
}
.fwc-col-9 {
  width: 75%;
}
.fwc-col-10 {
  width: 83.3333%;
}
.fwc-col-11 {
  width: 91.6667%;
}
.fwc-col-12,
.fwc-col-no-margin {
  width: 100%;
}

.fwc-col-bm {
  margin-bottom: var(--fwcu-sm);
}
.fwc-row-bm {
  margin-bottom: var(--fwcu-sm);
}

.fwc-row *[class^="fwc-col-"] {
  padding: 0 var(--fwcu-2xsm);
}
.fwc-row .fwc-col-no-margin {
  padding: 0;
}

@media screen and (max-width: 900px) {
  .fwc-row {
    flex-wrap: wrap;
  }
  .fwc-row.md-full *[class^="fwc-col-"] {
    padding: 0;
  }
  .fwc-row *[class^="fwc-col-"].md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .fwc-row *[class^="fwc-col-"].md-10 {
    flex: 0 0 auto;
    width: 83.3333%;
  }
  .fwc-row *[class^="fwc-col-"].md-8 {
    flex: 0 0 auto;
    width: 66.6667%;
  }
  .fwc-row *[class^="fwc-col-"].md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .fwc-row *[class^="fwc-col-"].md-4 {
    flex: 0 0 auto;
    width: 33.3333%;
  }
  .fwc-row *[class^="fwc-col-"].md-2 {
    flex: 0 0 auto;
    width: 16.6667%;
  }
  .fwc-row *[class^="fwc-col-"].md-0 {
    display: none;
  }

  .fwc-col-bm-md {
    margin-bottom: var(--fwcu-sm);
  }
  .fwc-row-bm-md {
    margin-bottom: var(--fwcu-sm);
  }
}

@media screen and (max-width: 600px) {
  .fwc-row.sm-full *[class^="fwc-col-"] {
    margin: 0;
  }
  .fwc-row *[class^="fwc-col-"].sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .fwc-row *[class^="fwc-col-"].sm-10 {
    flex: 0 0 auto;
    width: 83.3333%;
  }
  .fwc-row *[class^="fwc-col-"].sm-8 {
    flex: 0 0 auto;
    width: 66.6667%;
  }
  .fwc-row *[class^="fwc-col-"].sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .fwc-row *[class^="fwc-col-"].sm-4 {
    flex: 0 0 auto;
    width: 33.3333%;
  }
  .fwc-row *[class^="fwc-col-"].sm-2 {
    flex: 0 0 auto;
    width: 16.6667%;
  }
  .fwc-row *[class^="fwc-col-"].sm-0 {
    display: none;
  }

  .fwc-row {
    /*
      5*fwcu = 40px.
      40px + col-padding(12px)*2 = 64px.
      64px / 2 = 32px gutter for mobile
    */
    max-width: calc(100% - (5 * var(--fwcu)));
  }
  .fwc-col-bm-sm {
    margin-bottom: var(--fwcu-sm);
  }
  .fwc-row-bm-sm {
    margin-bottom: var(--fwcu-sm);
  }
}

/* Overflow Row w/ Snap Scroll*/
@media screen and (max-width: 900px) {
  .fwc-row.md-overflow {
    width: 100%;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
  }
  .fwc-row.md-overflow *[class^="fwc-col-"] {
    width: 100%;
    flex: 0 0 auto;
    scroll-snap-align: center;
  }
}

@media screen and (max-width: 600px) {
  .fwc-row.sm-overflow {
    width: 100%;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
  }
  .fwc-row.sm-overflow *[class^="fwc-col-"] {
    width: 100%;
    flex: 0 0 auto;
    scroll-snap-align: center;
  }
}

/* Smartphones and tablets only */
@media screen and (max-width: 600px) and (hover: none) and (pointer: coarse) {
  .fwc-row.sm-overflow {
    scrollbar-width: none;
  }
  .fwc-row.sm-overflow *[class^="fwc-col-"] {
    width: 92%;
  }
  .fwc-row.sm-overflow::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

@media screen and (max-width: 900px) and (hover: none) and (pointer: coarse) {
  .fwc-row.md-overflow {
    scrollbar-width: none;
  }
  .fwc-row.md-overflow *[class^="fwc-col-"] {
    width: 92%;
    padding: calc(0.75 * var(--fwcu));
  }
  .fwc-row.md-overflow::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

/* Flex helpers */
.fwc-a-base,
.fwc-flex.a-base {
  align-items: baseline;
}
.fwc-a-center,
.fwc-flex.a-center,
.fwc-row.a-center {
  align-items: center;
}
.fwc-a-end,
.fwc-flex.a-end,
.fwc-row.a-end {
  align-items: flex-end;
}
.fwc-a-start,
.fwc-flex.a-start,
.fwc-row.a-start {
  align-items: flex-start;
}
.fwc-a-stretch,
.fwc-flex.a-stretch,
.fwc-row.a-stretch {
  align-items: stretch;
}
.fwc-j-between,
.fwc-flex.j-between {
  justify-content: space-between;
}
.fwc-j-center,
.fwc-flex.j-center,
.fwc-row.j-center {
  justify-content: center;
}
.fwc-j-end,
.fwc-flex.j-end,
.fwc-row.j-end {
  justify-content: flex-end;
}
.fwc-f-wrap,
.fwc-flex.wrap,
.fwc-row-wrap {
  flex-wrap: wrap;
}

/* fwc-divider (style) */
.fwc-divider {
  background-color: var(--color-gray-five);
  height: calc(1.25 * var(--fwcu));
  margin-top: var(--fwcu-2xl);
  margin-bottom: var(--fwcu-2xl);
  width: 100%;
}
@media screen and (max-width: 600px) {
  .fwc-divider {
    margin-top: var(--fwcu-lg);
    margin-bottom: var(--fwcu-lg);
  }
}

/* fwc-image-bleed (template) */
.fwc-container.fwc-img-bleed-cont {
  color: var(--color-gray-one);
  background: var(--color-white);
  overflow: hidden;
}

.fwc-img-bleed-media {
  width: calc(100% + (32 * var(--fwcu)));
  height: 100%;
  overflow: overlay;
  max-width: none;
  box-shadow: var(--box-shadow);
  object-fit: cover;
}

.fwc-img-bleed-pad {
  padding: var(--fwcu-xl) 0;
}

.fwc-img-bleed-copy {
  margin: var(--fwcu-xsm) 0;
}

.fwc-img-bleed-cont.left-side .fwc-img-bleed-media {
  margin-left: calc(-32 * var(--fwcu));
}

@media screen and (max-width: 600px) {
  .fwc-container.fwc-img-bleed-cont.right-side .fwc-row {
    flex-direction: column-reverse;
  }

  .fwc-container.fwc-img-bleed-cont.left-side .fwc-row {
    flex-direction: column;
  }

  .fwc-img-bleed-pad {
    padding: 0;
  }

  .fwc-img-bleed-media {
    width: calc(100% + var(--fwcu-md));
  }

  .fwc-container.fwc-img-bleed-cont.left-side .fwc-img-bleed-media {
    margin-left: calc(-1 * var(--fwcu-md));
  }
}

/* end Image Bleed */
