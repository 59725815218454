$motif-font-family: 'employer-portal-motif' !important;

$motif-about: "\e900";
$motif-access-for-blind: "\e901";
$motif-add-phone: "\e902";
$motif-add-shopping-cart: "\e903";
$motif-add-tag: "\e904";
$motif-add-text: "\e905";
$motif-aed: "\e906";
$motif-aids-ribbon: "\e907";
$motif-alarm-clock: "\e908";
$motif-alluminium_massagetable: "\e909";
$motif-alphabetical-sorting: "\e90a";
$motif-alphabetical-sorting-2: "\e90b";
$motif-ambulance: "\e90c";
$motif-amnesty_international: "\e90d";
$motif-amputee: "\e90e";
$motif-angle-down: "\e90f";
$motif-angle-left: "\e910";
$motif-angle-right: "\e911";
$motif-angle-up: "\e912";
$motif-antiseptic-cream: "\e913";
$motif-apple: "\e914";
$motif-appointment-reminders: "\e915";
$motif-archived: "\eb25";
$motif-arrow: "\e916";
$motif-arrow-pointing-left: "\e917";
$motif-arrows-long-down: "\e918";
$motif-arrows-long-left: "\e919";
$motif-arrows-long-right: "\e91a";
$motif-arrows-long-up: "\e91b";
$motif-assistive_listening_systems: "\e91c";
$motif-attention: "\e91d";
$motif-audio-description: "\e91e";
$motif-average: "\e91f";
$motif-average-2: "\e920";
$motif-ball-point-pen: "\e921";
$motif-banana: "\e922";
$motif-bandage: "\e923";
$motif-bench-press-with-dumbbells: "\e924";
$motif-bento-box: "\eb28";
$motif-binoculars: "\e925";
$motif-birthday-cake: "\e926";
$motif-bitbucket: "\e927";
$motif-blind: "\e928";
$motif-block: "\e929";
$motif-body-scan: "\e92a";
$motif-bookmark: "\e92b";
$motif-bookmark-ribbon: "\e92c";
$motif-bottle-of-water: "\e92d";
$motif-braille: "\e92e";
$motif-brain: "\e92f";
$motif-bulleted-list: "\e930";
$motif-business-contact: "\e931";
$motif-buy: "\e932";
$motif-caduceus: "\e933";
$motif-caduceus_gavel: "\e934";
$motif-cafe: "\e935";
$motif-calculator: "\e936";
$motif-calendar: "\e937";
$motif-calendar-minus: "\e938";
$motif-calendar-plus: "\e939";
$motif-call-transfer: "\e93a";
$motif-cancel: "\e93b";
$motif-cancel-2: "\e93c";
$motif-cancel-file: "\e93d";
$motif-cancer-ribbon: "\e93e";
$motif-card-exchange: "\e93f";
$motif-card-security: "\e940";
$motif-card-security-code: "\e941";
$motif-card-verification-value: "\e942";
$motif-cardboard-box: "\e943";
$motif-cast: "\e944";
$motif-cc-amex: "\eb29";
$motif-cc-discover: "\eb2a";
$motif-cc-mastercard: "\eb2b";
$motif-cc-visa: "\eb2c";
$motif-charge-battery: "\e945";
$motif-chat: "\e946";
$motif-chat-bubble: "\eb2f";
$motif-chat-send: "\eb30";
$motif-cheap-2: "\e947";
$motif-check-wide: "\eb31";
$motif-checked: "\e948";
$motif-checked-2: "\e949";
$motif-checkmark: "\e94a";
$motif-checkout: "\e94b";
$motif-chef-hat: "\eb1d";
$motif-chevron: "\e94c";
$motif-chevron-down: "\e94d";
$motif-chevron-down-round: "\e94e";
$motif-chevron-filled-down: "\e94f";
$motif-chevron-filled-up: "\e950";
$motif-chevron-left: "\e951";
$motif-chevron-left-round: "\e952";
$motif-chevron-right: "\e953";
$motif-chevron-right-round: "\e954";
$motif-chevron-up: "\e955";
$motif-chevron-up-round: "\e956";
$motif-circle: "\e957";
$motif-circle-checked-inverse: "\eb1e";
$motif-circle-notch: "\e958";
$motif-circle-thin: "\e959";
$motif-circled: "\e95a";
$motif-circled-down: "\e95b";
$motif-circled-down-left: "\e95c";
$motif-circled-down-right: "\e95d";
$motif-circled-left: "\e95e";
$motif-circled-right: "\e95f";
$motif-circled-up: "\e960";
$motif-circled-up-left: "\e961";
$motif-circled-up-right: "\e962";
$motif-clinic: "\e963";
$motif-clipboard: "\e964";
$motif-clock: "\e965";
$motif-clone: "\e966";
$motif-closed-captioning: "\e967";
$motif-collapse: "\e968";
$motif-color-dropper: "\e969";
$motif-comments: "\e96a";
$motif-commercial-development-management: "\e96b";
$motif-compare: "\e96c";
$motif-compress: "\e96d";
$motif-computer-support: "\e96e";
$motif-concept: "\e96f";
$motif-condom: "\e970";
$motif-condom-package: "\e971";
$motif-contacts: "\e972";
$motif-content: "\e973";
$motif-copy: "\e974";
$motif-coronavirus: "\e975";
$motif-counselor: "\e976";
$motif-create-new: "\e977";
$motif-crop: "\e978";
$motif-crutch: "\e979";
$motif-curls-with-dumbbells: "\e97a";
$motif-cursor: "\e97b";
$motif-customer-support: "\e97c";
$motif-death: "\e97d";
$motif-decrease-font: "\e97e";
$motif-delete: "\e97f";
$motif-delete_link: "\e980";
$motif-delete_slide: "\e981";
$motif-department: "\e982";
$motif-design: "\e983";
$motif-details: "\e984";
$motif-device-information: "\e985";
$motif-diabetes: "\e986";
$motif-diabetes-monitor: "\e987";
$motif-dialysis_machine: "\e988";
$motif-diamond: "\e989";
$motif-dislike: "\e98a";
$motif-dislike-fill: "\e98b";
$motif-doctors-bag: "\e98c";
$motif-doctors-without-borders: "\e98d";
$motif-document: "\e98e";
$motif-documents: "\e98f";
$motif-donate: "\e990";
$motif-door-sensor-alarmed: "\e991";
$motif-double-down: "\e992";
$motif-double-left: "\e993";
$motif-double-right: "\e994";
$motif-double-up: "\e995";
$motif-down-2: "\e996";
$motif-down-3: "\e997";
$motif-down-arrow: "\e998";
$motif-down-left: "\e999";
$motif-down-left-arrow: "\e99a";
$motif-down-left-round: "\e99b";
$motif-down-right: "\e99c";
$motif-down-right-arrow: "\e99d";
$motif-down-right-round: "\e99e";
$motif-down-round: "\e99f";
$motif-down-squared: "\e9a0";
$motif-download: "\e9a1";
$motif-download-2: "\e9a2";
$motif-download-graph-report: "\e9a3";
$motif-download-pie-chart-report: "\e9a4";
$motif-downloads: "\e9a5";
$motif-drag-list-down: "\e9a6";
$motif-drag-list-up: "\e9a7";
$motif-drag-reorder: "\e9a8";
$motif-dumbbell: "\e9a9";
$motif-edit: "\e9aa";
$motif-elderly-person: "\e9ab";
$motif-ellipse: "\e9ac";
$motif-ellipse-filled: "\e9ad";
$motif-employee-card: "\e9ae";
$motif-empty-hourglass: "\e9af";
$motif-empty-trash: "\e9b0";
$motif-environment: "\e9b1";
$motif-erase: "\e9b2";
$motif-eraser: "\e9b3";
$motif-error: "\e9b4";
$motif-error-cloud: "\e9b5";
$motif-escape-mask: "\e9b6";
$motif-event-accepted: "\e9b7";
$motif-event-accepted-tentatively: "\e9b8";
$motif-exercise: "\e9b9";
$motif-expand: "\e9ba";
$motif-external-link: "\eb2d";
$motif-eye: "\e9bb";
$motif-facebook: "\e9bc";
$motif-false-teeth: "\e9bd";
$motif-family: "\e9be";
$motif-feedback: "\e9bf";
$motif-file: "\e9c0";
$motif-fill-color: "\e9c1";
$motif-filled-like: "\e9c2";
$motif-filled-star: "\e9c3";
$motif-filter: "\e9c4";
$motif-find-matching-job: "\e9c5";
$motif-fingerprint: "\e9c6";
$motif-flip-horizontal: "\e9c7";
$motif-flip-vertical: "\e9c8";
$motif-flow-chart: "\e9c9";
$motif-foil-space-blanket: "\e9ca";
$motif-fold: "\e9cb";
$motif-foot-angle: "\e9cc";
$motif-forgot-password: "\e9cd";
$motif-fridge: "\e9ce";
$motif-full-image: "\e9cf";
$motif-full-stop: "\e9d0";
$motif-gavel: "\e9d1";
$motif-general-ledger: "\e9d2";
$motif-go-to-link: "\e9d3";
$motif-gold-bars: "\e9d4";
$motif-good-decision: "\e9d5";
$motif-graduation-cap: "\e9d6";
$motif-graph-report: "\e9d7";
$motif-graph-report-script: "\e9d8";
$motif-group-background-selected: "\e9d9";
$motif-group-foreground-selected: "\e9da";
$motif-group-of-questions: "\e9db";
$motif-groups: "\e9dc";
$motif-hand-cursor: "\e9dd";
$motif-hand-with-pen: "\e9de";
$motif-handshake: "\e9df";
$motif-hashtag-large: "\e9e0";
$motif-headset: "\e9e1";
$motif-health-book: "\e9e2";
$motif-hearing: "\e9e3";
$motif-hearing-aid: "\e9e4";
$motif-heart-health: "\e9e5";
$motif-heart-monitor: "\e9e6";
$motif-heart-with-pulse: "\e9e7";
$motif-help: "\e9e8";
$motif-helping-hand: "\e9e9";
$motif-high-connection: "\e9ea";
$motif-high-importance: "\e9eb";
$motif-high-priority: "\e9ec";
$motif-home: "\e9ed";
$motif-honesty: "\e9ee";
$motif-honey: "\e9ef";
$motif-hospital-3: "\e9f0";
$motif-hot-dog: "\e9f1";
$motif-human-bone: "\e9f2";
$motif-ice-cream-cone: "\e9f3";
$motif-id-card: "\e9f4";
$motif-id-verified: "\e9f5";
$motif-idea: "\e9f6";
$motif-in-transit: "\e9f7";
$motif-inbox: "\e9f8";
$motif-info: "\e9f9";
$motif-info-squared: "\e9fa";
$motif-infusion-pumps: "\e9fb";
$motif-ingredients-list: "\e9fc";
$motif-inspection: "\e9fd";
$motif-internal: "\e9fe";
$motif-internet: "\e9ff";
$motif-invisible: "\ea00";
$motif-invite: "\ea01";
$motif-ios-development: "\ea02";
$motif-job: "\ea03";
$motif-key: "\ea04";
$motif-keyhole-shield: "\ea05";
$motif-kidney: "\ea06";
$motif-label-printer: "\ea07";
$motif-language: "\ea08";
$motif-large-intestine: "\ea09";
$motif-leave: "\ea0a";
$motif-left-2: "\ea0b";
$motif-left-3: "\ea0c";
$motif-left-arrow: "\ea0d";
$motif-left-down-2: "\ea0e";
$motif-left-round: "\ea0f";
$motif-left-squared: "\ea10";
$motif-left-up-2: "\ea11";
$motif-lgbt-men: "\ea12";
$motif-lgbt-women: "\ea13";
$motif-library: "\ea14";
$motif-lightning-bolt: "\ea15";
$motif-like: "\ea16";
$motif-list: "\ea17";
$motif-liver: "\ea18";
$motif-lock: "\ea19";
$motif-lock-2: "\ea1a";
$motif-loser: "\ea1b";
$motif-low-priority: "\ea1c";
$motif-loyalty: "\ea1d";
$motif-lungs: "\ea1e";
$motif-map-marker: "\ea1f";
$motif-marker: "\ea20";
$motif-marker-pen: "\ea21";
$motif-massage: "\ea22";
$motif-medical-doctor: "\ea23";
$motif-medical-heart: "\ea24";
$motif-medical-mobile-app: "\ea25";
$motif-medical-thermometer: "\ea26";
$motif-megaphone: "\ea27";
$motif-membership-card: "\ea28";
$motif-mental-state: "\ea29";
$motif-menu-kebab: "\ea2a";
$motif-merge-horizontal: "\ea2b";
$motif-merge-vertical: "\ea2c";
$motif-message: "\ea2d";
$motif-message-filled: "\ea2e";
$motif-metamorphose: "\ea2f";
$motif-microbeam_radiation_therapy: "\ea30";
$motif-micropore-tape: "\ea31";
$motif-minus: "\ea32";
$motif-mobile-shop-cards: "\ea33";
$motif-mortar-and-pestle: "\ea34";
$motif-move: "\ea35";
$motif-ms-excel: "\eb26";
$motif-multiple-devices: "\ea36";
$motif-music: "\ea37";
$motif-mute: "\ea38";
$motif-negative-dynamic: "\ea39";
$motif-news: "\ea3a";
$motif-no-chat: "\ea3b";
$motif-no-edit: "\ea3c";
$motif-no-filling: "\ea3d";
$motif-no-idea: "\ea3e";
$motif-no-reminders: "\ea3f";
$motif-no-smoking: "\ea40";
$motif-nose: "\ea41";
$motif-not-hearing: "\ea42";
$motif-numbered-list: "\ea43";
$motif-numerical-sorting-12: "\ea44";
$motif-numerical-sorting-21: "\ea45";
$motif-ok: "\ea46";
$motif-opened-captioning: "\ea47";
$motif-opened-folder: "\ea48";
$motif-order-on-the-way: "\ea49";
$motif-order-shipped: "\ea4a";
$motif-organization: "\ea4b";
$motif-overview_pages_3: "\ea4c";
$motif-pacemaker: "\ea4d";
$motif-paper-clip: "\eb27";
$motif-paper-plane: "\ea4e";
$motif-parse-from-clipboard: "\ea4f";
$motif-past: "\ea50";
$motif-paste: "\ea51";
$motif-paycheque: "\ea52";
$motif-pdf: "\ea53";
$motif-pen: "\ea54";
$motif-pencil: "\ea55";
$motif-pencil-tip: "\ea56";
$motif-pharmacist: "\ea57";
$motif-phone: "\ea58";
$motif-physical-therapy: "\ea59";
$motif-picture: "\ea5a";
$motif-pill: "\ea5b";
$motif-play: "\ea5c";
$motif-play-round: "\ea5d";
$motif-plus: "\ea5e";
$motif-posture: "\ea5f";
$motif-prescription-pill-bottle: "\ea60";
$motif-presentation-filled: "\ea61";
$motif-price-tag: "\ea62";
$motif-price-tag-usd: "\ea63";
$motif-print: "\ea64";
$motif-printed-ocr: "\ea65";
$motif-pulse: "\ea66";
$motif-puzzle: "\ea67";
$motif-question: "\ea68";
$motif-rating: "\ea69";
$motif-read-message: "\ea6a";
$motif-read-message1: "\ea6b";
$motif-reading: "\ea6c";
$motif-rebalance-portfolio: "\ea6d";
$motif-recurring-appointment: "\ea6e";
$motif-recurring-appointment-exception: "\ea6f";
$motif-redo: "\ea70";
$motif-refresh: "\ea71";
$motif-reply: "\ea72";
$motif-resize-diagonal: "\ea73";
$motif-resize-four-directions: "\ea74";
$motif-resize-horizontal: "\ea75";
$motif-resize-vertical: "\ea76";
$motif-restrict: "\ea77";
$motif-retweet: "\ea78";
$motif-reviewer-male: "\ea79";
$motif-ribbon: "\eb2e";
$motif-right-2: "\ea7a";
$motif-right-3: "\ea7b";
$motif-right-arrow: "\ea7c";
$motif-right-down-2: "\ea7d";
$motif-right-round: "\ea7e";
$motif-right-squared: "\ea7f";
$motif-right-up-2: "\ea80";
$motif-rod_of_asclepius: "\ea81";
$motif-rotate: "\ea82";
$motif-rotate-left: "\ea83";
$motif-rotate-right: "\ea84";
$motif-rotate-right-1-1-1: "\ea85";
$motif-rss: "\ea86";
$motif-ruler: "\ea87";
$motif-rules: "\ea88";
$motif-sad-cloud: "\eb1f";
$motif-safety-collection-place: "\ea89";
$motif-safety-pin: "\ea8a";
$motif-safety-shower: "\ea8b";
$motif-sales-performance: "\ea8c";
$motif-saliva-test: "\ea8d";
$motif-save-the-children: "\ea8e";
$motif-scale: "\ea8f";
$motif-scalpel: "\ea90";
$motif-scissors: "\ea91";
$motif-search-1: "\ea92";
$motif-search-contacts: "\ea93";
$motif-search-property: "\ea94";
$motif-secured-by-alarm-system: "\ea95";
$motif-security-ssl: "\ea96";
$motif-sell-property: "\ea97";
$motif-sent: "\ea98";
$motif-services: "\ea99";
$motif-settings: "\ea9a";
$motif-shield: "\ea9b";
$motif-shopping-bag: "\ea9c";
$motif-shopping-cart: "\ea9d";
$motif-sign_language_interpretation: "\ea9e";
$motif-skeleton: "\ea9f";
$motif-skin: "\eaa0";
$motif-skull: "\eaa1";
$motif-sleeping-in-bed: "\eaa2";
$motif-small-business: "\eaa3";
$motif-smartphone-approve: "\eaa4";
$motif-sneaker: "\eb20";
$motif-sneeze: "\eaa5";
$motif-soldering-iron: "\eaa6";
$motif-sort: "\eaa7";
$motif-sort-down: "\eaa8";
$motif-sort-down-filled: "\eaa9";
$motif-sort-left: "\eaaa";
$motif-sort-right: "\eaab";
$motif-sort-up: "\eaac";
$motif-sort-up-filled: "\eaad";
$motif-sorting-arrows: "\eaae";
$motif-sorting-arrows-horizontal: "\eaaf";
$motif-sorting-options: "\eab0";
$motif-speech-bubble: "\eab1";
$motif-sperm-bank: "\eab2";
$motif-split-horizontal: "\eab3";
$motif-split-vertical: "\eab4";
$motif-standing-man: "\eab5";
$motif-standing-woman: "\eab6";
$motif-star: "\eab7";
$motif-star-half: "\eab8";
$motif-star-half-empty: "\eab9";
$motif-statistics: "\eaba";
$motif-stethoscope: "\eabb";
$motif-stitch-marker: "\eabc";
$motif-stocks: "\eabd";
$motif-stomach: "\eabe";
$motif-stop-pie-chart-report: "\eabf";
$motif-stretcher: "\eac0";
$motif-submit-resume: "\eac1";
$motif-suicide-risk: "\eac2";
$motif-support: "\eac3";
$motif-surgical-scissors: "\eac4";
$motif-swap: "\eac5";
$motif-symbol-mars: "\eb21";
$motif-symbol-venus: "\eb22";
$motif-synchronize: "\eac6";
$motif-syringe: "\eac7";
$motif-tags: "\eac8";
$motif-tasklist: "\eac9";
$motif-tasks: "\eaca";
$motif-tax: "\eacb";
$motif-technical_support: "\eacc";
$motif-technology-items: "\eacd";
$motif-template: "\eace";
$motif-test-partial-passed: "\eacf";
$motif-therapy: "\ead0";
$motif-thick-arrow-pointing-down: "\ead1";
$motif-thick-arrow-pointing-up: "\ead2";
$motif-thumb-down: "\ead3";
$motif-thumb-up: "\ead4";
$motif-tied-hands: "\ead5";
$motif-time-card: "\ead6";
$motif-timeline: "\ead7";
$motif-todo-list: "\ead8";
$motif-tooth: "\ead9";
$motif-toothbrush: "\eada";
$motif-toothpaste: "\eadb";
$motif-topic: "\eadc";
$motif-touchscreen-smartphone: "\eadd";
$motif-training: "\eade";
$motif-transaction-list: "\eadf";
$motif-transfer-between-users: "\eae0";
$motif-trash: "\eae1";
$motif-travel-card: "\eae2";
$motif-treatment-plan: "\eae3";
$motif-tree-structure: "\eae4";
$motif-triangle: "\eae5";
$motif-triangular-bandage: "\eae6";
$motif-true-false: "\eae7";
$motif-trust: "\eae8";
$motif-tv: "\eae9";
$motif-tweezers: "\eaea";
$motif-twitter: "\eaeb";
$motif-ultrasound: "\eaec";
$motif-unchecked-checkbox: "\eaed";
$motif-undo: "\eaee";
$motif-unhcr: "\eaef";
$motif-unicef: "\eaf0";
$motif-unlock: "\eaf1";
$motif-unlock-2: "\eaf2";
$motif-up-2: "\eaf3";
$motif-up-3: "\eaf4";
$motif-up-arrow: "\eaf5";
$motif-up-left: "\eaf6";
$motif-up-left-arrow: "\eaf7";
$motif-up-left-round: "\eaf8";
$motif-up-right: "\eaf9";
$motif-up-right-arrow: "\eafa";
$motif-up-right-round: "\eafb";
$motif-up-round: "\eafc";
$motif-up-squared: "\eafd";
$motif-update-tag: "\eafe";
$motif-upload: "\eaff";
$motif-upload-2: "\eb00";
$motif-urgent-message: "\eb01";
$motif-urine-collection: "\eb02";
$motif-user-checked: "\eb23";
$motif-user-group-man-man: "\eb03";
$motif-user-group-man-woman: "\eb04";
$motif-user-group-woman-woman: "\eb05";
$motif-user-male-circle: "\eb06";
$motif-user-manual: "\eb07";
$motif-user-remove: "\eb24";
$motif-uterus: "\eb08";
$motif-vaccine-drop: "\eb09";
$motif-vending-machine: "\eb0a";
$motif-video-conference: "\eb0b";
$motif-video-projector: "\eb0c";
$motif-view-details: "\eb0d";
$motif-vip: "\eb0e";
$motif-virus: "\eb0f";
$motif-visible: "\eb10";
$motif-visualy-impaired: "\eb11";
$motif-walker: "\eb12";
$motif-walking-stick: "\eb13";
$motif-warning-shield: "\eb14";
$motif-wheelchair: "\eb15";
$motif-why-us-male: "\eb16";
$motif-wipes: "\eb17";
$motif-wooden_massagetable: "\eb18";
$motif-world-health-organization: "\eb19";
$motif-xray: "\eb1a";
$motif-yogurt: "\eb1b";
$motif-youtube-play: "\eb1c";
