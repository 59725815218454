//MOTIF OVERRIDES - PULLING IN STYLES FROM FWC PROJECT AND REPLACING CSS CUSTOM PROPS WITH SCSS VARIABLES. THIS WILL MAKE IT EASIER TO UPGRADE TO DIRECTLY IMPORTING THE FWC STYLE SHEETS ONCE WE UPGRADE TO A NEWER VERSION OF ANGULAR
@import "./partials/vars";
@import "./partials/main";
@import "./partials/grid";
@import "./partials/common-components";
@import "./partials/epm_icons";
@import "./partials/data-visualizations";
@import "./partials/header";
@import "./partials/functions";
@import "./partials/buttons-links";
@import "./partials/alerts";
@import "./partials/accordions";
@import "./partials/modals";
@import "./partials/animations";
@import "./partials/input";
@import "./partials/cards";
@import "./partials/typography";
@import "./partials/tables";
@import "./partials/select";
@import "./partials/checkbox";
@import "./partials/toggle";
@import "./partials/radio";
@import "./partials/expand";
//@import "./partials/icon_vars";

//@import "./partials/icon_vars";

.fwc-search-btn {
  border: none;
  margin-left: 24px;
  width: 168px;
  height: 40px;
  padding: 11px 0 10px;
  border-radius: 8px;
  font-family: "Lato",sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  color: #fff;
  background-color: #286ce2;
  background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.1));
}

//:root {
//  --font-sans-serif: 'Lato', sans-serif;
//  --font-serif: 'Bitter', serif;
//  --color-brand: #0079C2;
//  --color-primary: #286CE2;
//  --color-util-one: #D20A3C;
//  --color-util-two: #53B1A3;
//  --color-util-three: #2453A6;
//  --color-util-four: #37475A;
//  --color-dviz-one: #00D795;
//  --color-dviz-two: #3BB273;
//  --color-dviz-three: #108808;
//  --color-dviz-four: #511284;
//  --color-dviz-five: #CB0042;
//  --color-dviz-six: #CB2A73;
//  --color-dviz-seven: #FF4D61;
//  --color-dviz-eight: #F2BC35;
//  --color-gray-one: #333333;
//  --color-gray-two: #666666;
//  --color-gray-three: #949494;
//  --color-gray-four: #CCCCCC;
//  --color-gray-five: #F7F7F7;
//  --color-gray-six: #eeeeee;
//  --color-black: #000000;
//  --color-white: #FFFFFF;
//  --color-btn-second-border: #F0F5FF;
//  --fwcu: 8px;
//  --default-font-size: calc(1.75*var(--fwcu));
//  --default-mobile-font-size: calc(1.5*var(--fwcu));
//  --box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.08);
//  --border-radius: var(--fwcu);
//}
/*
  FWC CSS CUSTOM PROPS
  FOR IE11 Support, add to style in head
  <style class="global-style-vars">
   ...
  </style>
*/
:root {
  --color-rgb-brand: 0, 121, 194;
  --color-rgb-primary: 40, 108, 226;
  --color-rgb-primary-hover: 36, 83, 166;
  --color-rgb-util-one: 210, 10, 60;
  --color-rgb-util-two: 83, 177, 163;
  --color-rgb-util-three: 36, 83, 166;
  --color-rgb-util-four: 55, 71, 90;
  --color-rgb-dviz-one: 0, 215, 149;
  --color-rgb-dviz-two: 59, 178, 115;
  --color-rgb-dviz-three: 16, 136, 8;
  --color-rgb-dviz-four: 81, 18, 132;
  --color-rgb-dviz-five: 203, 0, 66;
  --color-rgb-dviz-six: 203, 42, 115;
  --color-rgb-dviz-seven: 255, 77, 97;
  --color-rgb-dviz-eight: 242, 188, 53;
  --color-rgb-gray-one: 51, 51, 51;       /** #333333 */
  --color-rgb-gray-two: 102, 102, 102;    /** #666666 */
  --color-rgb-gray-three: 148, 148, 148;  /** #949494 */
  --color-rgb-gray-four: 204, 204, 204;   /** #CCCCCC */
  --color-rgb-gray-five: 247, 247, 247;   /** #F7F7F7 */
  --color-rgb-gray-six: 238, 238, 238;    /** #EEEEEE */
  --color-rgb-black: 0, 0, 0;
  --color-rgb-white: 255, 255, 255;
  --color-rgb-btn-second-border: 240, 245, 255;
  --color-rgb-alert-success: var(--color-rgb-dviz-two);
  --color-rgb-alert-info: var(--color-rgb-primary);
  --color-rgb-alert-error: var(--color-rgb-util-one);
  --color-rgb-alert-warning: var(--color-rgb-dviz-eight);

  --font-sans-serif: 'Lato', sans-serif;
  --font-serif: 'Bitter', serif;
  --color-brand: rgb(var(--color-rgb-brand));
  --color-primary: rgb(var(--color-rgb-primary));
  --color-primary-hover: rgb(var(--color-rgb-primary-hover));
  --color-util-one: rgb(var(--color-rgb-util-one));
  --color-util-two: rgb(var(--color-rgb-util-two));
  --color-util-three: rgb(var(--color-rgb-util-three));
  --color-util-four: rgb(var(--color-rgb-util-four));
  --color-dviz-one: rgb(var(--color-rgb-dviz-one));
  --color-dviz-two: rgb(var(--color-rgb-dviz-two));
  --color-dviz-three: rgb(var(--color-rgb-dviz-three));
  --color-dviz-four: rgb(var(--color-rgb-dviz-four));
  --color-dviz-five: rgb(var(--color-rgb-dviz-five));
  --color-dviz-six: rgb(var(--color-rgb-dviz-six));
  --color-dviz-seven: rgb(var(--color-rgb-dviz-seven));
  --color-dviz-eight: rgb(var(--color-rgb-dviz-eight));
  --color-gray-one: rgb(var(--color-rgb-gray-one));
  --color-gray-two: rgb(var(--color-rgb-gray-two));
  --color-gray-three: rgb(var(--color-rgb-gray-three));
  --color-gray-four: rgb(var(--color-rgb-gray-four));
  --color-gray-five: rgb(var(--color-rgb-gray-five));
  --color-gray-six: rgb(var(--color-rgb-gray-six));
  --color-black: rgb(var(--color-rgb-black));
  --color-white: rgb(var(--color-rgb-white));
  --color-btn-second-border: rgb(var(--color-rgb-btn-second-border));
  --color-alert-error: rgb(var(--color-rgb-alert-error));
  --color-alert-info: rgb(var(--color-rgb-alert-info));
  --color-alert-success: rgb(var(--color-rgb-alert-success));
  --color-alert-warning: rgb(var(--color-rgb-alert-warning));
  --fwcu: 8px;
  --default-font-size: calc(1.75 * var(--fwcu));
  --default-mobile-font-size: calc(1.75 * var(--fwcu));
  --default-line-height: calc(2.75 * var(--fwcu));
  --default-line-height-mobile: calc(2.75 * var(--fwcu));
  --box-shadow: 0 6px 12px 0 rgba(var(--color-rgb-black), 0.08);
  --border-radius: var(--fwcu);
  --fwc-focus: 4px solid rgba(var(--color-rgb-primary-hover), 0.6);
}

.fwc-sm-text {
  font-size: 14px !important;
}

.fwc-title-text {
  font-size: 18px !important;
}
fieldset {
  border: 0 !important;
  box-shadow: none;
}
