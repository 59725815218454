//import pattern library

@import './partials/grid';
@import './partials/components';
@import './partials/main';
@import './partials/mixins';
@import './partials/variables';

//import shared resources
@import '../../eea-library-ui/scss/main';

//import partials
@import './partials/form-elements';
@import './partials/registration';
@import './partials/widgets';
@import './partials/member-registration';
@import './partials/broker-registration';
@import './partials/eamobileapp';
@import './partials/eet-setup';
@import './partials/login';
@import "./partials/paperSlider";

@import './motif-styles/motif-styles';
@import './partials/approve-deny';

body, html {
    height:100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}
input {
  font-size: 1rem;
  display: block;
  color: inherit;
  width: 100%;
  background: transparent;
  outline: none;
  border: none;
  padding-right: 0.16667rem;
  padding-bottom: .3rem;
  padding-top: .3rem;
  padding-left: 0.66667rem;
}
.btm-sm {
  margin-bottom: 1rem;
}
.top-sm {
  margin-top: 1rem;
}

//Mobile App Banner
.mobile-banner-content {
  background-color: $light-gray;
  color: $primary-text-color-inverse;
  min-height: 125px;
  font-size: 20px;
  font-weight: 100;
  padding: 20px 10px;
  text-align: left;
  border-bottom: 5px solid $primary-color-blue;
  @media screen and (min-width: $breakpoint-sm) {
    padding: 20px;
  }

  .banner-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
  }
  .text-container {
    padding-top: 10px;
    @media screen and (min-width: $breakpoint-sm) {
      padding-top: 0;
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  .banner-image {
    @media (min-width: $breakpoint-md) {
      align-self: center;
    }
  }

  .banner-title-text {
    font-weight: 400;
    padding-left: 20px;
    padding-bottom: 10px;
  }

  .banner-close{
    border-bottom: none;
    color: $primary-color-light-blue;
  }

  .mobile-text {
    font-size: 1rem;
    font-family: "Open Sans", "open-sans", sans-serif, sans-serif;
    font-weight: 400;
  }

  .banner-image-container {
    width: 70px;
    height: 55px;
    @media screen and (min-width: $breakpoint-xs) {
      width: 90px;
      height: 75px;
    }
  }

  .desktop-content {
    @media screen and (max-width: $breakpoint-sm) {
     display: none;
    }
  }

  .mobile-content {
    @media screen and (min-width: $breakpoint-sm) {
      display: none;
    }
  }
}
legend {
  border-bottom: none;
}




/** Alerts styling overrides to match Classic style**/
.ant-alert {
  border: none;
  background: #EEEEEE;
  margin: 0 0.25rem 0.25rem;
  color: $primary-color-light-blue;
  display: flex;
  width: 100%;
  .ant-alert-left {
    color: $primary-color-light-blue;
    background-color: $primary-color-light-blue;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    padding: 0 12px 0 13px;
    span.alert-icon {
      font-family: 'FontAwesome';
      &.alert-icon-positive {
        &::before {
          content: "\f17b";
          color: white;
        }
      }
      &.alert-icon-negative {
        &::before {
          content: "\f1cd";
          color: white;
        }
      }
      &.alert-icon-info {
        &::before {
          content: "\f24a";
          color: white;
        }
      }
    }
  }

  .ant-alert-main {
    color: #949494;
    width: 100%;
    padding: 1rem;
    display: block;
    flex-flow: row wrap;
    align-items: center;
    font-size: 1rem;
    .fa.fa-remove {
      font-size: 1.25rem;
    }
    p {
      color: #666;
      margin: 0;
      span {
        color: #666;
        display: flex;
      }
      .closer {
        margin-left: auto;
      }
    }
    .fa-times {
      color: #666;
      font-size: 1.25rem;
    }
  }
}

.ant-alert.ant-negative .ant-alert-left {
  background-color: #cc0033;
}
.ant-alert.ant-positive .ant-alert-left {
  background-color: #53b1a3;
}

/** Alerts End**/

/** Dismissable Alerts **/
.ant-alert.ant-dismissable {
  -webkit-box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.05);
}
.er-alert-close {
  float: right;
  margin-left: auto;
}
//PL OVERRIDES
.psArrow:before {
  font-family: 'FontAwesome' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  content: "\f0d7";
  border-left: none;
  line-height: 0;
  margin-left: 0;
  color: #0079C2;
  background: transparent;
}
.psArrow {
  padding: 0;
}
.pfSelect-legend {
  color: #0079C2;
  margin-bottom: 0;
  font-weight: 400;
  font-size: 0.86111rem;
  line-height: 1.42857rem;
  padding-left: .25rem;
}
.psLabel.selected {
  background: #00609A;
  color: #FFFFFF;
}
.psLabel.focus {
  background: #00609A;
  color: #FFFFFF;
}
.uxd-text-input input[type="text"] {
  padding-top: 0.35rem;
}
.ant-alert .ant-alert-main span span {
  display: inline !important;
}
@media screen and (max-width: 480px){
  .chat-container.minimized {
    bottom: 7.25rem;
  }
}
.fwc-input input {
  border: 1px solid var(--color-gray-three);
}
.kampyle_vertical_button .kampyle_button {
  background: #2453A6 !important;
  color: #ffffff;
  font-weight: bold;
}
.kampyle_button-text {
  font-weight: bold !important;
}

//date-picker position
.date-picker-cont {
  position: relative;
  right: 10px;
  top: 10px;
}
