
.paper-slider-container {

    $footer-height:$sph2*2 + $sph1;
    $header-height:$sph2+$sph1;

	position:fixed;
	top:0;
	bottom:0;
	right:0;
	left:0;
	overflow:hidden;
	pointer-events:none;
	background:rgba(0,0,0,0);
    transition:background 500ms ease;
    z-index:1010;
    &.open {
        pointer-events:auto;
        background:rgba(0,0,0,.5);
        .single-slider { 
            transform:translateX(0);
            &.lock {
                pointer-events: none;
            }
        }
    }
  @media print {
    overflow: visible !important;
    position: unset !important;
    z-index: 0 !important;
  }

    .paper-slider {
        position: absolute;
        top: 0;
        bottom: 0;
        right:0;
        width:100%;
        transition:all 500ms ease-in-out;
        transform: translateX(110%);
    }

    .paper-slider-header {
        position: absolute;
        top:0;
        width: 100%;
        z-index:1000;
        background:$primary-color-blue;
        color:$base-color;
        height:$header-height;
        h2 {
            padding:$sp1;
            font-family: 'Open Sans';
            font-weight: normal;
            font-size: 1.6rem;
        }
        &.er-flex {
            justify-content: flex-start;
            align-items: center;
            padding:0 $sp1;
            a { 
                flex-basis: $sph2; 
                &.ant-arrow-previous:before {
                    color:$base-color;
                }
            }
            h2 { 
                flex-basis: 100%;
            }
        }
    }

    .paper-slider-sub-header {
        position: absolute;
        top: $header-height;
        width: 100%;
        .bg-gray-medium {
            padding-left: 2rem;    
        }
        .flex-item:first-child, .flex-item:last-child {
            flex: 1 100%;
        }
        .flex-item:first-child {
            padding-bottom: .5rem;
        }
    }

    .paper-slider-sub-header-large {
        position: absolute;
        top: $header-height;
        width: 100%;
        .bg-gray-medium {
            padding-left: 2rem;
            padding-right: 0;    
        }
    }

    .paper-slider-content {
        position: absolute;
        top: $header-height;
        bottom:$footer-height;
        right:0;
        left:0;
        overflow-y:scroll;
        -webkit-overflow-scrolling: touch;
        background: $base-color;
        padding:$sp2;
        &:not(.motif-styles) {
          div {
            line-height: 1.42857;
            font-family: "Open Sans", "open-sans", sans-serif, sans-serif;
          }
        }
        &.double-slider {
            padding-left:$sp1;
        }
        &.no-footer {
            bottom:0;
        }
        &.no-header {
            top:0;
        }
        &.no-wrapper {
            top:0;
            bottom:0;
        }
        &.with-sub-header, &.with-large-sub-header {
            bottom: 0;
            top: $header-height*2;
            .er-fi, .er-fi-dtoggle {
                margin-bottom: 0;
            }
        }
      @media print {
        overflow: visible;
      }
    }
    .paper-slider-footer {
        position: absolute;
        bottom:0;
        background: $primary-color-dark-blue;
        width:100%;
        padding:$sp1;
        color:$base-color;
        height:$footer-height;
        padding:$sp1 + $sp025;
    }

    .double-slider.double { 
        transform:translateX(0);
    }

    .er-alert {
        padding:0;
        position: absolute;
        
        .col-sm-10.col-sm-offset-1 {
            width:100%;
            margin:0;
            padding-left: 0;
            padding-right: 0;
        }

        //IE 11 Specific query
        @media all and (-ms-high-contrast:none) {
            margin-left:-15px;
            .col-sm-10.col-sm-offset-1 {
                padding-left: 15px;
                padding-right: 15px;
            }
            .ant-anthem-alert .media-body > p > span {
                // removed previous 'display: block' fix due to misalignment on smaller messages
                align-items: center;
            }
            .ant-anthem-alert .media-body > p > span > div {
                flex-grow: 1;
                flex-shrink: 1;
                flex-basis: 95%
            }
            .ant-anthem-alert .media-body > p > span > span {
                flex-grow: 1;
                flex-shrink: 1;
                flex-basis: 95%
            }
            .ant-anthem-alert .media-body > p > span > button {
                flex-grow: 1;
                flex-shrink: 1;
                flex-basis: 5%;
            }
        }
    }

    @media screen and (min-width: $breakpoint-xs) {
        .paper-slider-content {
            bottom:$footer-height;
            &.with-large-sub-header {
                top: 160px;
            }
        }
    }
    
    @media screen and (min-width: $breakpoint-sm) {
        $footer-height:$sph2*2;
        .paper-slider-content {
            bottom:$footer-height;
            &.with-large-sub-header {
                top: $header-height*2;
            }
        }
        .paper-slider {
            width:90%;
        }
        .paper-slider-header.er-flex {
            h2 { margin-left:-($sph2); }
        }
        .paper-slider-footer {
            height:$footer-height;
            padding:0 $sp2;;
        }
        .double-slider {
            box-shadow: -10px 0 10px rgba(0,0,0,0.5);
            &.double {
                width:87.5%;
            }
        }
     
        .paper-slider-sub-header {
            .bg-gray-medium {
                padding-left: 3rem;    
            }
            .flex-item:first-child {
                flex: 1 30%;
                padding-bottom: 0;
            }
            .flex-item:last-child {
                flex: 1 70%;
            }
        }
        .paper-slider-sub-header-large {
            .bg-gray-medium {
                padding-left: 3rem;
                padding-right: 3rem;     
            }
            .flex-item {
                &.fi-1 {
                    flex-grow: 1;
                    flex-shrink: 1;
                    flex-basis: 25%;
                    padding-bottom: 0;
                }
            }
            .flex-item {
                &.fi-2 {
                    flex-grow: 1;
                    flex-shrink: 1;
                    flex-basis: 35%;
                    padding-bottom: 0;
                }
            }
            .flex-item {
                &.fi-3 {
                    text-align: right;
                    flex-grow: 1;
                    flex-shrink: 1;
                    flex-basis: 40%;
                }
            }
        }        
    }

}
