.basic-info-container {
  background: #f7f7f7;
  padding: 1rem;
}

.user-info-container {
  padding: 1rem;
  border-radius: 8px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  position: relative;
}
.activate-user {
  position: absolute;
  right: 0rem;
  top: 1.5rem;
}

.user-status {
  padding: 1px 9px;
  border-radius: 2px;
  font-size: smaller;
  &.active{
    background: #108808;
    color: #ffffff;
  }
  &.not-active {
    background: #f2bc35;
    color: #333333;
  }
}

.bottom-footer {
  position: absolute;
    bottom: 0;
    width: 100%;
}

.protected-note{
  background: #f7f7f7;
  padding: 16px;
  flex-wrap: nowrap !important;
  p {
    line-height: 1.2rem;
  }
}

.approve-deny-container {
  box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.08);
  background-color: var(--calcite);
  margin-left: -15px;
  margin-right: -15px;
  padding: 1rem;
  background: white;
  &.fixed {
    position:fixed;
    width: 100%;
    bottom: 0;
  }
  &.not-fixed {
    position: none;
    bottom: none;
  }
}
