//debug
.border-_black
{
    border: #000 2px solid;
}

.zindex1000
{
    z-index:1000;
}

.no-margin
{
    margin: 0;
}

.no-margin-left
{
    margin-left: 0;
}
.no-margin-right
{
    margin-right: 0;
}
.no-margin-top
{
    margin-top: 0;
}
.no-margin-bottom
{
    margin-bottom: 0;
}

.no-pad-left
{
    padding-left: 0;
}
.no-pad-left label
{
    padding-left: 0;
}

.no-pad-right
{
    padding-right: 0;
}

.leftright-margin-1
{
    margin: 0 1rem;
}

.broker-registration{
    padding-top: 4rem;

    .br-top{
        h2 {
            padding-bottom: 1rem;
        }
    }
}

.broker-login, .bill-entity-access, .bill-division-access
{
    .btn-spacing
    {
        margin-left: .5rem;
        margin-right: .5rem;
    }
    .er-flex-center {
      justify-content: center;
    }

    .title-line-height
    {
        line-height: 3rem;
    }

    .icon-adjust
    {
        font-size: 1.5rem;
        right: .5rem;
    }
}

.bottom-margin-reduce
{
    margin-bottom: -1rem;
}
.bottom-margin-1
{
    margin-bottom: 1rem;
}
.bottom-margin-2
{
    margin-bottom: 2rem;
}
.bottom-margin-3
{
    margin-bottom: 3rem;
}

.top-margin-reduce
{
    margin-top:-1rem;
}
.top-margin-1
{
    margin-top: 1rem;
}
.top-margin-2
{
    margin-top: 2rem;
}
.top-margin-3
{
    margin-top: 3rem;
}

.user-agreement
{
    .sig-h4
    {
        margin-left: .8rem;
    }
}

.declar-checkbox
{

    .pcLabel, .prLabel {
        font-weight: 300;
        position: relative;
        padding-left: $sp1 + $sp05;
    }
    .pcLabel:before, .prLabel:before {
        position: absolute;
        background-color: #fff;
        left: 0;
        top: .2rem;
    }
    .pfCheckbox { margin-bottom:$sp05; }

}

.br-user-access {
    .br-skinny-form {
        max-width: 500px;
        margin: auto;
    }
    .br-toggle {
        padding-bottom: 1rem;
    }
    .pfRadio {
        .prLabel { 
            padding-left:0; 
            font-weight: 400;
        }
    }

    .br-checkbox-grp {
        padding-top: 1rem;
        margin-bottom: .5rem;
        .br-checkbox {
            padding-top: .5rem;
            .br-group-label {
                font-weight: 600;
            }
            .pcLabel { 
                font-weight: 400;
            }
        }
    }

    
}

.br-checkbox-grp.col-sm-6
{
    padding-left: 0rem;
}

#lbl_radio_view_only_id, #lbl_radio_enroll_manage_id
{
    padding-left: 0rem;
}

.bill-entity-access
{
    padding-left: 0;

    .er-form
    {
        margin: 0;
    }
    legend
    {
        border:0 solid #fff;
    }

    .selectAll-checkbox 
    {
        margin-bottom: 1rem;
        margin-top: 2rem;
    }

    fieldset
    {
        legend
        {
            margin-bottom: 0;
        }
    }
    
}

.scal-filter-search-adjust
{
    ::before
    {
        position: absolute;
    top: 2rem;
    right: 1rem;
    }
}

.content-box
{
    padding-left: 0;
    padding-right: 0;
}

.checkboxBatch 
{
    // padding-left: 10px;
    // padding-right: 10px;
    padding-left: 0;
    padding-right: 0;

    .col-sm-4
    {
        // margin-top: 1rem;
    }

    .pcLabel
    {
        font-size: .8rem;
    }

    .checkbox-item
    {
        padding-left: 0;
        padding-right: 0;
    }
    
}

.agreement-box
{
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.agreement-margin-bottom-fix 
    {
        display: inline-block;

        label
        {
            margin-bottom: -6px;
        }
    }

.broker-confirmation {
    .bc-heading {
        font-size: 2rem;
        padding-bottom: 2rem;
        font-weight: 300;
    }
    .bc-message {
        margin-bottom: 2rem;
    }
}

// employer access group
.user-information
{
    .eag-user-info-grid
    {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

}

.section-title-bar
{
    background-color: #eee;
    padding: 1rem;
}

.entity-filter-div
{
    span.fa
    {
        position: relative;
        top: -4rem;
        left: -1rem;
        border-left: #000 solid 0px;
        font-size: 16px;
    }
}

.entity-filter-div input
{
    border: solid #000 0px;
    font-size: 16px;
    border-bottom: solid #eee 1px;
    
}

#searchfield1
{
    position:relative;
    right: -10rem;
}
.entity-filter-div .ant-search-button
{
    right: -9rem;
}

#filterDivisionAccess__label
{
    font-size: .9rem;
}

.separator-line
{
    border-bottom: #eee solid 2px;
}
.br-user-access-level-filter {
  display: flex;
  flex-basis: 50%;
  justify-content: center;
  position: relative;
  >div {
    width: 100%;
  }
  input {
    padding-right: 20px;
  }
  .fa {
    position: absolute;
    right: 0;
    font-size: 1.1rem;
    color: #000000;
    top: 30px;
  }
}

.container-fluid
 {
   background-image: none;
 }

.toggle-wrapper label
{
    max-width: 45%;
}

fieldset.pfToggleSwitch
{
    position: relative;
    top: -.7rem;
}

.bill-entity-access .selectAll-checkbox label
{
    margin-left: -1rem;
}

.bill-division-access 
{
    legend
    {
        border: 0px #fff solid;
    }

    .checkboxBatch
    {
        .col-sm-3
        {
            padding-left:0;
        }
    }
}

.br-reports-boxes {
  display: flex;
  flex-direction: column;
}

.no-pad-right
{
    padding-right: 0;
}
.tooltip-label {
  display: flex;
}
.tooltip-container {
  align-self: flex-start;
}
.br-flex-cont {
  .gd-user-access-level-filter {
    flex-basis: 50%;
  }
}
