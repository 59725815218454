@media print {
    //global
    .er-header, 
    .er-header-offset, 
    .er-sub-header,
    .er-footer,
    .er-alert,
    .ez-progress,
    .no-print,
    .paper-slider-header a,
    .btn {
        display:none !important;
    }
    .er-header-offset { 
        margin:0;
    }
    //View-Claims
    .view-claims-summary-print-logo {
      .claims-print-logo {
        display:block !important;
        text-align: center;
      }
    }


    //Billing
    .er-expand-table .er-table-row .er-fi:last-child {
        text-align: left;
    }

    .billing {
        .er-page-title {
            font-size: 2.3rem;
        }
    }

    //Automatic Monthly Payments - Confirmation Page
    .automatic-monthly-payment-authorization {
        .confirmation-notes{
            &.text-center {
                text-align: left;
                padding-left: 1rem;
                font-size: 1rem;
            }
        }
        .er-page-actions,
        .er-exp-footer,
        .conf-print-link {
            display: none;
        }
        .authorize-table {
            &.er-expand-table {
                .er-table-row {
                    .er-fi {
                        &.payment-amount{
                            text-align: left;
                            padding-top: 1.3rem;
                            font-size: 1.3rem;
                        }
                        &.conf-reference-number {
                            padding-top: 1.5rem;
                            padding-bottom: 1.5rem;
                            font-size: 1.4rem;
                        }
                        .payment-amount-number {
                            display: inline;
                        }
                    }
                }
            }
        }
    }

    //Self Bill
    .self-billing {
        .self-bill-conf-list .er-table-row {
            border-top:$billing-border;
        }
    }
  //Compare Provider Details
  .compare-providers-table {
    .compare-providers-grid-3 {
      .cp-table-header {
        border: 1px solid black;
      }
    }
  }

  // Imputed Income Calculator
  .imputed-calc-tool { 
    .er-scroll-tbl {

        .uxd-text-input input + .bottom-bar {
            border-bottom:none;
        }

        .er-scroll-tbl-group { min-width: auto !important; }
    }
  }

  //Print slider content
  .er-no-scroll .row {
      display: none;
  }

  //Header
  .logo-holder {
    a[href]:after { content: "" }
  }
}
