.uxd-overlay-pane {
  height: inherit;
}

.overlay-on {
  overflow: hidden;
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  pointer-events: none;
  z-index: 3000;
}

.overlay-container .overlay-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #37475a;
  opacity: 0;
  pointer-events: auto;
  transition: 125ms opacity ease-in;
  -webkit-tap-highlight-color: transparent;
}

.overlay-container .overlay-backdrop.fade-in {
  opacity: 0.9;
  z-index: 1000;
}

.overlay-container .overlay-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal-container {
  display: block;
  height: 100%;
  pointer-events: none;
  width: 100%;
}

.modal-container.dialog {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-wrap {
  display: block;
  max-width: 660px;
  padding: 24px;
  position: relative;
}

@media screen and (max-width: 800px) {
  .modal-container.dialog {
    padding: 0 15px;
  }
}

.modal-container.slide-in {
  width: 90%;
}

.modal-container.slide-in .modal-panel {
  position: absolute;
}

.modal-container.slide-in.right .modal-panel, .modal-container.slide-in.bottom .modal-panel {
  bottom: 0;
  right: 0;
}

@media screen and (min-width: 800px) {
  .modal-container.slide-in {
    width: 60%;
  }
}

.modal-panel {
  background-color: transparent;
  display: block;
  pointer-events: auto;
  position: relative;
  z-index: 3500;
}
.fwc-modal-header {
  background: linear-gradient(180deg, $color-white 0%, $color-gray-five 100%);
  padding: calc(4* #{$fwcu});
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: $border-radius $border-radius 0 0;
}
.fwc-modal-close {
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
  width: calc(3* #{$fwcu});
  height: calc(3* #{$fwcu});
  &:focus {
    //outline: 0;
    outline-color: transparent;
  }
}
.fwc-modal-wrapper {
  width: 92vw;
  max-width: calc(100 * #{$fwcu});
  display: block;
  position: relative;
  .no-border {
    border: none;
  }
}
.fwc-modal-body {
  padding: calc(3* #{$fwcu}) calc(6* #{$fwcu}) 0 calc(6* #{$fwcu});
  background: #fff;
  font-family: $font-family-Lato;
  p {
    font-size: 14px;
  }
}
.fwc-modal-actions {
  display: flex;
  justify-content: flex-end;
  padding: calc(6* #{$fwcu});
  background: #fff;
  border-radius: 0 0 $border-radius $border-radius;
}
.fwc-modal-slide-container {
  padding: 20px;
  li {
    margin-bottom: 5px;
  }
}
