@use "sass:math";
$slider-size:2rem;
$chip-size:1rem;

.range-slider {
    @extend .uxd-text-input;
    min-width:$sph2*3;
    position: relative;
    width:100%;
    margin-bottom:0;
    padding-bottom: $slider-size*2;

    .range-slider_label {
        font-size: 0.86111rem;
        color: $primary-color-light-blue;
        bottom: 0rem;
        pointer-events: none;
        position: relative;
        padding-left: 0.66667rem;
        transition: bottom 250ms ease-in-out, font-size 250ms ease-in-out, color 100ms linear;
        font-weight: 400;
        line-height: 1.42857rem;
        max-height: 1.42857rem;
        display: flex;
        
        &.tear-drop {
            margin-bottom:$slider-size;
        }
    }
    .slider-wrapper { 
        height: $slider-size;
        width:100%;
        position: absolute;
        transition: all ease 250ms;
    }

    .chip {
        height: $chip-size;
        width: $chip-size;
        top:1.5rem;
        padding:0;
        margin:0;
        background-color:$primary-color-light-blue;
        border:1px solid rgba(0,0,0,0);
        border-radius: 50%;
        position: absolute;
        transform-origin: top left;
        transition: all ease 100ms;
        cursor:-webkit-grab;

        .value-display {
            position: absolute;
            font-weight: 700;
            color:$neutral-dark-gray;
            top:-($chip-size*1.5);
            left:calc(50% - #{$chip-size});
            width: $chip-size*2;
        }

        &.tear-drop .value-display {
            display:none;
            color:$base-color;
            width:100%;
            transform:rotate(-45deg);
            top:5px;
            left:0;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        &.active {
            cursor:-webkit-grabbing;
            &.tear-drop {
                height: $slider-size;
                width: $slider-size;
                // .125rem above bar.
                top: -(math.div($slider-size, 2) + .125rem);
                border-top-right-radius: 50%;
                border-top-left-radius: 50%;
                border-bottom-left-radius: 50%;
                border-bottom-right-radius: 0%;
                transform: rotate(45deg);
               
                .value-display {
                    display:inline;
                }
            }
        }
        &:focus {
            border:1px solid $primary-color-blue;
            box-shadow: 0px 0px 2px 1px $primary-color-blue;
            outline:none;
        }
        &.pass-through {
            pointer-events: none;
        }
    }

    .bar {
        position: absolute;
        margin-bottom: -1px;
        padding-bottom: 1px;
        border-bottom:1px solid $neutral-dark-gray;
        top:$slider-size;
        width:100%;
        &:before{
            margin-bottom: -1px;
            padding-bottom: 1px;
        }

    }
    .range {
        position: absolute;
        top:$slider-size;
        transition: all ease 100ms;
        background-color:$primary-color-blue;
        box-shadow: 0px 0px 2px 0px $primary-color-blue;
        border-radius:5px;
        height:2px;

    }
}