.eam-container {
  margin-bottom: -4rem;
  .eam-heading {
    font-size: 40px;
  }
  .eam-intro {
    display: flex;
    .intro-left {
      flex: 1 1 40%;
      padding-top: 2.5rem;
      padding-right: 2rem;

      .eam-intro-subhead {
        font-size: 26px;
      }

      .eam-intro-content {
        font-size: 18px;
      }
      .app-store-logo {
        max-height: 40px;
        box-shadow: 0 10px 30px 0 rgba(46, 61, 98, 0.4);
        border-radius: 70px;
        margin-right: 2rem;
      }
      a {
        border-bottom: none;
      }
      .small-view-image {
        display: none;
      }
    }
    .intro-right {
      flex: 1 1 60%;
    }
    @media screen and (max-width: 1023px) {
      .intro-right {
        display: none;
      }
      .intro-left {
        margin-bottom: 2rem;
        .small-view-image {
          display: block;
        }
      }
      .eam-intro-subhead.btm-sm {
        margin-bottom: -1rem;
      }
    }
  }
  .eam-features {
    background-color: #0079c2;
    padding-bottom: 4rem;
    //put this in place for video version of page
    //.eam-features-head {
    //  background-color: $super-light-gray;
    //  height: 288px;
    //  margin-bottom: -144px;
    //}
    .eam-heading {
      padding-top: 2rem;
      padding-bottom: 2rem;
      color: $white;
    }
    .eam-card-flex {
      display: flex;
      justify-content: space-between;
      .eam-card {
        border: solid 1px #58abdf;
        background-color: $white;
        border-radius: 4px;
        margin-right: 35px;
        min-height: 530px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 35px;
        flex: 1;
        &:last-of-type {
          margin-right: 0;
        }
        .eam-card-content {
          font-size: 18px;
          .er-flex {
            align-items: flex-start;
            margin-bottom: 25px;
          }
          .fa-check-circle-regular-1 {
            margin-right: 10px;
            &:before {
              color: #58abdf;
              margin-right: 10px;
              line-height: 1.42857;
            }
          }
        }
      }
      .eam-card-logo-cont {
        align-self: center;
      }
      .eam-card-logo {
        height: 63px;
      }
      .eam-card-head {
        font-size: 24px;
      }
    }
    @media screen and (max-width: 1023px) {
      .eam-card-flex {
        flex-direction: column;
        .eam-card {
          margin-right: 0;
          margin-bottom: 2rem;
          min-height: 300px;
        }
      }
    }
  }
  .eam-video {
    margin-top: 70px;
  }
  .eam-faqs {
    background-color: $white;
    display: flex;
    flex-direction: column;
    padding-bottom: 3rem;
    .eam-question-container {
      background-color: $white;
      width: calc(100% / 1.25);
      .border-bottom-lt {
        padding: 2rem 0;
      }
      .expand-angle {
        padding-left: 24px;
      }
      .expand-plus {
        flex-basis: 10%;
      }
      .fa-angle-down:before {
        font-size: 32px;
      }
      .fa-angle-up:before {
        font-size: 32px;
      }
      .eam-answer-section {
        padding: 2rem 0 1rem 0;
        font-size: 16px;
      }
      .eam-answer {
        flex-basis: 90%;
        padding-right: 1rem;
      }
      .border-bottom-lt:last-of-type {
        border-bottom: none;
      }
    }
    .eam-heading {
      padding: 2rem 0;
    }
  }
  .login-disclaimer {
    font-size: 12px;
  }
}
.eam-header-container {
  .eam-head-logo {
    height: 85px;
    border-radius: 15px;
    padding: 2px;
  }
  .eam-head-links {
    margin-left: auto;
    @media screen and (max-width: 530px) {
      margin-left: 0;
    }
    div {
      padding-right: 3rem;
      &:last-of-type {
        padding-right: 0;
      }
      a {
        border-bottom: none;
        &:hover {
          border-bottom: 1px solid;
        }
      }
    }
  }
  @media screen and (max-width: 800px) {
    width: 100%;
  }
  @media screen and (max-width: 530px) {
    flex-direction: column;
  }
}
.main-header.eam-header {
  background-color: #96d6ff;
  @media screen and (max-width: 530px) {
    height: 115px;
  }
}
