//@mixin _cdk-optionally-nest-content($selector-context) {
//    @if ($selector-context=="") {
//      @content;
//    }
//
//    @else {
//      #{$selector-context} {
//        @content;
//      }
//    }
//  }
//
//  @mixin cdk-high-contrast($target: active, $encapsulation: "any") {
//    @if ($target !="active"and $target !="black-on-white"and $target !="white-on-black") {
//      @error 'Unknown cdk-high-contrast value "#{$target}" provided. '+'Allowed values are "active", "black-on-white", and "white-on-black"';
//    }
//
//    @if ($encapsulation !="on"and $encapsulation !="off"and $encapsulation !="any") {
//      @error 'Unknown cdk-high-contrast encapsulation "#{$encapsulation}" provided. '+'Allowed values are "on", "off", and "any"';
//    }
//
//    @at-root {
//      $selector-context: #{&};
//
//      @if ($encapsulation !="on") {
//        .cdk-high-contrast-#{$target} {
//          @include _cdk-optionally-nest-content($selector-context) {
//            @content;
//          }
//        }
//      }
//
//      @if ($encapsulation !="off") {
//        .cdk-high-contrast-#{$target} :host {
//          @include _cdk-optionally-nest-content($selector-context) {
//            @content;
//          }
//        }
//      }
//    }
//  }
//
//  /* stylelint-enable */
//  @mixin cdk-a11y {
//    .cdk-visually-hidden {
//      border: 0;
//      clip: rect(0 0 0 0);
//      height: 1px;
//      margin: -1px;
//      overflow: hidden;
//      padding: 0;
//      position: absolute;
//      width: 1px;
//      outline: 0;
//      -webkit-appearance: none;
//      -moz-appearance: none;
//    }
//  }
//
//  @mixin _cdk-optionally-nest-content($selector-context) {
//    @if ($selector-context=="") {
//      @content;
//    }
//
//    @else {
//      #{$selector-context} {
//        @content;
//      }
//    }
//  }
//
//  @mixin cdk-high-contrast($target: active, $encapsulation: "any") {
//    @if ($target !="active"and $target !="black-on-white"and $target !="white-on-black") {
//      @error 'Unknown cdk-high-contrast value "#{$target}" provided. '+'Allowed values are "active", "black-on-white", and "white-on-black"';
//    }
//
//    @if ($encapsulation !="on"and $encapsulation !="off"and $encapsulation !="any") {
//      @error 'Unknown cdk-high-contrast encapsulation "#{$encapsulation}" provided. '+'Allowed values are "on", "off", and "any"';
//    }
//
//    @at-root {
//      $selector-context: #{&};
//
//      @if ($encapsulation !="on") {
//        .cdk-high-contrast-#{$target} {
//          @include _cdk-optionally-nest-content($selector-context) {
//            @content;
//          }
//        }
//      }
//
//      @if ($encapsulation !="off") {
//        .cdk-high-contrast-#{$target} :host {
//          @include _cdk-optionally-nest-content($selector-context) {
//            @content;
//          }
//        }
//      }
//    }
//  }
//
//  $calendar-padding: 8px !default;
//  $calendar-header-divider-width: 1px !default;
//  $calendar-arrow-size: 5px !default;
//  $calendar-arrow-disabled-opacity: 0.5 !default;
//  $calendar-prev-next-icon-border-width: 2px;
//  $calendar-prev-icon-transform: translateX(2px) rotate(-45deg);
//  $calendar-next-icon-transform: translateX(-2px) rotate(45deg);
//
//  $calendar-body-label-side-padding: 33% / 7 !default;
//  $calendar-body-cell-min-size: 32px !default;
//  $calendar-body-cell-content-margin: 5% !default;
//  $calendar-body-cell-content-border-width: 1px !default;
//  $calendar-body-min-size: 7 * $calendar-body-cell-min-size !default;
//  $calendar-body-cell-content-size: 100% - $calendar-body-cell-content-margin * 2 !default;
//
//  $datepicker-calendar-padding: 8px;
//  $datepicker-non-touch-calendar-cell-size: 40px;
//  $datepicker-non-touch-calendar-width: $datepicker-non-touch-calendar-cell-size * 7+$datepicker-calendar-padding * 2;
//
//  @media all and (max-width: 767px) {
//    .cdk-overlay-container .cdk-overlay-pane {
//      width: 100%;
//    }
//
//    .daterange-calender.fullWidth {
//      position: fixed;
//      top: 0;
//      z-index: 999;
//      background: white;
//      overflow: auto;
//      left: 0;
//      width: 100%;
//    }
//
//    .date-range-title {
//      width: 100%;
//      background-image: url(/assets/images/motif/blue-tall.png);
//      background-repeat: no-repeat;
//      background-size: cover;
//      font-weight: 300;
//      color: #ffff;
//      font-size: 28px;
//      letter-spacing: 0;
//      line-height: 34px;
//      padding: 51px 0 20px 15px;
//
//    }
//  }
//
//  .calendar-header {
//    padding: $calendar-padding 24px 0;
//
//    .calendar-previous-button,
//    .calendar-next-button {
//      position: relative;
//      font-size: calculateRem(24px);
//      color: highlight("highlight-three");
//      background: white;
//      border: none;
//      padding: 0px;
//      outline: none;
//
//      [dir="rtl"] & {
//        transform: rotate(180deg);
//      }
//    }
//  }
//
//  .ant-rangeDatepicker{
//    @media all and (max-width: 767px) {
//      .calendar-previous-button,
//      .calendar-next-button{
//        opacity: 0;
//      }
//    }
//  }
//
//  .calendar-footer-cont {
//    text-align: center;
//    color: highlight("highlight-three");
//    font-family: Lato;
//    font-size: calculateRem(14px);
//    font-weight: bold;
//    letter-spacing: 0;
//    line-height: calculateRem(17px);
//    padding: calculateRem(14px) 0;
//    cursor: pointer;
//  }
//
//  .ant-calendar-content {
//    padding: calculateRem(8px) calculateRem(11px) calculateRem(24px);
//    outline: none;
//  }
//
//  .calendar-controls {
//    display: flex;
//    margin: 5% 0;
//    align-items: center;
//  }
//
//  .ant-datepicker-cont {
//    position: relative;
//    width: 230px;
//
//    .datepicker-toggle button {
//      position: absolute;
//      right: calculateRem(18px);
//      top: calculateRem(10px);
//      width: calculateRem(25px);
//      height: calculateRem(20px);
//      line-height: calculateRem(22px);
//      font-size: 1.25rem;
//      color: highlight("highlight-three");
//      background: white;
//      border: none;
//      outline: none;
//    }
//
//    .ant-text-input.hasDatepicker {
//      padding: calculateRem(13px) calculateRem(12px);
//      font-size: calculateRem(12px);
//      line-height: calculateRem(15px);
//    }
//  }
//
//  .calendar-period-button {
//    flex: 1;
//    text-align: center;
//    color: #3a3a3a;
//    font-size: calculateRem(16px);
//    font-weight: bold;
//    letter-spacing: 2.5px;
//    line-height: calculateRem(19px);
//  }
//
//  .calendar-arrow {
//    display: inline-block;
//    width: 0;
//    height: 0;
//    border-left: $calendar-arrow-size solid transparent;
//    border-right: $calendar-arrow-size solid transparent;
//    border-top-width: $calendar-arrow-size;
//    border-top-style: solid;
//    margin: 0 0 0 $calendar-arrow-size;
//    vertical-align: middle;
//
//    &.calendar-invert {
//      transform: rotate(180deg);
//    }
//
//    [dir="rtl"] & {
//      margin: 0 $calendar-arrow-size 0 0;
//    }
//  }
//
//  .ant-calendar {
//    display: block;
//
//    button.motif-icon[disabled] {
//      color: rgba(0, 0, 0, 0.26);
//    }
//
//    .ant-calendar-table {
//      border-spacing: 0;
//      border-collapse: collapse;
//      width: 100%;
//      margin-bottom: 0px;
//      font-size: calculateRem(14px);
//      line-height: 1.42857143;
//
//      .calendar-table-header th {
//        padding: calculateRem(14px) 0;
//        color: #333333;
//        font-size: calculateRem(12px);
//        font-weight: bold;
//        letter-spacing: 0;
//        line-height: calculateRem(10px);
//        text-align: center;
//      }
//    }
//  }
//
//  .calendar-table-header-divider {
//    position: relative;
//    height: $calendar-header-divider-width;
//
//    &::after {
//      content: "";
//      position: absolute;
//      top: 0;
//      left: -$calendar-padding;
//      right: -$calendar-padding;
//      height: $calendar-header-divider-width;
//    }
//  }
//
//  .ant-calendar-body {
//    min-width: $calendar-body-min-size;
//
//    .calendar-body-cell {
//      position: relative;
//      text-align: center;
//      outline: none;
//      padding: 0px !important;
//      cursor: pointer;
//
//      .calendar-body-cell-content {
//        width: 100%;
//        padding-top: 100%;
//        color: #333333;
//        font-size: calculateRem(12px);
//        text-align: center;
//        position: relative;
//
//        span{
//          display: flex;
//          align-items: center;
//          justify-content: center;
//          position: absolute;
//          height: 100%;
//          width: 100%;
//          top: 0;
//          left: 0;
//          border: 2px solid transparent;
//          border-radius: 50%;
//        }
//
//        @include cdk-high-contrast(active, off) {
//          border: none;
//        }
//
//        &:not(.calendar-body-selected) span:hover {
//          font-size: calculateRem(18px);
//          color: highlight("highlight-three");
//          font-weight: 700;
//          border: 2px solid highlight("highlight-three");
//        }
//      }
//
//      .calendar-body-selected span {
//        background-color: highlight("highlight-three");
//        color: #fff;
//        font-size: calculateRem(18px);
//        font-weight: bold;
//        box-sizing: border-box;
//      }
//
//      &.rangeClass .calendar-body-cell-content span:hover{
//        border-radius: 0;
//      }
//    }
//  }
//
//  .calendar-body-label {
//    height: 0;
//    line-height: 0;
//    text-align: left;
//    padding-left: $calendar-body-label-side-padding;
//    padding-right: $calendar-body-label-side-padding;
//  }
//
//  .calendar-body-disabled {
//    cursor: default;
//  }
//
//  @include cdk-high-contrast(active, off) {
//
//    .datepicker-popup:not(:empty),
//    .calendar-body-selected {
//      outline: solid 1px;
//    }
//
//    .calendar-body-today {
//      outline: dotted 1px;
//    }
//
//    .cdk-keyboard-focused .calendar-body-active,
//    .cdk-program-focused .calendar-body-active {
//      &>.calendar-body-cell-content:not(.calendar-body-selected) {
//        outline: dotted 2px;
//      }
//    }
//  }
//
//  [dir="rtl"] {
//    .calendar-body-label {
//      text-align: right;
//    }
//  }
//
//  @media (hover: none) {
//    .calendar-body-cell:not(.calendar-body-disabled):hover {
//      &>.calendar-body-cell-content:not(.calendar-body-selected) {
//        background-color: transparent;
//      }
//    }
//  }
//
//  .datepicker-content {
//    display: block;
//    border-radius: 4px;
//
//    .ant-calendar {
//      box-sizing: border-box;
//      min-width: 360px;
//      width: 100%;
//      border: 1px solid rgba(0, 0, 0, 0.1);
//      border-radius: 4px;
//      background-color: #ffffff;
//      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
//
//      @media all and (max-width: 767px) {
//        min-width: 0;
//      }
//    }
//  }
//
//  .cdk-overlay-connected-position-bounding-box {
//    max-width: 100% !important;
//    width: 100%;
//  }
//
//  .datepicker-content-touch {
//    display: block;
//    max-height: 80vh;
//    overflow: auto;
//    margin: -24px;
//
//    .ant-calendar {
//      min-width: 250px;
//      min-height: 312px;
//      max-width: 750px;
//      max-height: 788px;
//    }
//  }
//
//  @media all and (orientation: landscape) {
//    .datepicker-content-touch .ant-calendar {
//      width: 64vh;
//      height: 80vh;
//    }
//  }
//
//  @media all and (orientation: portrait) {
//    .datepicker-content-touch .ant-calendar {
//      width: 80vw;
//      height: 100vw;
//    }
//  }
//
//  .cdk-visually-hidden {
//    border: 0;
//    clip: rect(0 0 0 0);
//    height: 1px;
//    margin: -1px;
//    overflow: hidden;
//    padding: 0;
//    position: absolute;
//    width: 1px;
//    outline: 0;
//    -webkit-appearance: none;
//    -moz-appearance: none;
//  }
//
//  .cdk-global-overlay-wrapper,
//  .cdk-overlay-container {
//    pointer-events: none;
//    top: 0;
//    left: 0;
//    height: 100%;
//    width: 100%;
//  }
//
//  .cdk-overlay-container {
//    position: fixed;
//    z-index: 1000;
//  }
//
//  .cdk-overlay-container:empty {
//    display: none;
//  }
//
//  .cdk-global-overlay-wrapper,
//  .cdk-overlay-pane {
//    display: -webkit-box;
//    display: flex;
//    position: absolute;
//    z-index: 1000;
//  }
//
//  .cdk-overlay-pane {
//    pointer-events: auto;
//    box-sizing: border-box;
//    max-width: 100%;
//    max-height: 100%;
//  }
//
//  .cdk-overlay-backdrop {
//    position: absolute;
//    top: 0;
//    bottom: 0;
//    left: 0;
//    right: 0;
//    z-index: 1000;
//    pointer-events: auto;
//    -webkit-tap-highlight-color: transparent;
//    -webkit-transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
//    transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
//    opacity: 0;
//  }
//
//  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
//    opacity: 1;
//  }
//
//  @media screen and (-ms-high-contrast: active) {
//    .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
//      opacity: 0.6;
//    }
//  }
//
//  .cdk-overlay-transparent-backdrop,
//  .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
//    opacity: 0;
//  }
//
//  .cdk-overlay-connected-position-bounding-box {
//    position: absolute;
//    z-index: 1000;
//    display: -webkit-box;
//    display: flex;
//    -webkit-box-orient: vertical;
//    -webkit-box-direction: normal;
//    flex-direction: column;
//    min-width: 1px;
//    min-height: 1px;
//  }
//
//  .calendar-arrow {
//    border-top-color: rgba(0, 0, 0, 0.54);
//  }
//
//  .calendar-body-label,
//  .datepicker-toggle {
//    color: rgba(0, 0, 0, 0.54);
//  }
//
//  .ant-calendar-body .calendar-body-today:not(.calendar-body-selected) {
//    border-color: rgba(0, 0, 0, 0.38);
//    color: highlight("highlight-three");
//    font-size: calculateRem(18px);
//    font-weight: bold;
//  }
//
//  .cdk-overlay-container .cdk-overlay-pane {
//    padding-top: calculateRem(8px);
//    padding-bottom: calculateRem(8px);
//  }
//
//  .calendar-body-disabled>.calendar-body-cell-content:not(.calendar-body-selected),
//  .calendar-table-header {
//    color: rgba(0, 0, 0, 0.38);
//  }
//
//  .calendar-table-header-divider:after {
//    background: rgba(0, 0, 0, 0.12);
//  }
//
//  /* .cdk-keyboard-focused .calendar-body-active > .calendar-body-cell-content:not(.calendar-body-selected),
//  .cdk-program-focused .calendar-body-active > .calendar-body-cell-content:not(.calendar-body-selected),
//  .calendar-body-cell:not(.calendar-body-disabled):hover > .calendar-body-cell-content:not(.calendar-body-selected) {
//    background-color: rgba(0, 0, 0, 0.04);
//  } */
//
//  .calendar-body-disabled>.calendar-body-today:not(.calendar-body-selected) {
//    border-color: rgba(0, 0, 0, 0.18);
//  }
//
//  .calendar-body-disabled>.calendar-body-selected {
//    background-color: rgba(63, 81, 181, 0.4);
//  }
//
//  .datepicker-content {
//    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
//    background-color: #fff;
//    color: rgba(0, 0, 0, 0.87);
//
//    @media all and (max-width: 767px) {
//      width: 91%;
//      max-width: 440px;
//      margin: 0 auto;
//    }
//  }
//
//  .datepicker-content.dp-warn .calendar-body-selected,
//  .datepicker-content.dp-accent .calendar-body-selected {
//    background-color: #ff4081;
//    color: #fff;
//  }
//
//  .datepicker-content.dp-accent .calendar-body-disabled>.calendar-body-selected {
//    background-color: rgba(255, 64, 129, 0.4);
//  }
//
//  .calendar-body-today.calendar-body-selected,
//  .datepicker-content.dp-warn .calendar-body-today.calendar-body-selected,
//  .datepicker-content.dp-accent .calendar-body-today.calendar-body-selected {
//    box-shadow: inset 0 0 0 1px #fff;
//  }
//
//  .datepicker-content.dp-warn .calendar-body-disabled>.calendar-body-selected {
//    background-color: rgba(244, 67, 54, 0.4);
//  }
//
//  .datepicker-content-touch {
//    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
//  }
//
//  .datepicker-toggle-active {
//    color: #3f51b5;
//  }
//
//  //** Ant DatePicker Styles Ends Here **//
//
//  //** Date Range Styles Starts Here **//
//  .daterangepicker-input {
//    position: relative;
//  }
//
//  .example-custom-date-class {
//    >div {
//      background: highlight("highlight-three") !important;
//      color: white !important;
//      border-radius: 100%;
//    }
//  }
//
//  .customMitifyCalender {
//    font-size: 1.25rem;
//    color: highlight("highlight-three");
//    position: absolute;
//    top: calculateRem(13px);
//    right: calculateRem(18px);
//    cursor: pointer;
//  }
//
//  .calenderOptionList {
//    width: 266px;
//    float: left;
//    overflow: hidden;
//    padding: 0px;
//    color: highlight("highlight-three");
//    font-size: calculateRem(12px);
//    text-align: center;
//    padding: calculateRem(30px) calculateRem(24px);
//    height: 100%;
//
//    >div {
//      padding: calculateRem(15px) 0;
//    }
//
//    .daySelected {
//      border: 1px solid highlight("highlight-three");
//    }
//  }
//
//  .daterangepicker-input {
//    width: 230px;
//
//    /* .hasDatepicker.pfText {
//      &:hover,
//      &:focus {
//        width: 100%;
//      }
//      width: 100%;
//    } */
//    .hasDatepicker {
//      padding: 0.92857143rem 0.85714286rem;
//      font-size: 0.85714286rem;
//      line-height: 1.07142857rem;
//    }
//  }
//
//  .ant-rangeDatepicker {
//    @media all and (max-width: 767px) {
//      width: 100%;
//    }
//
//    width: 360px;
//  }
//
//  .daterange-wrapper {
//    position: absolute;
//    z-index: 9999;
//    margin-top: 46px;
//
//    @media screen and (max-width: 767px) {
//      margin-left: -36px;
//    }
//  }
//
//  // allow mobile click through to close modal on left and right side
//  @media all and (max-width: 767px){
//    .cdk-overlay-pane.datepicker-popup{
//      pointer-events: none !important;
//    }
//    .ant-calendar{
//      pointer-events: auto;
//    }
//  }
//
//  .daterange-calender {
//    cursor: pointer;
//    box-shadow: 0 24px 24px 0 rgba(0, 0, 0, 0.05);
//    border: solid 1px #e9eaeb;
//    background: rgba(255, 255, 255, 1);
//    clear: both;
//    top: 10px;
//    position: relative;
//    width: fit-content;
//
//    .range-left-comp {
//      position: relative;
//
//      @media screen and (min-width: 768px) {
//        display: flex;
//      }
//
//      @media all and (min-width: 768px) {
//        border-left: 0.89px solid rgba(229, 229, 229, 1);
//      }
//
//      height: 100%;
//    }
//
//    .datePicker-footer {
//      display: none;
//    }
//
//    .rangeClass.calendar-body-cell {
//      background-color: rgb(94, 97, 102);
//      color: #333333;
//    }
//
//    .calendar-footer-cont {
//      display: none;
//    }
//
//    .ant-calendar-body {
//
//      .rangeStartDayClass > div span,
//      .rangeEndDayClass > div span {
//        background-color: highlight("highlight-three") !important;
//        color: white !important;
//      }
//
//      .rangeEndDayClass {
//        background: rgba(36, 83, 166, 0.1);
//        border-radius: 0 999px 999px 0;
//      }
//
//      .rangeStartDayClass {
//        background: rgba(36, 83, 166, 0.1);
//        border-radius: 999px 0 0 999px;
//      }
//
//      .rangeStartDayClass.rangeEndDayClass {
//        border-radius: 999px;
//
//      }
//    }
//
//    .date-apply-btn {
//      text-align: right;
//      margin: 0 20px 20px 0;
//
//      @media screen and (max-width: 767px) {
//        position: fixed;
//        bottom: 0;
//        background-color: rgba(255, 255, 255, 1);
//        box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.08);
//        width: 100%;
//        padding: 15px;
//        text-align: left;
//        margin-bottom: 0;
//
//        input {
//          width: 46%;
//        }
//      }
//
//      .cancel_btn {
//        margin-right: 20px;
//
//        @media screen and (max-width: 768px) {
//          border: none;
//        }
//      }
//    }
//
//    .rangeClass.calendar-body-cell {
//      background-color: rgba(36, 83, 166, 0.1);
//      color: #333333;
//      height: 100%;
//      width: 100%;
//      border-radius: 0;
//    }
//
//    .ant-calendar+.ant-calendar .calendar-previous-button {
//      opacity: 0;
//      pointer-events: none;
//    }
//  }
//
//  .calenderOptionList.moveLeft {
//    width: 160px;
//  }
//
//  //** Date Range Styles Ends Here **//
.fwc-date-picker-input {
  input {
      font-size: calc(2*var(--fwcu));
      border: 1px solid var(--color-gray-three);
      border-radius: var(--border-radius);
      box-shadow:  var(--box-shadow);
      color: var(--color-gray-two);
      padding: calc(0.5*var(--fwcu)) calc(2*var(--fwcu));
      height: calc(5*var(--fwcu));
      width:100%;
  }
}
