@import "./icon_vars";

/** Badges **/

/** Alerts **/
.er-motif-alert {
  #dismissibleAlerts {
    .cmp-example-wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .btn {
        margin-top: 1.5rem;
      }
    }
  }

  .ant-alert {
    margin: 1rem 0;

    .ant-alert-left {
      background-color: color("primary");
    }

    .alert-icon {
      font-family: $motif-font-family;
      color: $color-white;
      padding: spacer(5);
      font-size: 1.5rem;

      &.alert-icon-positive {
        &::before {
          content: "\e94a";
        }
      }

      &.alert-icon-negative {
        &::before {
          content: $motif-error;
        }
      }

      &.alert-icon-info {
        &::before {
          content: "\e9fb";
        }
      }

      &.alert-icon-disaster {
        &::before {
          content: "\e9b5";
        }
      }
    }

    .ant-alert-main {
      padding: spacer(4) 10px spacer(4) spacer(3);
      background-color: map-get($ant-motif-grays, 100);

      .fa.fa-remove {
        font-size: 1.25rem;
      }
    }

    &.ant-negative {
      .ant-alert-left {
        background-color: color("support-danger");
      }
    }

    &.ant-positive {
      .ant-alert-left {
        background-color: $accent-color-green;
      }
    }
  }

  /** Dismissable Alerts **/
  .ant-alert.ant-dismissable .ant-alert-main button.ant-dismiss-alert {
    top: spacer(4);
  }

  .dismiss-icon {
    font-family: 'employer-portal-motif';
    color: $ant-motif-white;
    padding: spacer(4);
    font-size: 1.5rem;

    &.dismiss-icon-positive {
      &::before {
        content: $motif-checkmark;
      }
    }

    &.dismiss-icon-negative {
      &::before {
        content: $motif-error;
      }
    }

    &.dismiss-icon-info {
      &::before {
        content: "\e9fb";
      }
    }

    &.dismiss-icon-remove {
      color: black;
      font-size: 1.7rem;

      &::before {
        content: $motif-cancel;
      }
    }
  }

  #errorDialog,
  #modalFullscreen {
    display: none;
  }

  /** Modals **/
  [data-uxd-modal-cmp] {
    background: rgba(55, 71, 90, 0.9);

    .modal-content {
      max-width: 750px;
      margin: 15% auto;
      border-radius: 3px;
      padding: spacer(7) spacer(5) spacer(11) spacer(5);
      box-shadow: none;
      font-family: "Lato", sans-serif;

      .modal-title {
        font-size: calculateRem(22px);
      }

      .modal-component-body {
        font-size: calculateRem(14px);
        line-height: 2.2;
      }

      p {
        line-height: 2.2;
        font-size: calculateRem(14px);
      }

      .modal-title {
        padding-top: spacer(6);
        padding-bottom: spacer(6);
      }

      .modal-footer {
        display: none;
      }

      .close {
        .fa.fa-remove {
          font-size: calculateRem(35px);
          color: map-get($ant-motif-grays, 500);
          margin-right: .5rem;
        }
      }
    }
  }

  @media only screen and (max-width: $breakpoint-sm) {
    [data-uxd-modal-cmp] {
      .modal-content .close {
        .fa.fa-remove {
          font-weight: bold;
          border: 2px solid $ant-motif-black;
          border-radius: 100%;
          font-size: calculateRem(30px);
          padding: spacer(2);
        }
      }
    }
  }

  /** Tooltips **/
  .popIcon {
    .tooltip-icon {
      font-family: $motif-font-family;

      &:before {
        content: $motif-help;
      }
    }

    .glyphicon,
    .fa {
      color: $ant-motif-black;

      &:hover,
      &:focus {
        color: map-get($ant-motif-grays, 400);
      }
    }
  }

  .popover {
    box-shadow: 2px 2px 4px 0 rgba(220, 220, 220, 0.5);
    color: #575757;
    max-width: 239px;
    // padding: 20px;

    .popover-content {
      padding: spacer(5) spacer(5);
    }

    .popover-close {
      z-index: 2;
      height: spacer(7);
      width: spacer(7);
      padding: 0;

      a {
        text-decoration: none;
        display: inline-block;
        font-size: ($font-size-base * 0.78);
        z-index: 2;

        span {
          font-size: 17px;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }

  .popover-content.error {
    color: $accent-color-red;
  }

  .popover .popover-close {
    position: relative;
    top: -15px;
    right: -15px;

    a {
      text-decoration: none;
      color: $ant-motif-white;
      display: inline-block;
      top: 0;
      left: 0;
      position: relative;
      border-bottom: none;

      .fa {
        color: $ant-motif-white;
      }
    }
  }

  .popover.top {
    border: solid 1px color("secondary-light");
  }

  .popover.right {
    margin-left: 5px;
  }

  .popover.bottom {
    margin-top: 5px;
  }

  .popover.left {
    margin-left: -5px;
  }

  /** Loading Indicators **/

  /*-------------------------------------------*\
    Alerts
  \*-------------------------------------------*/
  // **
  // * Global Alert Container
  // *
  // * 1. comment
  // *
  .ant-global-alert-container {
    display: block;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 100;

    .global-alert-list {
      display: block;
      list-style: none;
      margin: 0;
      padding: 0;
    }

    &.static {
      position: static;
      background-color: #fff;
      margin-top: 0;

      .global-alert-item {
        margin-top: 12px;

        &:last-of-type {
          margin-bottom: calculateRem(64px);
        }

        .ant-inline-alert-body {
          padding: 0.70rem .5rem;

          .dismiss-button {
            background: #fff;

            span {
              font-size: calculateRem(26px);
              height: 16px;
              width: 16px;
              display: inline-block;
            }
          }
        }
      }

    }
  }

  .ant-global-alert.ant-global-alert--light {
    width: 100%;
    background-color: $white;
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.05);

    .ant-global-alert-body {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
    }

    .alert-message {
      padding: spacer(4) spacer(5);
      color: gray(300);
      line-height: 1.57;
    }

    .alert-icon {
      display: inline-flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      font-family: $motif-font-family;
      font-size: 2.38rem;
      min-height: calculateRem(80px);
      width: calculateRem(80px);
      background-color: gray(100);
    }

    .dismiss-button {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background-color: inherit;
      margin-left: auto;
      padding-right: spacer(3);

      &:not(:focus) {
        outline: none;
        border: none;
      }

      .alert-icon-close {
        display: block;
        font-family: $motif-font-family;
        font-size: calculateRem(16px);
        padding-top: spacer(5);
        padding-right: spacer(5);

        &::before {
          display: block;
          content: $motif-delete;
          color: gray(400);
        }
      }
    }

    &.ant-alert-success {
      $success-color: $support-success;

      .alert-icon {
        color: $success-color;

        &::before {
          content: $motif-checkmark;
        }
      }
    }

    &.ant-alert-error {
      $error-color: $support-error;

      .alert-icon {
        color: $error-color;

        &::before {
          content: $motif-cancel;
        }
      }
    }

    &.ant-alert-warning {
      $warning-color: $support-error;

      .alert-icon {
        color: $warning-color;

        &::before {
          content: $motif-error;
        }
      }
    }

    &.ant-alert-info {
      $information-color: $support-info;

      .alert-icon {
        color: $information-color;

        &::before {
          content: "\e9fb";
        }
      }
    }
  }

  .ant-global-alert.ant-global-alert--solid {
    color: $white;
    padding-top: spacer(4);
    padding-bottom: spacer(4);

    .alert-icon {
      font-family: $motif-font-family;
      font-size: 1.5rem;
      margin-right: .5rem;
    }

    &.ant-alert-success {
      $success-color: $support-success;
      background-color: $success-color;

      .alert-icon {
        color: $white;

        &::before {
          content: $motif-checkmark;
        }
      }
    }

    &.ant-alert-error {
      $error-color: $support-error;
      background-color: $error-color;

      .alert-icon {
        color: white;

        &::before {
          content: $motif-cancel;
        }
      }
    }

    &.ant-alert-warning {
      $warning-color: $support-warning;
      background-color: $warning-color;

      .alert-icon {
        color: white;

        &::before {
          content: $motif-error;
        }
      }
    }

    &.ant-alert-info {
      $information-color: $support-info;
      background-color: $information-color;

      .alert-icon {
        color: white;

        &::before {
          content: "\e9fb";
        }
      }
    }

    .ant-global-alert-body {
      display: flex;
      align-items: center;
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;
    }

    .dismiss-button {
      // 3.
      line-height: 1rem;
      font-size: 1rem;
      margin-left: auto;
      color: inherit;

      &:not(:focus) {
        background-color: transparent;
        border: none;
        outline: none;
      }
    }
  }

  // **
  // * Inline Alert Container
  // *
  // * 1. comment
  // *
  .ant-inline-alert-container {
    .inline-alert-list {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }

  // **
  // * Motif Inline Alert
  // *
  // * 1. Inline Alert
  // * 2. Inline Alert icon
  // * 3. Close / Dismiss button
  .er-ant-inline-alert {
    // 1.
    border: 1px solid gray(200);
    border-left: 5px solid gray(200);
    margin: spacer(2);
    color: gray(400);

    .alert-icon {
      // 2.
      font-family: $motif-font-family;
      font-size: 1.5rem;
      margin-right: .5rem ;
    }

    &.ant-alert-success {
      $success-color: $support-success;
      border-left-color: $success-color;

      .alert-icon {
        color: $success-color;

        &::before {
          content: $motif-checkmark;
        }
      }
    }

    &.ant-alert-warning {
      $warning-color: accent("accent-one");
      border-left-color: $warning-color;

      .alert-icon {
        color: $warning-color;

        &::before {
          content: $motif-error;
        }
      }
    }

    &.ant-alert-error {
      $error-color: $support-error;
      border-left-color: $error-color;

      .alert-icon {
        color: $error-color;

        &::before {
          content: $motif-cancel;
        }
      }
    }

    &.ant-alert-info {
      $information-color: $support-info;
      border-left-color: $information-color;

      .alert-icon {
        color: $information-color;

        &::before {
          content: "\e9fb";
        }
      }
    }

    .ant-inline-alert-body {
      display: flex;
      align-items: center;
      padding: 0.70rem .5rem;
    }

    .dismiss-button {
      // 3.
      line-height: 1rem;
      font-size: 1rem;
      margin-left: auto;

      &:not(:focus) {
        border: none;
        outline: none;
      }
    }
  }

  .universalInlineAlert {
    ul.inline-alert-list {
      .inline-alert-item {
        margin: 12px 0;

        &:last-of-type {
          margin-bottom: 24px;
        }

        &:first-of-type {
          margin-top: 24px;
        }

        .dismiss-button {
          background: #fff;

          span {
            font-size: calculateRem(26px);
            height: 16px;
            width: 16px;
            display: inline-block;
          }
        }
      }
    }
  }
}
