//debug (removable)
.black-_border
{
    border: solid 2px #000;
}
//css for debugging above (removable)

.complete-registration label.pcLabel
{
    float: none;
}

.member-registration-wrapper{
    .margin-left-none-float
    {
        float: left;
    }

    .margin-top-3
    {
        margin-top: 3rem;
    }
    .margin-bottom-3
    {
        margin-bottom: 3rem;
    }

    .margin-top-2
    {
        margin-top: 2rem;
    }
    .margin-bottom-2
    {
        margin-bottom: 2rem;
    }

    .margin-top-1
    {
        margin-top: 1rem;
    }
    .margin-bottom-1
    {
        margin-bottom: 1rem;
    }

    .woc-checkbox
    {
        margin:0 1rem 1rem 0;
    }

    .woc-checkboxes
    {
        margin-bottom: -2rem;
    }

    .line-top
    {
        border-top: solid 2px #dcdcdc;
    }

    .line-bottom
    {
        border-bottom: solid 2px #dcdcdc;
    }

    .woc-print-icon-vert
    {
        position:relative;
        top: .3rem;
    }

    .woc-bottom-name
    {
        position:relative;
        top: -1.3rem;
        color: #a3a3a3;
    }

    .uxd-error-container .uxd-error-message.material
    {
        margin-top: -1.8rem;
    }
    
    .woc-checkbox-adjust1
    {
        max-width:600px; margin:0 auto;
    }

    .er-mask-toggle.fa.fa-eye-slash
    {
        right: 3px;
    }

    .er-mask-toggle.fa.fa-eye
    {
        right: 3px;
    }

    .error-adjust-margin-top-left
    {
        margin-top: -1.4rem;
        margin-left: -1.4rem;
    }
}

.container-fluid
{
    background-image:url('../images/employer-fpo.png'), url('../images/employer-fpo.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.aso-fi-80 {
    // fle
    flex-basis: 80%;
}
.progress {
    height: 11px;
    border-radius: 5px;
  
  }
  .progress-bar {
  font-size: 13px;
  line-height: 13px;
  }
  .ome-progress-bar{
      width: 60%;
      margin:0 auto;
}

//around line 57 in waiver-coverage
// .woc-checkbox-adjust1 input.pcOption
// {
//     position: relative;
//     top: 1.3rem;
//     left: 4rem;
//     float: none;
// }