%err-pseudo{
    font-family: FontAwesome;
    line-height:1.2em;
    font-size:1rem;
    vertical-align:middle;
    margin-right:5px;
}

// left align long label text for checkbox
%flex-check-box {
    display:flex;
    &:before {
        flex:1 0 1rem;
        top:.15rem;
    }
}

.uxd-error-container .uxd-error-message{
    text-align: left;
    &[hidden] { display:none; }
    //prevents error message wrapping under icon
    text-indent:-22px;
    margin-left:22px;

    &:before{
        @extend %err-pseudo;
        content: "\f24b";
    }
    &-green{
        color: $accent-color-teal;
    &:before{
        @extend %err-pseudo;
        content: "\f17b";
        }
    }
    font-size: 0.8571rem;
    color: $accent-color-red;
    font-weight: 400;
    &.material{
        margin-top: -1.4rem;
    }
}

.er-full-width-links{
    .col-xs-6{
        @extend .col-xs-12 !optional;
}
.footer-links{
    padding:0;
    li{
        border-bottom:1px solid $gray-2;
        &:first-child{
            border-top:1px solid $gray-2;
        }
    }
}
.headline-half{
    margin-bottom:0;
}
}

// address selector for view-print-id inside modal

.modal-body{
    .prLabel{
        margin:.5rem 0;
        .label-text{
            text-align:left;
            font-weight:400;
            max-width:none;
        }
    }
}



//custom button dropdown class
.open>div .dropdown-menu li.providerLink{
    border-top:1px solid $gray;
    a{
        font-weight:600;
    }
    &~.providerLink{
        border-top:none;
        border-bottom:1px solid $gray;
}
}

.ant-lg-select, .ant-md-select, .ant-sm-select {
    &.er-select {
        .pfSelect .psButton {
            background:transparent;
            color: $neutral-dark-gray;
            border: none;
            border-bottom: 1px solid $neutral-dark-gray;
            border-radius: 0;
            width:100%;
            .psArrow{
                border-left:none;
            }
        }
        .psDropdown {
            border-radius:0;
            max-width:inherit;
        }
}
    &.material {
        .pfSelect {
            margin-bottom:1.25rem;
            legend{
                color:$primary-color-light-blue;
                margin-bottom:0;
                font-weight:400;
                font-size: 0.86111rem;
                line-height: 1.42857rem;
                padding-left:$sp025;
            }
        }
        &.no-marg .pfSelect {
            margin-bottom:0;
        }
    }
}
legend {
  border-bottom: none;
}
.select-wrapper .uxd-error-container{
        margin-top:-1rem;
}
// override wcs-expand collapse to allow for form transclusion - user enrollment flow
div[data-uxd-expand-collapse-wcs].ec-form
.collapse-block{
  margin: 0;
  .ant-collapse.blue {
    border: none;
    .ant-collapse-heading {
      border-top: none;
      text-align: center;
      //Card visual update for grey background
      font-weight: 300;
      font-size: 2.223rem;
      padding: 2rem calc(1.8rem + 15px) 2rem 15px;
      background-color: $light-gray;
      .fa {
        color: $primary-color-light-blue;
        font-size: 2.3rem;
      }
      .fa-check-circle-o {
        color: $primary-color-light-blue;
      }
    }
    }
}
//when collapse is nested inside other collapse - add dependent section in new enrollment form
.nested-form div[data-uxd-expand-collapse-wcs].ec-form
.collapse-block
.ant-collapse.blue
.ant-collapse-heading{
            text-align:left;
}
.uxd-text-input .popIcon{
    position:absolute;
    top: 30px;
    right:0;
}
//Styling toggle switches with inline label - enrollment flow

.er-user-information {
  label{
    flex:1 1 20%;
  }
  .toggleComp{
    flex:1 1 65%;
  }
}

@media screen and ( min-width:$breakpoint-md ){
    .toggle-wrapper{
        display:flex;
        flex-flow:row wrap;
        // align-items:center;
        label{
            // flex:1 1 auto;
            max-width:75%;
        }
        .toggleComp{
            flex:1 1 auto;
            margin-left:5px;
        }
    }
    // new-enrollment full width toggle comp
    .coverage-types
    .toggle-wrapper
    .toggleComp{
        flex:1 0 auto;
    }
  .er-user-information {
    label{
      flex:1 1 13%;
    }
    .toggleComp{
      flex:1 1 65%;
    }
  }
        //custom style for toggle in col-3
    .toggle-wrapper.toggle-small{
      display:flex;
      flex-flow:row wrap;
      // align-items:center;
      label{
          // flex:1 1 auto;
          max-width:75%;
      }
      .toggleComp{
          flex:1 1 auto;
          margin-left:5px;
      }
        label{
            flex:1 1 7%;
        }
        .toggleComp{
            flex:1 1 30%;
        }
    }


}

//Fix for PL Tool Tips
//So they do not overlap navbar
.popover.right,
.popover.left,
.popover.top,
.popover.bottom {
  z-index: 20;
}

// IE hides little 'x' to clear the field
.uxd-text-input{
    input[type="text"], input[type="password"]{
        &::-ms-clear, ::-ms-reveal{
            display:none;
        }
    }
}

//Fix for PL text input for zooming out 75% or smaller
.uxd-text-input .bottom-bar {
  padding-bottom: 1px;
  margin-bottom: -1px;
}

.uxd-text-input .bottom-bar,
.uxd-text-input .bottom-bar:before {
  margin-bottom: -1px;
  padding-bottom: 1px;
}
//overriding radios for 2fa flow
.two-fa-sender .pfRadio span{
    display:flex;
    flex-flow:column nowrap;
    align-items:center;
    .radio-wrapper{
        display:flex;
        flex-flow:row wrap;
        width:100%;
        padding:1rem 0;
        div{
            width:50%;
        }
        .icon{
            flex:1 0 auto;
            display: flex;
            flex-flow: row nowrap;
            // min-width: 100%;
            justify-content: flex-start;
            padding-left:35px;
            .fa{
                color:$neutral-dark-gray;
                font-size:1.5rem;
                margin-right:5px;
            }
        }
        .radio-comp{
            flex:1 0 auto;
            .prLabel{
                display:flex;
                flex-flow:row nowrap;
                justify-content: flex-start;
            }
        }
    }
}
//no padding on first radio
.pfRadio {
    .pfRadioLeft .prLabel { padding-left:0; }
    //fold up vertical
    .pfRadioFold .prLabel { padding-left:0; padding-right:$sp2; }
}

// override radios label and radios centered
.pfRadio.centered{
    span{
        display:flex;
        justify-content: center;
    }
    .prLabel{
        float:inherit;
    }
}
.pfRadio.h3-heading{
    .prLabel{

    }
}

//No textwrap checkbox
.no-txt-wrap-chbx {
    .pcLabel {
        position: relative;
        padding:0 $sp1*1.5;
        &:before {
            position: absolute;
            left:0;
        }
    }
}

// Checkbox with link for label
.er-chkbx-lnk {
    .pcLabel {
        width: $sph05 + $sph025;
        height: $sph1 - $sph0125;
        overflow:hidden;
        margin-bottom: 0;
    }
}


// from pl partials/variables
// $breakpoint-xs: 480px !default;
// $breakpoint-sm: 801px !default;
// $breakpoint-md: 1024px !default;
// $breakpoint-lg: 1200px !default;

@media screen and ( min-width:$breakpoint-sm ){
    .two-fa-sender .toggle-pattern-v2 .pfRadio span{
        display:flex;
        flex-flow:column nowrap;
        align-items:center;
        .radio-wrapper{
            display:flex;
            flex-flow:row nowrap;
            .icon{
                min-width: 43%;
                justify-content: flex-end;
                padding-left:0;

            }
        }
    }
}

//Double Tabbed View
.double-tabbed-view {
    margin-top:$sp1;
    margin-bottom:$sp1;
    span.tab-label { display:none; }
    ul.nav-pills { @extend .body-lg; }
    .tabs.teal .tab-content { padding-top:0; }
}

//Update design for tabs
.tabs.teal ul.nav-pills li.active {
    a, a:link, a:visited, a:hover, a:visited:hover {
        color: $neutral-dark-gray;
        font-weight: 800;
    }
}


// Fixes iOS zoom on input issue
// TODO: Is this the right solution?  At least keep for Perfecto testing.
@supports (-webkit-overflow-scrolling: touch) {
    .uxd-text-input input[type="text"],
    .uxd-text-input input[type="password"] {
      font-size: 16px;
    }
}
// Design updates to Step Navigation tabs
.step-navigation {
  // Hide divider before the first tab
  li:nth-child(1) > span.ez-sep {
    display:none;
  }
  // Change divider to white on completed tabs
  .completed {
    .ez-sep {
      background-color:#FFFFFF;
    }
  }
  // Hide divider when between the complete and non-completed tabs
  li.completed + li:not(.completed) span.ez-sep,
  li.active + li:not(.active) span.ez-sep {
    opacity: 0;
  }
  // Change divider to white on active tabs
  li .active{
    .ez-sep {
      background-color:#FFFFFF;
      opacity:1 !important;
    }
  }
}
// Manage PCP dropdown width
.lister-pcp-wrap #psButtonchoosePCPDropdown {
  min-width:222px;
  display:flex;
  justify-content:flex-end;
  .buttonMain {
    max-width:300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.user-agreement {
  .userTable th {
    font-size:0.8rem
  }
  .electSigCallOut p {
    font-size:16px;
    padding-left: 0.25rem;
  }
}
/* Moved over from style blocks in the html component */
.eaua-detail-grid
{
  margin: 2rem 0;
}
.eaua-detail-grid div
{
  padding-left: 0;
  margin-bottom: .5rem;
  overflow-wrap: break-word;
}
.margin-fix-elecSig
{
  margin-left: 1rem;
}

// todo check if we want to do this
// fix for chome autofill yellow background
.uxd-text-input{
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active  {
        // box-shadow:none;
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
}


// Max width set inside of container-fluid
.col-sm-10.col-sm-offset-1:not(.er-revert-col-max), .col-xs-10.col-xs-offset-1:not(.er-revert-col-max)  {
    @media screen and (min-width:$breakpoint-sm) {
        width:83.333%; //matches col-10 from boostrap
        max-width: 1170px;
        margin:0 auto;
        float:none;
    }
}

.er-slider-revert-col-max {
    .col-sm-10.col-sm-offset-1{
        width: 100%;
        max-width: none;
    }
}

// Override for Mobile App web view wrapper issue
@media screen and (max-width: $breakpoint-xs) {
  .modal, .modal-backdrop {
    position: fixed;
  }
}
[data-uxd-dropdown-cmp] {
  .psArrow:before {
    font-family: 'FontAwesome' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    content: "\f0d7";
    border-left: none;
    line-height: 0;
    margin-left: 0;
    color: #0079C2;
    background: transparent;
  }
  .psArrow {
    padding: 0;
  }
}
