.ant-chart-content {
  display: block;
  width: 75%;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(50%);
  font-family: "Lato", sans-serif;
  font-size: 14px;
  text-align: center;
  color: #666666;
}
.donut-chart-container {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  position: relative;
  z-index: 1; }
@media screen and (max-width: 800px) {
  .donut-chart-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column; } }
.donut-chart-legend .legend-title {
  font-weight: 700;
  margin: 25px 0; }
.donut-chart-legend dl {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row;
  flex-wrap: wrap;
  width: 100%;
  overflow: visible;}
.donut-chart-legend dl dt {
  padding: 18px 0;
  border-top: 1px solid #CCCCCC;
  -webkit-box-flex: 0;
  flex: 0 0 75%;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 400;
  &:last-child {
    border-bottom: 1px solid #CCCCCC;
  }
  .fwc-last-right {
    font-size: 14px;
  }
}
.donut-chart-legend dl dd {
  padding: 18px 0;
  border-top: 1px solid #CCCCCC;
  -webkit-box-flex: 0;
  flex: 0 0 25%;
  margin-left: auto;
  text-align: right;
  overflow: hidden;
  font-size: 1.28571429rem; }
.donut-chart-legend dl .key {
  display: -webkit-box;
  display: flex;
  font-size: 14px}
.donut-chart-legend dl [class*="key-color"] {
  height: 0.5rem;
  width: 2.42857143rem;
  margin-right: 1rem;
  display: inline-block;
  border-radius: 5px;
  position: relative;
  top: 7px; }
.donut-chart-legend dl [class*="key-color"].key-color-one {
  background-color: #FF4D61; }
.donut-chart-legend dl [class*="key-color"].key-color-two {
  background-color: #00D795; }
.donut-chart-legend dl [class*="key-color"].key-color-three {
  background-color: #511284; }
.donut-chart-legend dl [class*="key-color"].key-color-four {
  background-color: #F2BC35; }
.donut-chart-legend dl .key-term {
  -webkit-box-flex: 1;
  flex: 1; }
.ant-chart-container {
  position: relative;
}
.ant-chart-container .ant-chart-content-positioner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ant-chart-container .chart-unit {
  color: #333333;
  font-size: 28px;
  margin: 0;
}
.ant-chart-container .chart-text {
  color: #666666;
}
.ant-chart-pie-container canvas {
  display: block;
  visibility: hidden;
  width: 100%;
  height: 100%;
}
.ant-chart-pie-container svg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: visible;
}
.ant-chart-pie-container .tool-tip {
  position: absolute;
  width: 0;
  height: 0;
  overflow: visible;
  background-color: black;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.ant-chart-pie-container .tool-tip .arrow_box {
  position: absolute;
  background: #fff;
  border: 1px solid #286CE2;
  padding: 10px;
  text-align: center;
  width: 125px;
  transform: translate(-50%, -115%);
  left: 0;
  top: 0;
}
.ant-chart-pie-container .tool-tip .arrow_box:after {
  top: calc(100% - 1px);
  left: 50%;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fff;
  transform: translateX(-50%);
}
.ant-chart-pie-container .tool-tip .arrow_box:before {
  top: 100%;
  left: 50%;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #286CE2;
  transform: translateX(-50%);
}
.ant-chart-pie-container .tool-tip .pie-value {
  font-size: 12px;
  font-weight: 700;
  margin: 5px 0;
}
.ant-chart-pie-container .tool-tip .pie-label {
  font-size: 12px;
}
.ant-pie-chart {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ant-pie-chart .radial-progress-0 path {
  fill: #FF4D61;
}
.ant-pie-chart .radial-progress-1 path {
  fill: #00D795;
}
.ant-pie-chart .radial-progress-2 path {
  fill: #511284;
}
.ant-pie-chart .radial-progress-3 path {
  fill: #F2BC35;
}
.searchSubscriber {
  .search-header {
    font-size: 38px;
    font-weight: normal;
  }
  .margin-horizontal {
    margin-left: 5%;
    margin-right: 5%;
  }
}
//Expand with blue background
.er-fwc-expand {

}
.promo-container {
  .promo-heading {
    font-size: 28px;
    font-weight: 300;
    font-family: "Open Sans", "open-sans", sans-serif, sans-serif;
    color: #FFFFFF;
    line-height: normal;
  }

  .promo-sub-head {
    font-size: 16px;
    font-weight: bold;
    color: #FFFFFF;
  }
  .promo-icons {
    justify-content: space-around;
  }
  .promo-phone-img {
    max-width: 220px;
  }
}

//TABS
.motif-tabs.tabs-container {
  .tabs {
    display: flex;
    font-family: var(--font-sans-serif);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 30px 0 30px 0;
  }
  .button-tab {
    cursor: pointer;
    border: solid 1px #e9e9e9;
    background-color: #fff;
    color: #286ce2;
    padding: 11px 47px 10px 48px;
  };

  .tab-left{
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .tab-right{
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .selected-tab {
    color: #fff;
    background-color: #286ce2;
  }
  .unselected-tab {
    background-color: #fff;
  }
  .button-tab:hover:not(.selected-tab) {
    background-image: linear-gradient(
        180deg
      , rgba(0,0,0, 0), rgba(0, 0, 0, 0.2)), linear-gradient(
        180deg
      , var(--color-primary), var(--color-primary));
    color: white;
  }
}
.motif-slider {
  top: 0px !important;
  padding: 0 !important;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 801px) {
    left: 20% !important;
  }
  .motif-slider-header {
    background: var(--color-white);
    height: 50px;
    margin-bottom: 24px;
    margin-left: 1rem;
    margin-top: 1rem;
    a {
      border-bottom: none;
      text-decoration: none;
    }
    .epm-icon-arrows-long-right {
      font-size: 22px;
      color: var(--color-gray-two);
      background: #f7f7f7;
      padding: 15px;
    }
  }
}
.motif-slider-actions {
  text-align: end !important;
  padding: calc(var(--fwcu)*3);
  border-top: 1px solid var(--color-gray-five);
  box-shadow: 0 -1px 4px 1px rgba(0,0,0,.05);
  display: flex;
  justify-content: flex-end;
}
.motif-slider-scroll {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.motif-slider-content-cont {
  overflow-y: auto;
  height: 100%;
  flex: 1 1;
}
.motif-slider-content-cont-for-smartsrch {
  height: 100%;
  flex: 1 1;
}
.paper-slider-container .paper-slider-content.motif-slider {
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100%;
}


//panel
.fwc-panel-button {
  padding: 5px 15px;
  border: solid 1px #eee;
  border-radius: 6.5px;
  font-size: 14px;
  margin-right: 10px;
}
.fwc-panel-container {
  position: relative;
  .fwc-panel-wrapper {
    overflow: hidden;
    opacity: 0;
    height: 0;
    display: none;
    transition: all 200ms ease;
    &.open {
      z-index: 1;
      opacity: 1;;
      display: block;
      height: auto;
      position: absolute;
      top: 35px;
      border: 1px solid var(--color-gray-four);
      border-radius: var(--border-radius);
      background-color: var(--color-white);
    }
    .fwc-checkbox {
      label {
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
  .fwc-top-panel-content {
    padding: 20px;
  }
  .fwc-panel-actions {
    border-top: 2px solid #eee;
    padding: 20px;
    display: flex;
  }
}
.multi-expand-item {
  padding: 20px 0;
  border-top: 1px solid #ccc;
  &:last-of-type {
    border-bottom: 1px solid #ccc;
  }
  .multi-header-cont {
    margin-left: 10px;
  }
  .multi-header {
    font-size: 18px;
    margin-bottom: 5px;
  }
  .multi-panel-cont {
    padding-left: 44px;
    margin-top: 20px;
  }
}
.fwc-info-modal-slide {
  .epm-icon-help {
    color: var(--color-primary);
  }
}
