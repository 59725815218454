@import "../../../src/fwc-assets/styles/components/fwc-select";

.fwc-select-list label {
  padding-bottom: 0;
  display: block;
  margin-bottom: var(--fwcu);
  font-size: 14px;
}
.fwc-select-list select {
  font-size: 14px;
}
