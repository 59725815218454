.er-motif-accordion {
  // -------------------------------------------
  //	Motif Accordions
  // -------------------------------------------

  // ----
  // - Primary Accordion Styles
  // - Features a blue chevron on the left & a border top on every
  // - heading.
  //
  // ----
  .ant-theme-primary {
    .ant-collapse-heading {
      align-items: center;
      font-weight: 300;
      border-top: thin solid map-get($ant-motif-grays, 300);
      border-right: none;
      border-bottom: none;
      border-left: none;
      color: $gray;

      .collapsible-heading-text {
        font-weight: 300;
        font-size: 1.375rem;
      }

      .ant-collapse-button {
        padding: spacer(8) spacer(4);
        text-align: left;

        .accordion-button-icon {
          font-family: $motif-font-family;
          color: color("primary");
          font-size: calculateRem(20px);
          margin-right: spacer(9);

          &:before {
            content: $motif-angle-down;
            display: block;
            transition: 250ms ease-in-out;
          }
        }
      }
    }

    .ant-collapse-body .body-content {
      padding: spacer(3) spacer(4) spacer(8) spacer(4);
    }

    &.is-expanded .accordion-button-icon::before {
      transform: rotate(180deg);
    }
  }

  // ----
  // - Primary Accordion (continued ...)
  // - This is a solutiont to add the border bottom on the last item.
  // - The last selector, div[data-tcp-wcs-expand-collapse] is being used
  // - exclusively for RCP with a custom component and should be decoupled in the future.
  //
  // ----
  .uxd-collapse-item-cmp,
  .uxd-expand-collapse-wcs,
  div[data-tcp-wcs-expand-collapse] {
    &:last-child {
      .ant-theme-primary .ant-collapse {
        border-bottom: thin solid map-get($ant-motif-grays, 300);
      }
    }
  }

  // ----
  // - Secondary Accordion Styles
  // - Features a smaller, black chevron on the left.
  // - No additional padding is added to the heading, providing less white-space to
  // - the accordion.
  //
  // ----
  .ant-theme-secondary {
    .ant-collapse-heading {
      display: block;
      font-family: $font-family-Montserrat;
      font-size: calculateRem(14px);
      font-weight: 400;
    }

    .ant-collapse-button {
      padding: spacer(3) 0;

      .accordion-button-icon {
        margin-right: spacer(5);
        font-family: $motif-font-family;
        transition: 250ms ease-in-out;
        transform: rotate(-90deg);

        &:before {
          content: $motif-sort-down-filled;
          display: block;
        }
      }
    }

    .ant-collapse-body .body-content {
      padding: spacer(3) spacer(4) spacer(8) spacer(9);
    }

    &:last-child {
      border-bottom: none;
    }

    &.is-expanded {
      .accordion-button-icon {
        transform: rotate(0deg);
      }
    }
  }

  /*-------------------------------------------*\
    Alt Toggle Component
  \*-------------------------------------------*/
  .alt-toggle-cmp {
    border-bottom: 1px solid map-get($ant-motif-grays, 200);
    padding: spacer(6) 0px;

    .alt-toggle {
      overflow: hidden;
    }

    .truncatedHeader {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .alt-toggle-icon {
      position: absolute;
      right: 10px;
      color: color("primary");
      font: normal normal normal 45px "FontAwesome";
    }
  }

  /*-------------------------------------------*\
    Expansion Panel Component
  \*-------------------------------------------*/
  .uxd-expansion-button {
    background: transparent;

    &:not(:focus) {
      border: 2px solid transparent;
      outline: none;
    }
  }

  // **
  // * Expansion Panel
  // *
  // * 1. comment
  // *
  .uxd-expansion-panel {
    display: block;
    overflow: hidden;
    border-top: none;
    border-bottom: none;



    .uxd-expansion-header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      padding-top: calculateRem(30px);
      padding-bottom: calculateRem(30px);
    }

    .uxd-expansion-button {
      height: 100%;
      margin-right: spacer(9);
      color: color("primary");
      border-color: transparent;

      .fa {
        color: inherit;
      }
    }
  }

  // **
  // * Expansion Table
  // * A set of styles mean to wrap the expansion panel component
  // *
  .uxd-expansion-table {
    border-top: 1px solid #cccccc;
    .uxd-expansion-button {
      // width: calculateRem(22px);

      .motif-icon {
        font-size: calculateRem(22px);
      }
    }

    .uxd-expansion-header {
      position: relative;
      padding-left: calculateRem(10px);
      padding-right: calculateRem(10px);

      &::after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 15px 15px 0 15px;
        border-color: $white transparent transparent transparent;
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -50px;
      }
    }

    .uxd-table-row {
      display: flex;
      flex-flow: row wrap;
      flex: 1;
      overflow: hidden;
      justify-content: flex-start;
      align-items: stretch;
      .cell-mobile-label {
        display: none;
      }
      @media screen and (max-width: 700px) {
        flex-direction: column;
        .cell-mobile-label {
          display: block;
          font-weight: bold;
          margin-right: 5px;
        }
        .cell-header {
          margin-top: 8px;
        }
      }

      .uxd-table-cell:nth-child(1),
      .uxd-table-action {
        flex-grow: 0;
      }

      .uxd-table-cell {
        flex: 1 1 auto;
        margin-right: spacer(6);
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;

        &:first-of-type {
          flex-basis: 200px;
        }
      }
      .uxd-table-action {
        display: flex;
        flex-grow: 1;
        justify-content: flex-start;
        margin-left: auto;
      }
    }

    &.zebra-striping {
      .uxd-expansion-panel:nth-child(even) {
        $backgroundColor: map-get($ant-motif-grays, 100);

        .uxd-expansion-header {
          background-color: $backgroundColor;

          &::after {
            border-color: $backgroundColor transparent transparent transparent;
          }
        }
      }
    }
  }

  .motif-angle-down{
    display: inline-block;

    &.open{
      transform: rotate(180deg);
    }
  }
  .cell-header-container {
    padding-left: .625rem;
    padding-right: .625rem;
    padding-bottom: 15px;
  }
  .cell-header-label {
    font-size: 14px;
    font-weight: bold;
  }
  .cell-header {
    flex: 1 1 14.25%;
    .er-motif-button-link {
      border-bottom: none;
    }
    .active-flag {
      color: $color-white;
      background-color: $color-dviz-three;
      padding: 5px 20px;
      width: 110px;
      font-size: 12px;
      font-weight: 700;
      display: flex;
      justify-content: center;
    }
    .future-flag {
      background-color: #ffcf6c;
      padding: 5px 20px;
      width: 110px;
      font-size: 10px;
      font-weight: 700;
      display: flex;
      justify-content: center;
    }
    .terminated-flag {
      color: $color-white;
      background-color: $color-util-one;
      padding: 5px 20px;
      width: 110px;
      font-size: 10px;
      font-weight: 700;
      display: flex;
      justify-content: center;
    }
    }
  .cell-header-toggle {
    flex: 1 1 5%;
  }
  .subset-table {
    color: $color-gray-one;
    background-color: #f7f7f7;
    margin-bottom: 0px;
    .table-row {
      &:first-of-type {
        font-size: 14px;
        padding: 15px;
        border-bottom: 1px solid $color-gray-four;
      }
      .table-data {
        padding: 20px 15px;
      }
      .table-header {
        padding: 15px;
      }
    }
    .table-data-row:nth-of-type(even) {
      background-color: $color-white;
    }
  }
  .epm-icon-angle-down {
    color: $color-primary;
  }
  .epm-icon-angle-up {
    color: $color-primary;
  }
}
