@use "sass:math";
//spacial units
$sp1: 1rem;
$sp2: $sp1*2;
$sp4: $sp1*4;
$sp05: math.div($sp1, 2);
$sp025: math.div($sp1, 4);
$sp0125: math.div($sp1, 8);

//hard units for flex padding bug
$sph1: 25px;
$sph2: $sph1*2;
$sph05: math.div($sph1, 2);
$sph025: math.div($sph1, 4);
$sph0125: math.div($sph1, 8);

$header-offset: 125px;
$header-offset-mobile: 136px;
$er-border: 1px solid rgb(100, 98, 98);
//fix this
$billing-border: $er-border;
$super-light-gray: #f8f8f8;
$base-color: #FFF;
$black: #000;
$negative-background: #FFF7F7;

$primary-color-lighter-blue: #4da1d4;
$primary-color-light-blue: #0079C2;
$primary-color-blue: #00609A;
$primary-color-dark-blue: #002E58;
$primary-text-color: #FFF;
$primary-text-color-inverse: #333;

$neutral-dark-gray: #333;
$gray: #666666;
$gray-2: #A3A3A3;
$medium-gray: #DCDCDC;
$light-gray: #EEEEEE;
$white: #FFFFFF;

$accent-color-salmon: #FE5E3C;
$accent-color-peach:#faad96;
$accent-color-peach-dark:#e19e8a;
$accent-color-red: #E82D05;
$accent-color-teal: #06A59D;
$accent-color-blue: #69B3E7;
$accent-color-light-blue: #E9F4FB;
$accent-color-green: #00CC33;
$accent-color-light-green: #F6FFE5;
$accent-color-magenta: #cc0033;
$accent-color-light-magenta: #FFF0FA;

$breakpoint-sm: 801px !default;
$breakpoint-md: 1024px !default;
$breakpoint-xs: 480px !default;
$breakpoint-lg: 1200px !default;

$btn-min-width: 198px;


/** Todo - Normalize the below styles from public with the above from secure (below provided by DeutschLA) **/



/* Font settings and sizes Anthem.com. */
$base-font: 'Open Sans', "open-sans", sans-serif;
$font-weight-light: 300;
$font-weight-semi: 600;
$font-weight-bold: 700;
$font-size-base: 18px;
$font-size-h1: 48px;
$font-size-h2: 40px;
$font-size-h3: 36px;
$font-size-h4: 32px;
$font-size-h5: 26px;
$font-size-h6: 16px;
$font-size-small: 20px;

$font-size-mobile: 16px;
$font-size-header: 28px;

//== Units
//
//## Units sizes Anthem.com.
$padding: 60px;
$max-width: 1090px;
$nav-header: 81px;

// Deutsch vars
//== Colors
//
//## Brand colors for use across Anthem.com.

$color-xlight-gray: #f5f5f5;
$color-dark-gray: #2d2d2d;
$color-teal: #41c0b8;
$color-purple: #683c7a;
$color-magenta: #a21851;
$color-yellow: #ffcc33;
$color-error: #cc0033;
$color-dark-dolphin: #00847a;
$color-yes: #099b92;
$color-no: #e5270a;
$color-alert: #ffedf9;

// hover colors change state button on public
// scss used in .btn-info:hover scss
$medium-aqua-blue: #31b0d5;
$darker-aqua-blue: #269abc;

