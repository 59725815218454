.fwc-card-generic {
  border: solid 1px rgba(var(--color-rgb-black), 0.1);
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}
.fwc-card-generic.hover:hover {
  box-shadow: 0 0 20px 0 rgba(215, 222, 227, 0.39), 0 26px 90px 0 rgba(51, 60, 70, 0.3);
}
.fwc-card-generic.flat {
  box-shadow: none;
}

.fwc-card-generic.fwc-card-icon-reverse {
  position: relative;
  padding: calc(11.25 * var(--fwcu)) calc(6.25 * var(--fwcu)) calc(6.25 * var(--fwcu)) calc(6.25 * var(--fwcu));
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.fwc-card-icon-reverse .icon-green,
.fwc-card-icon-reverse .icon-gray {
  display: inline-flex;
  position: absolute;
  top: 0px;
  left: calc(6.25 * var(--fwcu));
  padding: calc(2.25 * var(--fwcu));
  color: var(--color-white);
  font-size: calc(4 * var(--fwcu));
}
.fwc-card-icon-reverse .icon-green {
  background: var(--color-util-two);
}
.fwc-card-icon-reverse .icon-gray {
  background: var(--color-gray-four);
}
.fwc-card-icon-reverse .fwc-card-icon-reverse-header {
  margin-bottom: calc(3 * var(--fwcu));
}
.fwc-card-icon-reverse a {
  margin-top: calc(3.75 * var(--fwcu));
}
.fwc-card-media-image {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  height: calc(11.75 * var(--fwcu));
  border-radius: var(--border-radius) var(--border-radius) 0 0;
}
.fwc-card-media-wrapper {
  padding: calc(2.5 * var(--fwcu));
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}
.fwc-card-media-body {
  margin-bottom: calc(2 * var(--fwcu));
  flex: 1 0 auto;
  color: var(--color-gray-one);
}
.fwc-card-media-title {
  margin: calc(2 * var(--fwcu)) 0;
  color: var(--color-gray-one);
}
.fwc-card-media-link {
  margin-bottom: calc(1.25 * var(--fwcu));
}
.fwc-card-media-link a {
  display: inline-flex;
}
.fwc-card-media-link svg {
  min-width: calc(2.25 * var(--fwcu));
  height: calc(2.25 * var(--fwcu));
  margin-right: var(--fwcu);
  fill: var(--color-gray-three);
  flex-basis: calc(2.25 * var(--fwcu));
}
