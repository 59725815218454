@use "sass:math";
@mixin erFlexCol($percent, $padding) {
    flex-grow:1;
    flex-shrink:1;
    flex-basis:calc(#{$percent} - #{$padding});
}

@mixin erFlexRow($columns, $padding) {
    $colWidth: math.div(100, $columns) + "%";
    .er-table-header, .er-table-row {
        flex-wrap: wrap;
        .er-fi {
            flex:1 0 100%;
            margin-bottom:$sp05;
            &:last-child { margin-bottom:0 }
        }
        @media screen and (min-width: $breakpoint-sm) {
            flex-wrap: nowrap;
            .er-fi {
                margin-bottom:0;
                @include erFlexCol($colWidth, $padding);
                &.f-col { flex:1 0 100%; }
                &.h-col { @include erFlexCol(50%, $sp1) }
            }
        }
    }
}

.er-expand-table, .er-table {


    border:$billing-border;
    border-top:none;
    margin-bottom:$sp2;

    .er-m { display:block; }
    .er-m-f { display:flex;}
    .er-m-i { display: inline; }
    .er-d, .er-d-f, .er-d-i { display:none; }
    .past-due-color { color: $accent-color-red; }
    .min-spin-h{ 
        min-height:$sph2*4;
     }


    // possibly refactor for this table 
    .billing-filter {
        justify-content: center;
    }

    .er-exp-info-head, .er-table-header, .er-table-row {
        padding:$sp1 0;
        flex-grow: 4;
        flex-shrink: 0;
        flex-basis: calc(100% - #{$sph2});
        align-items: flex-start;
        .er-fi { 
            padding:0 $sph05; 
            &:last-child { 
                text-align: right; 
                &.left {
                    text-align: left;
                }
            }
            &.right {
                text-align: right;
            }
            &.m-left {
                text-align:left;
                @media screen and (min-width: $breakpoint-sm) {
                    text-align: right;
                }
            }
        }
    }
    &.bottom-header{
        padding-top:5rem;
        position:relative;
        .er-table-header{
            position:absolute;
            bottom:0;
            background-color: white;
            z-index:999;
        }
    }
    &.fixed-header{
        padding-top:5rem;
        .er-table-header{
            position:fixed;
            top:$header-offset;
            background-color: white;
            z-index:999;
        }
    }
    .er-flow {
        flex-wrap:wrap;
        p { margin-bottom:0; }
    }

    .er-fi-expander, .er-fi-sgif, .er-fi-dtoggle {
        justify-content: center;
        align-self: stretch;
        flex:0 0 50px;
        -ms-flex:0 0 auto;
    }

    .er-exp-info {
        background:$light-gray;
        align-items: flex-start;
        .er-fi-expander {
            background: $medium-gray;
        }
    }

    .er-exp-info-head {
        @extend .body-sm;
        flex-direction: column;
        align-items: flex-start;
        .er-fi {
            padding-bottom:$sp05;
            &:last-child { padding-bottom:0; }
            .exp-sub-title { font-weight: 800; }
        }
        &.exp-two-col {
            .er-fi:last-child {
                text-align:left;
            }
        }
    }
    .er-table-row-cont.hover:hover {
        background:$light-gray;
    }

    .er-exp-footer, .er-table-row-cont {
        border-top:$billing-border;
    }

    .er-exp-footer, .er-tbl-footer {
        padding:$sp1;
    }

    .er-table-drawer {
        .exp-drawer-cont {
            align-items: stretch;
            flex-direction: column;
            padding:$sp1;
            .er-flex dt { padding-right:$sp05;}
        }
        .d-grp {
            margin-bottom:$sp025;
            dt { padding-right: $sp05;}
            dt, dd { display: inline-block; }
        }
    }

    .er-exp-no-data {
        padding:$sp1;
        text-align: center;
    }

    .er-exp-loader {
        position: absolute;
        z-index:1;
        top:0;
        bottom:0;
        left:0;
        right:0;

        & > div { 
            display: flex;
            align-items: center;
            justify-content: center;
            background:$base-color;
            height: 100%;
        }
    }

    .er-table-row {
        &.moe-row {
            display: flex;
        }
    } 

    .er-table-section {
        justify-content: center;
        padding:$sp1;
        border-top: $billing-border;
        border-bottom: $billing-border;

        &.no-bb { border-bottom:none; }
        &.no-tb { border-top:none; }
    }

    @media screen and (min-width: $breakpoint-sm) {
        .er-m, .er-m-f, .er-m-i { display:none; }
        .er-d { display:block; }
        .er-d-f { display:flex; }
        .er-d-i { display:inline; }

        .er-table-header, .er-table-row {
            .er-fi { flex-basis: 0; }
        }

        .er-exp-info-head {
            flex-direction: row;
            flex-wrap: nowrap;
            .er-fi {
                padding-bottom:0;
                &:last-child {
                    flex-grow:2;
                }
                &:nth-child(2) { flex-basis:33%; }
                .exp-sub-val { font-weight: 400; display:block; }
                .exp-sub-title-colon {
                    display: none;
                }
            }
        }
        .er-flow { flex-wrap:nowrap; }

        .er-table-drawer {
            .exp-drawer-cont {
                align-items: stretch;
                flex-direction: row;
                & > .er-fi {
                    &:nth-child(2) { 
                        border-left:$billing-border;
                        padding:0 $sph05; 
                    }
                    &:nth-child(3) {
                        border-left:$billing-border;
                    }
                }
            }
        }
    }

    &.mobile-only-expand {
        .er-expand-toggle {
            padding: 0 1rem;
        }        
        .er-table-row {
            &.moe-row {
                display: flex;
                .er-fi {
                    &:last-child {
                        text-align: center;
                    }  
                }
                .er-fi-dtoggle {
                    display: flex;
                }
            }
            &.no-expand {
                display: flex;
            }
        }
        .total-payment {
            flex: auto;
        }        
        .er-fi {
            padding: 0;
            &.moe-amount {
                text-align: right;
            }
        }
        @media screen and (min-width: $breakpoint-sm) {
            .er-table-row {
                &.moe-row {
                    .er-fi-dtoggle {
                        display: none;
                    }
                }
            }
        }
    }
}


.er-table {
    border:none;
    .er-table-header {
        border-bottom:$billing-border;
    }

    .er-table-row {
        border-bottom: $billing-border;
        &:last-child:not(.bottom-b) { 
            border-bottom:none;
        }
        &.no-border { border:none; }
    }

    .er-tbl-footer {
        border-bottom: $billing-border;

    }
    &.enclosed { border: $billing-border }
}


.static-table {
    &.er-expand-table {

        border:none;
        .er-flex.er-table-row {
            display:block;
        }
        .er-table-row-cont:hover {
            background:none;
        }
        .er-table-row {
            .er-fi {
                margin-bottom:$sph025;
                &.payment-amount {
                    text-align: center;
                    padding-top: 1rem;
                    .payment-amount-number {
                        display: block;
                        font-size: 1.5rem;
                        font-weight: 600;
                    }
                }
            }
        }
        .er-exp-footer { text-align: center; }
        .total-payment-amount {
            font-size: 2rem;
            font-weight: 600;
            display: block;
        }  


        @media screen and (min-width: $breakpoint-sm) {
            border-bottom:1px solid $medium-gray;
            .er-flex.er-table-row {
                display:flex;
            }
            .er-table-row {
                .er-fi {
                    margin-bottom:0;
                    &.payment-amount {
                        text-align: right;
                        padding-top: 0;
                        .payment-amount-number {
                            display: inline;
                            font-size: inherit;
                            font-weight: inherit;
                        }
                    }
                }
            }
            .er-exp-footer { text-align: right; }
            .total-payment-amount {
                font-size: 1rem; 
                display: inline;  
            }
            
        }
    }
}
