@import "../../../src/fwc-assets/styles/components/fwc-table";

.fwc-table th,
.fwc-table td {
  font-family: var(--font-sans-serif);
  a {
    font-family: var(--font-sans-serif) !important;
  }
  div {
    font-family: var(--font-sans-serif) !important;
  }
}
