


// - Timeline
// ----------------------------------------- *
.uxd-timeline-chart {
  height: 245px;
  padding: 1rem .25rem 0 .25rem;
  margin-bottom: 2rem;
}
.ant-timeline {
  display: block;
  position: relative;
  margin: 200px 0;

  .bg-rect {
    fill: #eeeeee;
  }

  .percent-track {
    fill: $color-dviz-one;
  }

  .dot {
    stroke: $color-gray-four;
  }

  .ant-timeline-svg {
    display: block;
    width: 100%;

    .first-timestamp {
      visibility: hidden;
    }
  }

  .ant-timestamp {
    display: block;
    position: absolute;
    white-space: nowrap;
    text-align: center;
    font-size: 14px !important;
    font-weight: bold;

    &.first-label {
      text-align: left;

      .ant-timestamp__line {
        width: 0;
        top: 10px;
      }
    }
    p {
      font-size: 14px !important;
      font-weight: normal !important;
    }

    &__line {
      border-right: 2px solid $color-primary;
      height: 30px;
      width: 50%;
    }

    &-below {
      bottom: -72px;

      .ant-timestamp__line {
        left: -2px;
      }
    }

    &-above {
      top: -80px;

      .ant-timestamp__line {
        position: absolute;
        top: 100%;
      }
    }

    &-right {
      min-width: 120px;
      left: 50px;
      text-align: left;

      .ant-timestamp__line {
        position: relative;
        width: 24px !important;
        left: -31px;
        top: 5px;
      }
    }

    &-left {
      min-width: 120px;
      text-align: right;

      .ant-timestamp__line {
        position: relative;
        left: 100%;
        top: 10px;
        margin-left: 10px;
        &:last-child {
          border-top: 2px solid $color-gray-four !important;
        }
      }
    }
  }

  &.vertical {
    margin: 0 0 0 85px;
    height: 100%;

    .ant-timeline-svg {
      height: 100%;
    }

    .ant-timestamp {
      &__line {
        border-top: 2px solid $color-dviz-one !important;
        height: 1px;
        width: 30px;
        z-index: 0;
      }
    }
  }
  .timestamp-portal {
    &:last-of-type {
      .ant-timestamp__line {
        border-top: 2px solid $color-gray-four !important;
      }
    }
  }
}

.ant-line-chart-container {
  .domain {
    stroke: #cccccc !important;
  }
  .line {
    fill: none;
    stroke-width: 3px;
  }
  .line-0 {
    stroke: $color-primary;
  }
  .line-1 {
    stroke: $color-dviz-eight;
  }
  .line-2 {
    stroke: $color-dviz-eight;
  }
  .purpleFill {
    fill: rgb(81,26,132) !important;
    fill-opacity: .15;
  }
  .yellowFill {
    fill: rgb(242,188,53) !important;
    fill-opacity: .15;
  }
  .greenFill {
    fill: rgb(16,136,8) !important;
    fill-opacity: .15;
  }
  .horizontal-label,
  .vertical-label,
  .horizontal-axis,
  .vertical-axis {
    font-size: 1rem;
    font-family: 'Lato';
  }
  .horizontal-label,
  .vertical-label {
    fill: $color-black;
    text-anchor: middle;
    font-weight: 600;
  }
  .axis-line {
    stroke-opacity: .3;
    stroke: $color-gray-one;
    stroke-width: 2px;
  }
  #y-axis {
    .tick {
      font-size: 12px;
      font-weight: bold;
      color: $color-gray-one;
      font-family: $font-sans-serif;
      line {
        visibility:hidden;
      }
    }
  }
  #x-axis {
    .tick {
      font-size: 12px;
      font-weight: bold;
      color: $color-gray-one;
      font-family: $font-sans-serif;
      //line {
      //  visibility:hidden;
      //}
    }
  }
}
