/** Anthem Base Sass Variables and Mixins **/
/** Todo - Normalize the below styles from public with the above from secure (below provided by DeutschLA) **/
/* Font settings and sizes Anthem.com. */
.sixteen-nine {
  position: relative; }
  .sixteen-nine:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 56.25%; }
  .sixteen-nine > .ratio-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }

.tab-example {
  width: 75%;
  float: left; }

.tab-json {
  width: 25%;
  float: left;
  padding-left: 1rem; }

.codeNotes {
  clear: both; }

.mockPreTagStyles {
  background: #eee;
  border: 1px solid #ccc;
  border-radius: 0.3em; }

.clearfix:after, .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-collapse-heading:after,
div[data-uxd-expand-collapse-wcs] .ant-collapse-heading:after,
div[data-tcp-wcs-expand-collapse] .ant-collapse-heading:after, .wcs-ec-body-content:after, .clearfix:before, .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-collapse-heading:before,
div[data-uxd-expand-collapse-wcs] .ant-collapse-heading:before,
div[data-tcp-wcs-expand-collapse] .ant-collapse-heading:before, .wcs-ec-body-content:before {
  content: " ";
  display: table; }

.clearfix:after, .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-collapse-heading:after,
div[data-uxd-expand-collapse-wcs] .ant-collapse-heading:after,
div[data-tcp-wcs-expand-collapse] .ant-collapse-heading:after, .wcs-ec-body-content:after {
  clear: both; }

/** Angular Components Styles **/
/*** Start accordion.scss ***/
/*bootstrap accordion start*/
.ant-accordion .panel,
.ant-accordion .panel + .panel {
  margin-top: 1rem;
  border: 0px solid #0079C2;
  border-radius: 0px; }

.ant-accordion .panel-heading a {
  display: table-row;
  width: 100%;
  border: 0px; }
  .ant-accordion .panel-heading a span.fa {
    color: #FFF;
    display: table-cell;
    vertical-align: middle;
    font-size: 1.857rem; }
  .ant-accordion .panel-heading a span.heading-text {
    display: table-cell;
    vertical-align: middle;
    padding-left: .6rem;
    color: #FFFFFF; }

.ant-accordion .panel-heading {
  padding: 0px;
  background: transparent;
  border: none; }

.ant-accordion .panel-heading a.collapsed {
  background-color: #0079C2; }

.ant-accordion .panel-heading a {
  font-size: 1.444rem;
  padding: .6rem;
  display: block;
  background-color: #0079C2;
  background-image: none;
  filter: none; }

.ant-accordion .panel-heading a, .ant-accordion .panel-heading a:hover, .ant-accordion .panel-heading a:focus {
  text-decoration: none; }

.ant-accordion .panel-body {
  padding: 1.714rem 1.111rem;
  border: 1px solid transparent;
  box-shadow: 0px 0px; }

.ant-accordion-label {
  font-size: 1rem;
  color: #000;
  text-decoration: none; }

a.ant-accordion-toggle:focus,
a.ant-accordion-toggle:hover {
  text-decoration: none; }

a.ant-accordion-toggle:focus span.ant-accordion-label,
span.ant-accordion-label:hover {
  text-decoration: underline; }

.ant-accordion .collapse {
  -webkit-transition: max-height 500ms ease-out, visibility 500ms ease;
  -o-transition: max-height 500ms ease-out, visibility 500ms ease;
  transition: max-height 500ms ease-out, visibility 500ms ease;
  max-height: 0;
  overflow: hidden;
  display: inherit;
  visibility: hidden; }

.ant-accordion .collapse.in .panel-body {
  max-height: 3000px;
  visibility: visible;
  -webkit-transition: max-height 500ms ease-out, visibility 500ms ease, border 500ms ease;
  -o-transition: max-height 500ms ease-out, visibility 500ms ease, border 500ms ease;
  transition: max-height 500ms ease-out, visibility 500ms ease, border 500ms ease;
  border: 1px solid #DCDCDC;
  border-top: 0px; }

.ant-accordion .collapse .panel-body {
  max-height: 0px;
  visibility: hidden;
  -webkit-transition: max-height 500ms ease-out, visibility 500ms ease, border 500ms ease;
  -o-transition: max-height 500ms ease-out, visibility 500ms ease, border 500ms ease;
  transition: max-height 500ms ease-out, visibility 500ms ease, border 750ms ease;
  border: 1px solid transparent;
  border-top: 0px; }

.ant-accordion .collapse.in {
  -webkit-transition: max-height 500ms ease-out, visibility 500ms ease;
  -o-transition: max-height 500ms ease-out, visibility 500ms ease;
  transition: max-height 500ms ease-out, visibility 500ms ease;
  max-height: 3000px;
  display: inherit;
  visibility: visible;
  overflow: visible; }

/*bootstrap accordion end*/
/** Start alphabet-tabs.scss **/
.ant-alphabet-tabs-container {
  position: relative;
  margin-bottom: 1rem; }
  .ant-alphabet-tabs-container .ant-alphabet-tabs {
    overflow-x: auto; }
    .ant-alphabet-tabs-container .ant-alphabet-tabs::-webkit-scrollbar {
      display: none; }
    .ant-alphabet-tabs-container .ant-alphabet-tabs ul {
      white-space: nowrap;
      margin-bottom: 0;
      padding-left: 0;
      list-style: none;
      display: flex;
      justify-content: flex-start; }
      .ant-alphabet-tabs-container .ant-alphabet-tabs ul li {
        font-size: 1.111rem;
        font-weight: 600;
        text-align: center;
        background-color: #0079C2;
        min-width: 100px;
        height: 50px;
        border-right: 1px solid #FFFFFF;
        display: block;
        cursor: pointer; }
        .ant-alphabet-tabs-container .ant-alphabet-tabs ul li:hover {
          background-color: #00609A; }
        .ant-alphabet-tabs-container .ant-alphabet-tabs ul li.active {
          background-color: #00609A; }
        .ant-alphabet-tabs-container .ant-alphabet-tabs ul li a {
          border-bottom: 0;
          color: #FFFFFF;
          line-height: 50px; }

.ant-alphabet-tabs-body ul {
  padding: 0;
  padding-left: .5rem;
  margin: 0;
  list-style: none; }
  .ant-alphabet-tabs-body ul.active {
    display: block; }
  .ant-alphabet-tabs-body ul li {
    margin-bottom: .5rem; }

/** Start alt-toggle.scss **/
.alt-toggle-cmp {
  position: relative;
  border-top: 2px solid #EEEEEE;
  border-bottom: 2px solid #EEEEEE;
  margin-bottom: -2px; }
  .alt-toggle-cmp .alt-toggle-header {
    width: calc( 100% - 35px);
    float: left;
    margin: .75rem 0; }
    .alt-toggle-cmp .alt-toggle-header .alt-toggle-body {
      width: calc( 100% + 35px);
      margin-top: 1rem;
      max-height: 2500px;
      visibility: visible;
      transition: max-height .25s ease, visibility .25s ease .25s; }
      .alt-toggle-cmp .alt-toggle-header .alt-toggle-body.closed {
        max-height: 0px;
        padding: 0px;
        margin: 0px;
        overflow: hidden;
        visibility: hidden;
        transition: max-height .25s ease, visibility .25s ease, padding .35s ease .25s; }
  .alt-toggle-cmp .alt-toggle-icon {
    float: right;
    cursor: pointer; }
    .alt-toggle-cmp .alt-toggle-icon .alt-toggle-plus:before {
      content: "\002b"; }
    .alt-toggle-cmp .alt-toggle-icon .alt-toggle-minus:before {
      content: "\2212"; }
  .alt-toggle-cmp .truncatedHeader {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-bottom: .25rem; }
  .alt-toggle-cmp .bodyOpened .truncatedHeader {
    overflow: visible;
    white-space: normal;
    padding-bottom: 0rem; }

/** Start back-to-top.scss **/
.back-to-top-dir.ng-hide {
  opacity: 0; }

.back-to-top-dir {
  position: fixed;
  display: block;
  bottom: -1px;
  right: -1px;
  line-height: 1.2em;
  text-align: center;
  color: #fff;
  font-weight: 600;
  background-color: #00609A;
  font-size: 0px;
  transition: all 0.2s ease-in-out 0s;
  border: 1px solid #00609A;
  padding: 10px 15px;
  float: right;
  max-width: 250px;
  z-index: 5; }
  @media screen and (min-width: 480px) {
    .back-to-top-dir {
      font-size: 14px; } }
  .back-to-top-dir .fa.black {
    font-size: 14px; }

@media screen and (max-width: 799px) {
  .back-to-top-mobile .round-button:hover, .back-to-top-mobile .round-button:focus {
    background-color: #fff;
    border: 1px solid #fff;
    color: #00609A; } }

.back-to-top-dir:hover, .back-to-top-dir:focus {
  background: #fff;
  color: #00609A; }

.fa.black {
  color: #fff;
  transition: all 0.2s ease-in-out 0s; }

.back-to-top-dir:hover > .fa.black, .back-to-top-dir:focus > .fa.black {
  color: #00609A; }

/** Start carousels.scss **/
/** Styles for parent template carousel */
.carousel-selector-cmp {
  position: relative; }
  .carousel-selector-cmp .carousel-content-container {
    padding: 0 2rem; }
    .carousel-selector-cmp .carousel-content-container ul {
      overflow: hidden;
      white-space: nowrap;
      margin: 0;
      padding: 0;
      text-align: center; }
      .carousel-selector-cmp .carousel-content-container ul li {
        list-style: none;
        display: inline-block;
        position: relative; }
      .carousel-selector-cmp .carousel-content-container ul > li {
        width: 100%;
        transition: left .5s; }
      @media screen and (min-width: 480px) {
        .carousel-selector-cmp .carousel-content-container ul > li {
          width: 50%; }
        .carousel-selector-cmp .carousel-content-container ul > li:first-child:nth-last-child(1) {
          max-width: 100%; } }
      @media screen and (min-width: 1024px) {
        .carousel-selector-cmp .carousel-content-container ul > li {
          width: 33.3333%; }
        .carousel-selector-cmp .carousel-content-container ul > li:first-child:nth-last-child(1) {
          max-width: 100%; }
        .carousel-selector-cmp .carousel-content-container ul > li:first-child:nth-last-child(2),
        .carousel-selector-cmp .carousel-content-container ul > li:first-child:nth-last-child(2) ~ li {
          width: 50%; } }
  .carousel-selector-cmp .carousel-left-control, .carousel-selector-cmp .carousel-right-control {
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
    .carousel-selector-cmp .carousel-left-control a, .carousel-selector-cmp .carousel-right-control a {
      border: none; }
    .carousel-selector-cmp .carousel-left-control .fa.fa-chevron-left, .carousel-selector-cmp .carousel-left-control .fa.fa-chevron-right, .carousel-selector-cmp .carousel-right-control .fa.fa-chevron-left, .carousel-selector-cmp .carousel-right-control .fa.fa-chevron-right {
      font-size: 1.75rem; }
      .carousel-selector-cmp .carousel-left-control .fa.fa-chevron-left:hover, .carousel-selector-cmp .carousel-left-control .fa.fa-chevron-right:hover, .carousel-selector-cmp .carousel-right-control .fa.fa-chevron-left:hover, .carousel-selector-cmp .carousel-right-control .fa.fa-chevron-right:hover {
        cursor: pointer; }
  .carousel-selector-cmp .carousel-right-control {
    right: 0; }

/** Styles for button selector carousel */
.carousel-content-container ul > .button-select-item {
  width: 100%;
  transition: left .5s; }
  .carousel-content-container ul > .button-select-item li {
    margin: 0.5rem; }

@media screen and (min-width: 480px) {
  .carousel-content-container ul > .button-select-item {
    width: 50%; }
  .carousel-content-container ul > .button-select-item:first-child:nth-last-child(1) {
    width: 100%; } }

@media screen and (min-width: 1024px) {
  .carousel-content-container ul > .button-select-item {
    width: 33.3333%; }
  .carousel-content-container ul > .button-select-item:first-child:nth-last-child(1) {
    width: 100%; }
  .carousel-content-container ul > .button-select-item:first-child:nth-last-child(2),
  .carousel-content-container ul > .button-select-item:first-child:nth-last-child(2) ~ .button-select-item {
    width: 50%; } }

.carousel-content-container ul .button-select-item {
  display: inline-block;
  white-space: normal;
  padding-bottom: 1.75rem;
  position: relative; }
  .carousel-content-container ul .button-select-item .button-select-content-container {
    position: relative;
    padding: 1.25rem;
    text-align: center;
    background: #FFFFFF; }
  .carousel-content-container ul .button-select-item .button-select-carousel-button {
    margin-top: 1rem; }
  .carousel-content-container ul .button-select-item.active .button-select-content-container {
    border: 1px solid #A3A3A3;
    box-shadow: 0px 2px 10px -1px #A3A3A3;
    transition: border .5s, box-shadow .5s;
    z-index: 1; }
    .carousel-content-container ul .button-select-item.active .button-select-content-container:before {
      content: ' ';
      position: absolute;
      left: 50%;
      height: 2.5rem;
      width: 2.5rem;
      bottom: -1.3rem;
      background: #FFFFFF;
      box-shadow: 4px 4px 10px -2px #A3A3A3;
      transform: translateX(-50%) rotate(45deg);
      border-right: 1px solid #A3A3A3;
      border-bottom: 1px solid #A3A3A3;
      z-index: -1; }
  .carousel-content-container ul .button-select-item.active .button-select-carousel-button {
    height: 0;
    transition: height 1s; }
    .carousel-content-container ul .button-select-item.active .button-select-carousel-button button {
      border: 0;
      padding: 0;
      font-size: 0;
      height: 0;
      transition: border .25s, padding .25s, font-size .25s, height .25s; }

/** Styles for blue & gray child carousel */
.ant-progress-bar-cmp .carousel-left-control {
  top: 35%; }

.ant-progress-bar-cmp .carousel-right-control {
  top: 35%;
  right: 10px; }

.ant-progress-bar-cmp .carousel-content-container ul > .default-select-item-container {
  width: 50%;
  transition: left .5s; }

@media screen and (min-width: 480px) {
  .ant-progress-bar-cmp .carousel-content-container ul > .default-select-item-container {
    width: 25%; } }

@media screen and (min-width: 1024px) {
  .ant-progress-bar-cmp .carousel-content-container ul > .default-select-item-container {
    width: calc(100% / 6); }
  .ant-progress-bar-cmp .carousel-content-container ul > .default-select-item-container:first-child:nth-last-child(1) {
    width: calc(100% / 3); }
  .ant-progress-bar-cmp .carousel-content-container ul > .default-select-item-container:first-child:nth-last-child(2),
  .ant-progress-bar-cmp .carousel-content-container ul > .default-select-item-container:first-child:nth-last-child(2) ~ .default-select-item-container {
    width: 25%; } }

.carousel-content-container ul > .default-select-item-container, .carousel-content-container ul > .color-select-item-container {
  width: calc(100% - .5rem);
  transition: left .5s; }

@media screen and (min-width: 480px) {
  .carousel-content-container ul > .default-select-item-container, .carousel-content-container ul > .color-select-item-container {
    width: calc(100% / 3 - .5rem); }
  .carousel-content-container ul > .default-select-item-container:first-child:nth-last-child(1), .carousel-content-container ul > .color-select-item-container:first-child:nth-last-child(1) {
    width: calc(100% / 3 - .5rem); } }

@media screen and (min-width: 1024px) {
  .carousel-content-container ul > .default-select-item-container, .carousel-content-container ul > .color-select-item-container {
    width: calc(100% / 4 - .5rem); }
  .carousel-content-container ul > .default-select-item-container:first-child:nth-last-child(1), .carousel-content-container ul > .color-select-item-container:first-child:nth-last-child(1) {
    width: calc(100% / 3 - .5rem); }
  .carousel-content-container ul > .default-select-item-container:first-child:nth-last-child(2), .carousel-content-container ul > .color-select-item-container:first-child:nth-last-child(2),
  .carousel-content-container ul > .default-select-item-container:first-child:nth-last-child(2) ~ .default-select-item-container, .carousel-content-container ul > .color-select-item-container:first-child:nth-last-child(2) ~ .color-select-item-container {
    width: calc(100% / 2 - .5rem); } }

.carousel-content-container ul .default-select-item-container {
  display: inline-block;
  position: relative;
  vertical-align: top;
  white-space: normal;
  background-color: #DCDCDC;
  cursor: pointer;
  min-height: 100px;
  margin: .25rem; }
  @media screen and (min-width: 1024px) {
    .carousel-content-container ul .default-select-item-container {
      min-height: 125px; } }
  .carousel-content-container ul .default-select-item-container > .select-item {
    padding: 1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 100%; }
  .carousel-content-container ul .default-select-item-container:hover {
    background-color: #EEEEEE; }
  .carousel-content-container ul .default-select-item-container.active {
    color: #FFFFFF;
    background-color: #0079C2;
    cursor: initial; }

.carousel-content-container ul .color-select-item-container {
  display: inline-block;
  position: relative;
  vertical-align: top;
  white-space: normal;
  cursor: pointer;
  margin: .25rem;
  padding-bottom: 1.75rem; }
  .carousel-content-container ul .color-select-item-container > .select-item {
    padding: 1rem 1rem 0;
    width: 100%;
    min-height: 8rem;
    z-index: 0;
    background-color: #EEEEEE; }
    .carousel-content-container ul .color-select-item-container > .select-item:hover {
      background-color: #DCDCDC; }
    .carousel-content-container ul .color-select-item-container > .select-item:before {
      display: block;
      content: " ";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 100%;
      transition: top 250ms ease;
      z-index: -1;
      border-bottom: 1.5rem solid;
      margin-top: -1.75rem; }
  .carousel-content-container ul .color-select-item-container.active {
    cursor: default;
    color: #FFFFFF; }
    .carousel-content-container ul .color-select-item-container.active:after {
      display: block;
      content: " ";
      position: absolute;
      border-style: solid;
      border-width: 25px 25px 0 25px;
      left: 50%;
      transform: translateX(-50%); }
    .carousel-content-container ul .color-select-item-container.active .select-item {
      padding-top: 1.75rem;
      transition: padding-top 230ms ease-in; }
      .carousel-content-container ul .color-select-item-container.active .select-item:before {
        top: 0; }
  .carousel-content-container ul .color-select-item-container:nth-child(5n+1) .select-item:before {
    border-color: #0079C2; }
  .carousel-content-container ul .color-select-item-container:nth-child(5n+1).active:after {
    border-color: #0079C2 transparent transparent transparent; }
  .carousel-content-container ul .color-select-item-container:nth-child(5n+1).active .select-item:before {
    background-color: #0079C2; }
  .carousel-content-container ul .color-select-item-container:nth-child(5n+2) .select-item:before {
    border-color: #00609A; }
  .carousel-content-container ul .color-select-item-container:nth-child(5n+2).active:after {
    border-color: #00609A transparent transparent transparent; }
  .carousel-content-container ul .color-select-item-container:nth-child(5n+2).active .select-item:before {
    background-color: #00609A; }
  .carousel-content-container ul .color-select-item-container:nth-child(5n+3) .select-item:before {
    border-color: #683c7a; }
  .carousel-content-container ul .color-select-item-container:nth-child(5n+3).active:after {
    border-color: #683c7a transparent transparent transparent; }
  .carousel-content-container ul .color-select-item-container:nth-child(5n+3).active .select-item:before {
    background-color: #683c7a; }
  .carousel-content-container ul .color-select-item-container:nth-child(5n+4) .select-item:before {
    border-color: #a21851; }
  .carousel-content-container ul .color-select-item-container:nth-child(5n+4).active:after {
    border-color: #a21851 transparent transparent transparent; }
  .carousel-content-container ul .color-select-item-container:nth-child(5n+4).active .select-item:before {
    background-color: #a21851; }
  .carousel-content-container ul .color-select-item-container:nth-child(5n+5) .select-item:before {
    border-color: #cc0033; }
  .carousel-content-container ul .color-select-item-container:nth-child(5n+5).active:after {
    border-color: #cc0033 transparent transparent transparent; }
  .carousel-content-container ul .color-select-item-container:nth-child(5n+5).active .select-item:before {
    background-color: #cc0033; }

/** Plan selector carousel component styles - to be deprecated **/
[data-uxd-button-selector-carousel-cmp] .tcp-plan-item {
  display: inline-block;
  margin: 0.5rem;
  white-space: normal;
  padding-bottom: 1.75rem;
  position: relative; }
  [data-uxd-button-selector-carousel-cmp] .tcp-plan-item .plan-selector-container {
    position: relative;
    padding: 1.75rem;
    text-align: center;
    background: #FFFFFF; }
  [data-uxd-button-selector-carousel-cmp] .tcp-plan-item.active .plan-selector-container {
    border: 1px solid #A3A3A3;
    box-shadow: 0px 2px 10px -1px #A3A3A3;
    transition: border .5s, box-shadow .5s;
    z-index: 1; }
    [data-uxd-button-selector-carousel-cmp] .tcp-plan-item.active .plan-selector-container .plan-selector-button-container {
      height: 0;
      transition: height 1s; }
      [data-uxd-button-selector-carousel-cmp] .tcp-plan-item.active .plan-selector-container .plan-selector-button-container button {
        border: 0;
        padding: 0;
        font-size: 0;
        height: 0;
        transition: border .25s, padding .25s, font-size .25s, height .25s; }
    [data-uxd-button-selector-carousel-cmp] .tcp-plan-item.active .plan-selector-container:before {
      content: ' ';
      position: absolute;
      left: 50%;
      height: 2.5rem;
      width: 2.5rem;
      bottom: -1.3rem;
      background: #FFFFFF;
      box-shadow: 4px 4px 10px -2px #A3A3A3;
      transform: translateX(-50%) rotate(45deg);
      border-right: 1px solid #A3A3A3;
      border-bottom: 1px solid #A3A3A3;
      z-index: -1; }
  [data-uxd-button-selector-carousel-cmp] .tcp-plan-item .brand-img-wrapper {
    margin-bottom: .5rem; }
    [data-uxd-button-selector-carousel-cmp] .tcp-plan-item .brand-img-wrapper img {
      width: 100%;
      max-width: 250px;
      padding: .5rem; }

/* SCSS for the data display carousel */
[data-uxd-data-display-carousel-cmp] {
  /*This removes the extra spacing between the items in the carousel */
  /* overriding the default icon scss */ }
  [data-uxd-data-display-carousel-cmp] [data-uxd-data-display-tile-carousel-cmp] {
    border: 1px solid #DCDCDC; }
  [data-uxd-data-display-carousel-cmp] .carousel-content-container ul {
    display: flex; }
    [data-uxd-data-display-carousel-cmp] .carousel-content-container ul > .button-select-item {
      min-width: 100%;
      margin: 0px; }
    @media screen and (min-width: 480px) {
      [data-uxd-data-display-carousel-cmp] .carousel-content-container ul > .button-select-item {
        width: 49%;
        min-width: 49%; }
      [data-uxd-data-display-carousel-cmp] .carousel-content-container ul > .button-select-item:first-child:nth-last-child(1) {
        width: 32.33%;
        margin: 0px;
        min-width: 32.33%; }
      [data-uxd-data-display-carousel-cmp] .carousel-content-container ul .button-select-item {
        margin: 0 0.5%; }
        [data-uxd-data-display-carousel-cmp] .carousel-content-container ul .button-select-item:last-child {
          margin-right: 0px; } }
    @media screen and (min-width: 1024px) {
      [data-uxd-data-display-carousel-cmp] .carousel-content-container ul > .button-select-item {
        width: 33.3333%;
        min-width: 32.333%; }
      [data-uxd-data-display-carousel-cmp] .carousel-content-container ul > .button-select-item:first-child:nth-last-child(1) {
        width: 32.33%;
        margin: 0px;
        min-width: 32.33%; }
      [data-uxd-data-display-carousel-cmp] .carousel-content-container ul > .button-select-item:first-child:nth-last-child(2),
      [data-uxd-data-display-carousel-cmp] .carousel-content-container ul > .button-select-item:first-child:nth-last-child(2) ~ .button-select-item {
        min-width: 32.33%;
        width: 32.33%; }
      [data-uxd-data-display-carousel-cmp] .carousel-content-container ul .button-select-item {
        margin: 0 0.5%; }
        [data-uxd-data-display-carousel-cmp] .carousel-content-container ul .button-select-item:last-child {
          margin-right: 0px; } }
  [data-uxd-data-display-carousel-cmp] .carousel-content-container ul > .button-select-item li {
    margin: 0px;
    width: 98%; }
  [data-uxd-data-display-carousel-cmp] .fa {
    color: white;
    padding: 0.75rem 0rem;
    background: #FE5E3C; }

/* tab boxes transforms into carousel on click */
.carousel-selector-cmp .tab-transform-carousel-wrapper {
  /* scss for the carousel overrides */ }
  .carousel-selector-cmp .tab-transform-carousel-wrapper .carousel-content-container {
    padding: 0px; }
    .carousel-selector-cmp .tab-transform-carousel-wrapper .carousel-content-container ul > .default-select-item-container {
      width: 100%;
      min-height: 492px;
      z-index: -9999;
      background-color: #0079C2;
      color: white;
      margin: 0px; }
      .carousel-selector-cmp .tab-transform-carousel-wrapper .carousel-content-container ul > .default-select-item-container .default-select-item {
        width: 88%;
        padding: 1rem 1rem 3rem 1rem;
        position: relative;
        transform: none;
        top: 0px;
        left: 0px; }
  .carousel-selector-cmp .tab-transform-carousel-wrapper .carousel-right-control {
    right: 0px; }
  .carousel-selector-cmp .tab-transform-carousel-wrapper .carousel-left-control {
    left: 0px; }
  .carousel-selector-cmp .tab-transform-carousel-wrapper .carousel-right-control,
  .carousel-selector-cmp .tab-transform-carousel-wrapper .carousel-left-control {
    width: 6%;
    top: 0;
    bottom: 0;
    cursor: pointer;
    transform: none; }
    .carousel-selector-cmp .tab-transform-carousel-wrapper .carousel-right-control a,
    .carousel-selector-cmp .tab-transform-carousel-wrapper .carousel-left-control a {
      width: 32.5px;
      margin: 0 auto;
      display: block; }
  .carousel-selector-cmp .tab-transform-carousel-wrapper .fa {
    color: white;
    position: absolute;
    top: 50%; }
  .carousel-selector-cmp .tab-transform-carousel-wrapper .closeCarousel {
    cursor: pointer;
    position: absolute;
    width: 50px;
    height: 50px;
    top: 0;
    right: 0;
    z-index: 9999; }
    .carousel-selector-cmp .tab-transform-carousel-wrapper .closeCarousel span {
      position: absolute;
      transform: rotate(-45deg);
      color: #fff;
      top: 5px;
      right: 8px;
      padding: 1rem; }
  .carousel-selector-cmp .tab-transform-carousel-wrapper .carousel-indicators {
    bottom: 0px;
    width: 100%;
    margin: 0 auto;
    left: 0px; }
    .carousel-selector-cmp .tab-transform-carousel-wrapper .carousel-indicators li {
      width: 12px;
      height: 12px;
      margin: 0 5px; }
      .carousel-selector-cmp .tab-transform-carousel-wrapper .carousel-indicators li.active {
        background-color: white; }

.tab-transform-color-boxes-wrapper {
  /* this is the tab styles */ }
  .tab-transform-color-boxes-wrapper .color-boxes-container {
    position: relative;
    background-color: #00609a;
    border: 1px solid #fff;
    color: #fff;
    text-align: center;
    padding: 35px 10px;
    cursor: pointer; }

/** Start datepicker.scss **/
.ui-datepicker .ui-datepicker-header {
  padding: 0.2em 0;
  position: relative; }

.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
  height: 1.8em;
  position: absolute;
  top: 2px;
  width: 1.8em; }

.ui-datepicker .ui-datepicker-prev-hover, .ui-datepicker .ui-datepicker-next-hover {
  top: 1px; }

.ui-datepicker .ui-datepicker-prev {
  left: 2px; }

.ui-datepicker .ui-datepicker-next {
  right: 2px; }

.ui-datepicker .ui-datepicker-prev-hover {
  left: 1px; }

.ui-datepicker .ui-datepicker-next-hover {
  right: 1px; }

.ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span {
  display: block;
  left: 50%;
  margin-left: -8px;
  margin-top: -8px;
  position: absolute;
  top: 50%; }

.ui-datepicker .ui-datepicker-title {
  line-height: 1.8em;
  margin: 0 2.3em;
  text-align: center; }

.ui-datepicker .ui-datepicker-title select {
  font-size: 1em;
  margin: 1px 0; }

.ui-datepicker select.ui-datepicker-month, .ui-datepicker select.ui-datepicker-year {
  width: 45%; }

.ui-datepicker table {
  border-collapse: collapse;
  font-size: 0.9em;
  margin: 0 0 0.4em;
  width: 100%; }

.ui-datepicker th {
  border: 0 none;
  font-weight: bold;
  padding: 0.7em 0.3em;
  text-align: center; }

.ui-datepicker td {
  border: 0 none;
  padding: 1px; }

.ui-datepicker td span, .ui-datepicker td a {
  display: block;
  padding: 0.2em;
  text-align: right;
  text-decoration: none; }

.ui-datepicker .ui-datepicker-buttonpane {
  background-image: none;
  border-bottom: 0 none;
  border-left: 0 none;
  border-right: 0 none;
  margin: 0.7em 0 0;
  padding: 0 0.2em; }

.ui-datepicker .ui-datepicker-buttonpane button {
  cursor: pointer;
  float: right;
  margin: 0.5em 0.2em 0.4em;
  overflow: visible;
  padding: 0.2em 0.6em 0.3em;
  width: auto; }

.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: left; }

.ui-datepicker.ui-datepicker-multi {
  width: auto; }

.ui-datepicker-multi .ui-datepicker-group {
  float: left; }

.ui-datepicker-multi .ui-datepicker-group table {
  margin: 0 auto 0.4em;
  width: 95%; }

.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 50%; }

.ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.3%; }

.ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%; }

.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header, .ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
  border-left-width: 0; }

.ui-datepicker-multi .ui-datepicker-buttonpane {
  clear: left; }

.ui-datepicker-row-break {
  clear: both;
  font-size: 0;
  width: 100%; }

.ui-datepicker-rtl {
  direction: rtl; }

.ui-datepicker-rtl .ui-datepicker-prev {
  left: auto;
  right: 2px; }

.ui-datepicker-rtl .ui-datepicker-next {
  left: 2px;
  right: auto; }

.ui-datepicker-rtl .ui-datepicker-prev:hover {
  left: auto;
  right: 1px; }

.ui-datepicker-rtl .ui-datepicker-next:hover {
  left: 1px;
  right: auto; }

.ui-datepicker-rtl .ui-datepicker-buttonpane {
  clear: right; }

.ui-datepicker-rtl .ui-datepicker-buttonpane button {
  float: left; }

.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current, .ui-datepicker-rtl .ui-datepicker-group {
  float: right; }

.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header, .ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
  border-left-width: 1px;
  border-right-width: 0; }

.ui-datepicker {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background: #fff none repeat scroll 0 0;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-color: #69B3E7 #DCDCDC #DCDCDC;
  border-image: none;
  border-style: solid;
  border-width: 1px;
  margin: -1px 2px;
  padding: 0;
  width: 220px;
  display: none; }

table.ui-datepicker-calendar {
  table-layout: fixed;
  width: 100%; }

.ui-datepicker-header {
  background: #0079C2 none repeat scroll 0 0;
  color: #fff;
  font-size: 1rem; }

.ui-datepicker td span, .ui-datepicker td a {
  color: #666666;
  font-size: 0.8889rem;
  text-align: center; }

.ui-datepicker td.ui-datepicker-current-day a, .ui-datepicker td a:hover {
  background: #0079C2 none repeat scroll 0 0;
  color: #fff; }

td.ui-datepicker-unselectable span {
  color: #DCDCDC; }

td.ui-datepicker-other-month.ui-datepicker-unselectable span {
  display: none; }

.ui-datepicker .ui-datepicker-prev:hover, .ui-datepicker .ui-datepicker-prev {
  left: 0;
  text-decoration: none;
  top: 0; }
  .ui-datepicker .ui-datepicker-prev:hover.ui-state-disabled, .ui-datepicker .ui-datepicker-prev.ui-state-disabled {
    cursor: default;
    opacity: 0.5; }

.ui-datepicker .ui-datepicker-next:hover, .ui-datepicker .ui-datepicker-next {
  right: 0;
  text-decoration: none;
  top: 0; }
  .ui-datepicker .ui-datepicker-next:hover.ui-state-disabled, .ui-datepicker .ui-datepicker-next.ui-state-disabled {
    cursor: default;
    opacity: 0.5; }

.ui-datepicker-prev::before, .ui-datepicker-next::before {
  top: 2px;
  left: 13px;
  position: relative;
  color: #FFFFFF;
  font-family: 'FontAwesome';
  font-size: 1.389rem; }

.ui-datepicker-prev::before {
  content: "\f0d9"; }

.ui-datepicker-next::before {
  content: "\f0da";
  left: 9px; }

.ui-datepicker-trigger {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background-color: transparent;
  border: 0px transparent;
  color: #0079C2;
  position: relative;
  width: 0px;
  font-size: 1.25rem;
  padding: 0 2.3rem 0 0;
  margin: 0 0 0 -2.3rem;
  top: 1px;
  display: block;
  float: left;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; }

@media screen and (min-width: 480px) {
  .ui-datepicker-trigger {
    top: 0px; } }

.hasDatepicker.ant-text-input {
  float: left; }

.hasDatepicker.ant-text-input::-ms-clear {
  display: none; }

.hasDatepicker.focus,
.hasDatepicker.focus + .ui-datepicker-trigger .fa {
  border-color: #69B3E7; }

input.pfText.hasDatepicker,
input.pfText.hasDatepicker:hover,
input.pfText.hasDatepicker:focus {
  width: auto;
  margin-left: 0 !important; }

.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
  display: none; }

.ui-datepicker-prev {
  float: left;
  cursor: pointer; }

.ui-datepicker-next {
  float: right;
  cursor: pointer; }

.modal-open.ui-datepicker {
  z-index: 1056; }

.ui-datepicker {
  z-index: 112; }

.ui-datepicker .ui-datepicker-calendar td a.ui-state-default,
a.ui-datepicker-prev,
a.ui-datepicker-next {
  border-bottom: 0 none; }

.ui-datepicker-trigger span.fa {
  color: #0079C2;
  padding: .4rem .5rem;
  border-left: 1px solid #666666;
  position: relative;
  display: block;
  font-size: 1.25rem; }
  @media screen and (min-width: 480px) {
    .ui-datepicker-trigger span.fa {
      padding: .4rem .5rem .45rem; } }

.ui-datepicker-trigger .fa-remove {
  padding-left: 2px; }

/*** Start expand.scss ***/
/*** Expand/Collapse Styles ***/
.ant-expand-collapse .panel-heading {
  padding: 0px .6rem;
  background-color: transparent; }
  .ant-expand-collapse .panel-heading .panel-title a {
    display: table-row;
    width: 100%;
    border: 0px; }
  .ant-expand-collapse .panel-heading a span.fa {
    display: table-cell;
    vertical-align: middle;
    margin-left: 2px;
    color: #FE5E3C; }
  .ant-expand-collapse .panel-heading a span.heading-text {
    display: table-cell;
    vertical-align: middle;
    padding-left: 1rem; }

.ant-expand-collapse .panel-heading.odd {
  background-color: #EEEEEE; }

.ant-expand-collapse .panel-heading a.ant-toggle-ctrl {
  font-size: 1.444rem;
  padding: .6rem 0;
  display: block;
  background-color: transparent;
  background-image: none;
  margin-top: -1px;
  filter: none; }

.ant-expand-collapse .panel-heading a, .ant-expand-collapse .panel-heading a:hover, .ant-expand-collapse .panel-heading a:focus {
  text-decoration: none;
  border-bottom: none; }

.ant-expand-collapse .panel + .panel {
  margin-top: 0px; }

.ant-expand-collapse .panel-body {
  margin: 0px;
  padding: 1.714rem 1.111rem; }

.ant-expand-collapse .panel, .ant-expand-collapse .panel-heading + .panel-collapse > .panel-body {
  border: 0px;
  box-shadow: 0px 0px;
  border-radius: 0; }

.ant-expand-collapse .collapse {
  -webkit-transition: height 500ms ease-out;
  -o-transition: height 500ms ease-out;
  transition: height 500ms ease-out;
  height: 0;
  display: inherit;
  overflow: hidden; }

.ant-expand-collapse .collapse.in {
  -webkit-transition: height 500ms ease-out;
  -o-transition: height 500ms ease-out;
  transition: height 500ms ease-out;
  height: auto;
  display: inherit; }

/** Responsive Collapsible Block **/
.ant-responsive-collapse,
.ant-responsive-collapse + .ant-responsive-collapse {
  margin-top: 0px; }

.ant-responsive-collapse-heading {
  cursor: pointer;
  width: 100%;
  background: #EEEEEE;
  margin-bottom: 1rem; }
  .ant-responsive-collapse-heading .collapsible-heading-text {
    display: table-cell;
    padding: .6rem 0px;
    vertical-align: middle;
    font-size: 1.444rem;
    font-weight: 300; }
  .ant-responsive-collapse-heading a, .ant-responsive-collapse-heading .ant-collapse-button {
    vertical-align: middle;
    display: table-cell;
    padding: .6rem;
    border-bottom: 0px solid transparent;
    margin-right: 12px; }
  .ant-responsive-collapse-heading.ant-heading-one {
    font-size: 1.444rem;
    font-weight: 400; }

.ant-responsive-collapse-body {
  max-height: 0px;
  padding: 0px;
  overflow: hidden;
  visibility: hidden;
  transition: max-height .75s ease, visibility .35s ease, padding .35s ease .35s; }

.ant-block-expanded .ant-responsive-collapse-body {
  max-height: 5000px;
  padding: .714rem .714rem 1.714rem;
  visibility: visible;
  transition: max-height .75s ease, visibility .35s ease; }

.ant-block-expanded .ant-responsive-collapse-heading {
  margin-bottom: 15px; }

.ant-block-expanded .ant-responsive-collapse-heading a .fa:before, .ant-block-expanded .ant-responsive-collapse-heading .ant-collapse-button .fa:before {
  content: "\f28f"; }

@media (min-width: 801px) {
  .ant-responsive-collapse {
    margin-top: 0px; }
  .ant-responsive-collapse + .ant-responsive-collapse {
    margin-top: 1rem; }
  .ant-responsive-collapse-heading {
    background: transparent;
    margin-bottom: 0px; }
    .ant-responsive-collapse-heading a, .ant-responsive-collapse-heading .ant-collapse-button {
      display: none; }
    .ant-responsive-collapse-heading.ant-heading-one {
      font-size: 1.8rem;
      font-weight: 300;
      text-align: center; }
      .ant-responsive-collapse-heading.ant-heading-one .collapsible-heading-text {
        font-size: 1.8rem; }
    .ant-responsive-collapse-heading .collapsible-heading-text {
      display: inline-block;
      text-align: center; }
  .ant-responsive-collapse-body {
    max-height: 3000px;
    padding: 1.111rem 0;
    visibility: visible; }
  .ant-block-expanded .ant-responsive-collapse-heading {
    margin-bottom: 0px; }
  .ant-block-expanded .ant-responsive-collapse-body {
    max-height: 3000px;
    padding: 1.111rem 0;
    visibility: visible; } }

/* Blue Accordion Styles for Collapsible Block */
.ant-accordion-blue.ant-responsive-collapse {
  border: 1px solid #0079C2; }
  .ant-accordion-blue.ant-responsive-collapse + .ant-responsive-collapse {
    margin-top: 15px; }
  .ant-accordion-blue.ant-responsive-collapse .ant-responsive-collapse-heading {
    background: #0079C2;
    color: #FFFFFF;
    margin-bottom: 0px;
    font-size: 1.444rem;
    font-weight: 300; }
    .ant-accordion-blue.ant-responsive-collapse .ant-responsive-collapse-heading a .fa {
      color: #FFFFFF; }
  .ant-accordion-blue.ant-responsive-collapse .ant-block-expanded .ant-responsive-collapse-body {
    padding: 1.111rem; }
  .ant-accordion-blue.ant-responsive-collapse .ant-block-expanded .ant-responsive-collapse-heading {
    margin-bottom: 0px; }
  @media (min-width: 801px) {
    .ant-accordion-blue.ant-responsive-collapse .ant-responsive-collapse-heading a {
      display: table-cell;
      vertical-align: middle; }
    .ant-accordion-blue.ant-responsive-collapse .ant-responsive-collapse-heading .collapsible-heading-text {
      display: table-cell;
      vertical-align: middle; }
    .ant-accordion-blue.ant-responsive-collapse .ant-responsive-collapse-body {
      max-height: 0px;
      padding: 0px;
      overflow: hidden;
      visibility: hidden;
      transition: max-height .75s ease, visibility .35s ease, padding .35s ease .35s; } }
  .ant-accordion-blue.ant-responsive-collapse.ant-block-expanded .ant-responsive-collapse-body {
    max-height: 9000px;
    padding: 1.111rem;
    visibility: visible;
    overflow: visible;
    transition: max-height .75s ease, visibility .35s ease; }
  .ant-accordion-blue.ant-responsive-collapse.ant-block-expanded .ant-responsive-collapse-heading {
    margin-bottom: 0px; }

/*** Expand/Collapse Styles (_expand.scss)***/
.collapseItemContainer div[data-uxd-collapse-item-cmp],
div[data-uxd-expand-collapse-wcs],
div[data-tcp-wcs-expand-collapse] {
  /** Responsive Collapsible Block **/
  /* Blue Accordion Styles for Collapsible Block */ }
  .collapseItemContainer div[data-uxd-collapse-item-cmp].addStripe:nth-of-type(odd) .ant-collapse-heading,
  div[data-uxd-expand-collapse-wcs].addStripe:nth-of-type(odd) .ant-collapse-heading,
  div[data-tcp-wcs-expand-collapse].addStripe:nth-of-type(odd) .ant-collapse-heading {
    background: transparent; }
  .collapseItemContainer div[data-uxd-collapse-item-cmp]:last-child .blue,
  div[data-uxd-expand-collapse-wcs]:last-child .blue,
  div[data-tcp-wcs-expand-collapse]:last-child .blue {
    border-bottom: 2px solid #EEEEEE; }
  .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-collapse-heading span.fa,
  div[data-uxd-expand-collapse-wcs] .ant-collapse-heading span.fa,
  div[data-tcp-wcs-expand-collapse] .ant-collapse-heading span.fa {
    color: #FE5E3C; }
  .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-expand-collapse .ant-toggle-ctrl span.fa:before,
  div[data-uxd-expand-collapse-wcs] .ant-expand-collapse .ant-toggle-ctrl span.fa:before,
  div[data-tcp-wcs-expand-collapse] .ant-expand-collapse .ant-toggle-ctrl span.fa:before {
    content: "\f28e"; }
  .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-expand-collapse .ant-toggle-ctrl.collapsed span.fa:before,
  div[data-uxd-expand-collapse-wcs] .ant-expand-collapse .ant-toggle-ctrl.collapsed span.fa:before,
  div[data-tcp-wcs-expand-collapse] .ant-expand-collapse .ant-toggle-ctrl.collapsed span.fa:before {
    content: "\f2c2"; }
  .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-collapse,
  .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-collapse + .ant-collapse,
  div[data-uxd-expand-collapse-wcs] .ant-collapse,
  div[data-uxd-expand-collapse-wcs] .ant-collapse + .ant-collapse,
  div[data-tcp-wcs-expand-collapse] .ant-collapse,
  div[data-tcp-wcs-expand-collapse] .ant-collapse + .ant-collapse {
    margin-top: 0px; }
  .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-collapse-heading,
  div[data-uxd-expand-collapse-wcs] .ant-collapse-heading,
  div[data-tcp-wcs-expand-collapse] .ant-collapse-heading {
    cursor: pointer;
    width: 100%;
    background: #EEEEEE;
    padding: .6rem 0px; }
    .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-collapse-heading .collapsible-heading-text,
    div[data-uxd-expand-collapse-wcs] .ant-collapse-heading .collapsible-heading-text,
    div[data-tcp-wcs-expand-collapse] .ant-collapse-heading .collapsible-heading-text {
      display: inline-block;
      font-size: 1.444rem;
      padding: 0.3rem;
      font-weight: 300; }
    .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-collapse-heading .ant-collapse-button,
    div[data-uxd-expand-collapse-wcs] .ant-collapse-heading .ant-collapse-button,
    div[data-tcp-wcs-expand-collapse] .ant-collapse-heading .ant-collapse-button {
      display: inline-block;
      border: none;
      background-color: transparent;
      margin-left: 0.6rem; }
      .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-collapse-heading .ant-collapse-button[tabindex='-1']:focus,
      div[data-uxd-expand-collapse-wcs] .ant-collapse-heading .ant-collapse-button[tabindex='-1']:focus,
      div[data-tcp-wcs-expand-collapse] .ant-collapse-heading .ant-collapse-button[tabindex='-1']:focus {
        outline: none; }
    .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-collapse-heading a, .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-collapse-heading .ant-collapse-button,
    div[data-uxd-expand-collapse-wcs] .ant-collapse-heading a,
    div[data-uxd-expand-collapse-wcs] .ant-collapse-heading .ant-collapse-button,
    div[data-tcp-wcs-expand-collapse] .ant-collapse-heading a,
    div[data-tcp-wcs-expand-collapse] .ant-collapse-heading .ant-collapse-button {
      display: inline-block; }
    .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-collapse-heading.ant-heading-one,
    div[data-uxd-expand-collapse-wcs] .ant-collapse-heading.ant-heading-one,
    div[data-tcp-wcs-expand-collapse] .ant-collapse-heading.ant-heading-one {
      font-size: 1.444rem;
      font-weight: 400; }
  .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-collapse-body,
  div[data-uxd-expand-collapse-wcs] .ant-collapse-body,
  div[data-tcp-wcs-expand-collapse] .ant-collapse-body {
    max-height: 0px;
    padding: 0px;
    overflow: hidden;
    visibility: hidden;
    transition: max-height .75s ease, visibility .35s ease, padding .35s ease .35s; }
  .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-collapse-heading a .fa:before,
  .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-collapse-heading .ant-collapse-button .fa:before,
  div[data-uxd-expand-collapse-wcs] .ant-collapse-heading a .fa:before,
  div[data-uxd-expand-collapse-wcs] .ant-collapse-heading .ant-collapse-button .fa:before,
  div[data-tcp-wcs-expand-collapse] .ant-collapse-heading a .fa:before,
  div[data-tcp-wcs-expand-collapse] .ant-collapse-heading .ant-collapse-button .fa:before {
    content: "\002b";
    font-weight: bold; }
  .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-collapse-heading a .fa, .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-collapse-heading a .icon,
  .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-collapse-heading .ant-collapse-button .fa,
  .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-collapse-heading .ant-collapse-button .icon,
  div[data-uxd-expand-collapse-wcs] .ant-collapse-heading a .fa,
  div[data-uxd-expand-collapse-wcs] .ant-collapse-heading a .icon,
  div[data-uxd-expand-collapse-wcs] .ant-collapse-heading .ant-collapse-button .fa,
  div[data-uxd-expand-collapse-wcs] .ant-collapse-heading .ant-collapse-button .icon,
  div[data-tcp-wcs-expand-collapse] .ant-collapse-heading a .fa,
  div[data-tcp-wcs-expand-collapse] .ant-collapse-heading a .icon,
  div[data-tcp-wcs-expand-collapse] .ant-collapse-heading .ant-collapse-button .fa,
  div[data-tcp-wcs-expand-collapse] .ant-collapse-heading .ant-collapse-button .icon {
    font-weight: bold; }
  .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-block-expanded .ant-collapse-body,
  div[data-uxd-expand-collapse-wcs] .ant-block-expanded .ant-collapse-body,
  div[data-tcp-wcs-expand-collapse] .ant-block-expanded .ant-collapse-body {
    max-height: 5000px;
    padding: .714rem .714rem 1.714rem;
    visibility: visible;
    transition: max-height .75s ease, visibility .35s ease; }
  .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-block-expanded .ant-collapse-heading,
  div[data-uxd-expand-collapse-wcs] .ant-block-expanded .ant-collapse-heading,
  div[data-tcp-wcs-expand-collapse] .ant-block-expanded .ant-collapse-heading {
    margin-bottom: 15px; }
  .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-block-expanded .ant-collapse-heading a .fa:before, .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-block-expanded .ant-collapse-heading .ant-collapse-button .fa:before,
  div[data-uxd-expand-collapse-wcs] .ant-block-expanded .ant-collapse-heading a .fa:before,
  div[data-uxd-expand-collapse-wcs] .ant-block-expanded .ant-collapse-heading .ant-collapse-button .fa:before,
  div[data-tcp-wcs-expand-collapse] .ant-block-expanded .ant-collapse-heading a .fa:before,
  div[data-tcp-wcs-expand-collapse] .ant-block-expanded .ant-collapse-heading .ant-collapse-button .fa:before {
    content: "\2212"; }
  @media (min-width: 801px) {
    .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-mobile-collapse .ant-collapse,
    div[data-uxd-expand-collapse-wcs] .ant-mobile-collapse .ant-collapse,
    div[data-tcp-wcs-expand-collapse] .ant-mobile-collapse .ant-collapse {
      margin-top: 0px; }
    .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-mobile-collapse .ant-collapse + .ant-collapse,
    div[data-uxd-expand-collapse-wcs] .ant-mobile-collapse .ant-collapse + .ant-collapse,
    div[data-tcp-wcs-expand-collapse] .ant-mobile-collapse .ant-collapse + .ant-collapse {
      margin-top: 1rem; }
    .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-mobile-collapse .ant-collapse-heading,
    div[data-uxd-expand-collapse-wcs] .ant-mobile-collapse .ant-collapse-heading,
    div[data-tcp-wcs-expand-collapse] .ant-mobile-collapse .ant-collapse-heading {
      background: transparent;
      margin-bottom: 0px; }
      .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-mobile-collapse .ant-collapse-heading a, .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-mobile-collapse .ant-collapse-heading .ant-collapse-button,
      div[data-uxd-expand-collapse-wcs] .ant-mobile-collapse .ant-collapse-heading a,
      div[data-uxd-expand-collapse-wcs] .ant-mobile-collapse .ant-collapse-heading .ant-collapse-button,
      div[data-tcp-wcs-expand-collapse] .ant-mobile-collapse .ant-collapse-heading a,
      div[data-tcp-wcs-expand-collapse] .ant-mobile-collapse .ant-collapse-heading .ant-collapse-button {
        display: none; }
      .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-mobile-collapse .ant-collapse-heading.ant-heading-one,
      div[data-uxd-expand-collapse-wcs] .ant-mobile-collapse .ant-collapse-heading.ant-heading-one,
      div[data-tcp-wcs-expand-collapse] .ant-mobile-collapse .ant-collapse-heading.ant-heading-one {
        font-size: 1.8rem;
        font-weight: 300;
        text-align: center; }
        .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-mobile-collapse .ant-collapse-heading.ant-heading-one .collapsible-heading-text,
        div[data-uxd-expand-collapse-wcs] .ant-mobile-collapse .ant-collapse-heading.ant-heading-one .collapsible-heading-text,
        div[data-tcp-wcs-expand-collapse] .ant-mobile-collapse .ant-collapse-heading.ant-heading-one .collapsible-heading-text {
          font-size: 1.8rem; }
      .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-mobile-collapse .ant-collapse-heading .collapsible-heading-text,
      div[data-uxd-expand-collapse-wcs] .ant-mobile-collapse .ant-collapse-heading .collapsible-heading-text,
      div[data-tcp-wcs-expand-collapse] .ant-mobile-collapse .ant-collapse-heading .collapsible-heading-text {
        display: inline-block;
        text-align: center; }
    .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-mobile-collapse .ant-collapse-body,
    div[data-uxd-expand-collapse-wcs] .ant-mobile-collapse .ant-collapse-body,
    div[data-tcp-wcs-expand-collapse] .ant-mobile-collapse .ant-collapse-body {
      max-height: 3000px;
      padding: 1.111rem 0;
      visibility: visible; }
    .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-mobile-collapse .ant-block-expanded .ant-collapse-heading,
    div[data-uxd-expand-collapse-wcs] .ant-mobile-collapse .ant-block-expanded .ant-collapse-heading,
    div[data-tcp-wcs-expand-collapse] .ant-mobile-collapse .ant-block-expanded .ant-collapse-heading {
      margin-bottom: 0px; }
    .collapseItemContainer div[data-uxd-collapse-item-cmp] .ant-mobile-collapse .ant-block-expanded .ant-collapse-body,
    div[data-uxd-expand-collapse-wcs] .ant-mobile-collapse .ant-block-expanded .ant-collapse-body,
    div[data-tcp-wcs-expand-collapse] .ant-mobile-collapse .ant-block-expanded .ant-collapse-body {
      max-height: 3000px;
      padding: 1.111rem 0;
      visibility: visible; } }
  .collapseItemContainer div[data-uxd-collapse-item-cmp] .blue.ant-collapse + .ant-collapse,
  div[data-uxd-expand-collapse-wcs] .blue.ant-collapse + .ant-collapse,
  div[data-tcp-wcs-expand-collapse] .blue.ant-collapse + .ant-collapse {
    margin-top: 0rem; }
  .collapseItemContainer div[data-uxd-collapse-item-cmp] .blue.ant-collapse .ant-collapse-heading,
  div[data-uxd-expand-collapse-wcs] .blue.ant-collapse .ant-collapse-heading,
  div[data-tcp-wcs-expand-collapse] .blue.ant-collapse .ant-collapse-heading {
    background: transparent;
    margin-bottom: 0px;
    font-weight: 300;
    border-top: 2px solid #EEEEEE;
    padding: 0.6rem;
    position: relative; }
    .collapseItemContainer div[data-uxd-collapse-item-cmp] .blue.ant-collapse .ant-collapse-heading a, .collapseItemContainer div[data-uxd-collapse-item-cmp] .blue.ant-collapse .ant-collapse-heading .ant-collapse-button,
    div[data-uxd-expand-collapse-wcs] .blue.ant-collapse .ant-collapse-heading a,
    div[data-uxd-expand-collapse-wcs] .blue.ant-collapse .ant-collapse-heading .ant-collapse-button,
    div[data-tcp-wcs-expand-collapse] .blue.ant-collapse .ant-collapse-heading a,
    div[data-tcp-wcs-expand-collapse] .blue.ant-collapse .ant-collapse-heading .ant-collapse-button {
      float: right;
      width: 100%;}
      .collapseItemContainer div[data-uxd-collapse-item-cmp] .blue.ant-collapse .ant-collapse-heading a .fa, .collapseItemContainer div[data-uxd-collapse-item-cmp] .blue.ant-collapse .ant-collapse-heading .ant-collapse-button .fa,
      div[data-uxd-expand-collapse-wcs] .blue.ant-collapse .ant-collapse-heading a .fa,
      div[data-uxd-expand-collapse-wcs] .blue.ant-collapse .ant-collapse-heading .ant-collapse-button .fa,
      div[data-tcp-wcs-expand-collapse] .blue.ant-collapse .ant-collapse-heading a .fa,
      div[data-tcp-wcs-expand-collapse] .blue.ant-collapse .ant-collapse-heading .ant-collapse-button .fa {
        color: #0079C2; }
  .collapseItemContainer div[data-uxd-collapse-item-cmp] .blue.ant-collapse .ant-collapse-heading .wcs-ec-heading-content,
  div[data-uxd-expand-collapse-wcs] .blue.ant-collapse .ant-collapse-heading .wcs-ec-heading-content,
  div[data-tcp-wcs-expand-collapse] .blue.ant-collapse .ant-collapse-heading .wcs-ec-heading-content {
    padding: 0.3rem; }
  .collapseItemContainer div[data-uxd-collapse-item-cmp] .blue.ant-collapse .ant-block-expanded .ant-collapse-body,
  div[data-uxd-expand-collapse-wcs] .blue.ant-collapse .ant-block-expanded .ant-collapse-body,
  div[data-tcp-wcs-expand-collapse] .blue.ant-collapse .ant-block-expanded .ant-collapse-body {
    padding: 1.111rem; }
  .collapseItemContainer div[data-uxd-collapse-item-cmp] .blue.ant-collapse .ant-block-expanded .ant-collapse-heading,
  div[data-uxd-expand-collapse-wcs] .blue.ant-collapse .ant-block-expanded .ant-collapse-heading,
  div[data-tcp-wcs-expand-collapse] .blue.ant-collapse .ant-block-expanded .ant-collapse-heading {
    margin-bottom: 0px; }
  @media (min-width: 801px) {
    .collapseItemContainer div[data-uxd-collapse-item-cmp] .blue.ant-collapse .ant-mobile-collapse .ant-collapse-heading a, .collapseItemContainer div[data-uxd-collapse-item-cmp] .blue.ant-collapse .ant-mobile-collapse .ant-collapse-heading .ant-collapse-button,
    div[data-uxd-expand-collapse-wcs] .blue.ant-collapse .ant-mobile-collapse .ant-collapse-heading a,
    div[data-uxd-expand-collapse-wcs] .blue.ant-collapse .ant-mobile-collapse .ant-collapse-heading .ant-collapse-button,
    div[data-tcp-wcs-expand-collapse] .blue.ant-collapse .ant-mobile-collapse .ant-collapse-heading a,
    div[data-tcp-wcs-expand-collapse] .blue.ant-collapse .ant-mobile-collapse .ant-collapse-heading .ant-collapse-button {
      display: table-cell;
      vertical-align: middle; }
    .collapseItemContainer div[data-uxd-collapse-item-cmp] .blue.ant-collapse .ant-mobile-collapse .ant-collapse-heading .collapsible-heading-text,
    div[data-uxd-expand-collapse-wcs] .blue.ant-collapse .ant-mobile-collapse .ant-collapse-heading .collapsible-heading-text,
    div[data-tcp-wcs-expand-collapse] .blue.ant-collapse .ant-mobile-collapse .ant-collapse-heading .collapsible-heading-text {
      display: table-cell;
      vertical-align: middle; }
    .collapseItemContainer div[data-uxd-collapse-item-cmp] .blue.ant-collapse .ant-mobile-collapse .ant-collapse-body,
    div[data-uxd-expand-collapse-wcs] .blue.ant-collapse .ant-mobile-collapse .ant-collapse-body,
    div[data-tcp-wcs-expand-collapse] .blue.ant-collapse .ant-mobile-collapse .ant-collapse-body {
      max-height: 0px;
      padding: 0px;
      overflow: hidden;
      visibility: hidden;
      transition: max-height .75s ease, visibility .35s ease, padding .35s ease .35s; }
    .collapseItemContainer div[data-uxd-collapse-item-cmp] .blue.ant-collapse .ant-mobile-collapse .ant-block-expanded .ant-collapse-body,
    div[data-uxd-expand-collapse-wcs] .blue.ant-collapse .ant-mobile-collapse .ant-block-expanded .ant-collapse-body,
    div[data-tcp-wcs-expand-collapse] .blue.ant-collapse .ant-mobile-collapse .ant-block-expanded .ant-collapse-body {
      max-height: 9000px;
      padding: 1.111rem;
      visibility: visible;
      overflow: visible;
      transition: max-height .75s ease, visibility .35s ease; }
    .collapseItemContainer div[data-uxd-collapse-item-cmp] .blue.ant-collapse .ant-mobile-collapse .ant-block-expanded .ant-collapse-heading,
    div[data-uxd-expand-collapse-wcs] .blue.ant-collapse .ant-mobile-collapse .ant-block-expanded .ant-collapse-heading,
    div[data-tcp-wcs-expand-collapse] .blue.ant-collapse .ant-mobile-collapse .ant-block-expanded .ant-collapse-heading {
      margin-bottom: 0px; } }

div[data-uxd-expand-collapse-wcs] .collapse-block,
div[data-tcp-wcs-expand-collapse] .collapse-block {
  padding-top: 2px;
  margin-bottom: -2px; }
  div[data-uxd-expand-collapse-wcs] .collapse-block .ant-collapse.blue h3.ant-collapse-heading,
  div[data-tcp-wcs-expand-collapse] .collapse-block .ant-collapse.blue h3.ant-collapse-heading {
    border-top: 2px solid #A3A3A3;
    margin-top: -2px;
    padding: 1rem 15px 1rem 15px; }
    div[data-uxd-expand-collapse-wcs] .collapse-block .ant-collapse.blue h3.ant-collapse-heading a .fa,
    div[data-uxd-expand-collapse-wcs] .collapse-block .ant-collapse.blue h3.ant-collapse-heading .ant-collapse-button .fa,
    div[data-tcp-wcs-expand-collapse] .collapse-block .ant-collapse.blue h3.ant-collapse-heading a .fa,
    div[data-tcp-wcs-expand-collapse] .collapse-block .ant-collapse.blue h3.ant-collapse-heading .ant-collapse-button .fa {
      color: #666666; }
  div[data-uxd-expand-collapse-wcs] .collapse-block .ant-collapse.blue .ant-collapse-body,
  div[data-tcp-wcs-expand-collapse] .collapse-block .ant-collapse.blue .ant-collapse-body {
    padding: 0px; }
    div[data-uxd-expand-collapse-wcs] .collapse-block .ant-collapse.blue .ant-collapse-body .wcs-ec-body-content,
    div[data-tcp-wcs-expand-collapse] .collapse-block .ant-collapse.blue .ant-collapse-body .wcs-ec-body-content {
      padding: .714rem .714rem 1.714rem; }
  @media (min-width: 801px) {
    div[data-uxd-expand-collapse-wcs] .collapse-block .ant-collapse.blue .ant-collapse-body,
    div[data-tcp-wcs-expand-collapse] .collapse-block .ant-collapse.blue .ant-collapse-body {
      padding: 0px; }
      div[data-uxd-expand-collapse-wcs] .collapse-block .ant-collapse.blue .ant-collapse-body .wcs-ec-body-content,
      div[data-tcp-wcs-expand-collapse] .collapse-block .ant-collapse.blue .ant-collapse-body .wcs-ec-body-content {
        padding: 1.111rem; } }
  div[data-uxd-expand-collapse-wcs] .collapse-block:last-child .blue,
  div[data-tcp-wcs-expand-collapse] .collapse-block:last-child .blue {
    border-bottom: 2px solid #A3A3A3; }

/* Base toggle component styles */
.base-toggle-expanded {
  visibility: visible;
  opacity: 1;
  transition: max-height .25s ease-out, opacity .25s ease-out; }

.base-toggle-collapsed {
  visibility: hidden;
  opacity: 0;
  transition: max-height .25s ease-in, opacity .25s ease-in; }

.base-toggle-header {
  margin-right: .25rem;
  display: inline; }
  .base-toggle-header + .fa {
    position: relative;
    top: 1px; }

.base-toggle {
  display: inline-block; }

.base-toggle-icon-expanded.fa.fa-angle-down {
  transform: rotateX(180deg);
  transition: transform .25s ease-out; }

.base-toggle-icon-collapsed.fa.fa-angle-down {
  transform: rotateX(0deg);
  transition: transform .25s ease-in; }

/*** Start modal.scss ***/
/*** Modal Windows ***/
.modal-header {
  padding: 10px 25px 10px;
  border: 0px; }
  .modal-header .close {
    background-color: #FFF;
    color: #333;
    position: relative;
    top: -18px;
    right: -33px;
    opacity: 1;
    filter: alpha(opacity=100);
    display: block;
    padding: 5px 5px 4px 6px;
    border-radius: 50%;
    font-size: 1.667rem;
    border: 1px solid #DCDCDC;
    z-index: 1; }
    .modal-header .close .fa {
      color: #0079C2; }
  .modal-header .close:focus {
    box-shadow: 0px 0px 5px 2px #00609A;
    outline: none; }

.modal-footer {
  padding: 25px;
  text-align: left;
  border-top: 0px solid #e5e5e5; }

.modal-body {
  padding: 0 1.429rem; }

.modal-backdrop.in {
  opacity: 0.7;
  filter: alpha(opacity=70); }

.ant-error-modal h2 {
  color: #cc0033; }

.ant-error-modal h2:before {
  content: "\f1ce";
  font-family: "FontAwesome";
  font-size: 2.444rem;
  top: 9px;
  position: relative;
  margin-right: 10px; }

/* Alternative full screen modal for larger content */
.ant-fullscreen-modal .modal-dialog {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  margin: 0 auto;
  overflow: scroll; }

.ant-fullscreen-modal .modal-content {
  border: 0px;
  border-radius: 0px; }

.ant-fullscreen-modal .modal-top {
  border-radius: 0px;
  border: 0px;
  height: 100%;
  padding-bottom: 70px;
  overflow-x: auto;
  box-shadow: 0 0 0 transparent; }
  .ant-fullscreen-modal .modal-top .close {
    padding: 5px 5px 4px 6px;
    font-size: 22px;
    opacity: 1;
    color: #666;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
    transform: translate3d(0px, 0px, 0px); }

.ant-fullscreen-modal .modal-header {
  border: 0px;
  padding: 1.428rem 3.929rem 2.714rem 1.429rem; }

.ant-fullscreen-modal .modal-body {
  padding-bottom: 40px; }

.ant-fullscreen-modal .modal-footer {
  background: #eee;
  bottom: 0;
  position: absolute;
  padding: 0;
  text-align: center;
  width: 100%;
  left: 0px; }

.ant-fullscreen-modal .modal-footer .btn {
  min-width: auto;
  padding: 8px 20px 9px;
  margin: 1rem 0 1.142rem 0; }

.ant-fullscreen-modal .modal-backdrop {
  background: #666; }

@media screen and (max-width: 479px) {
  .modal,
  .modal-backdrop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    overflow: hidden; }
  .modal-dialog {
    margin: 0px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    overflow: hidden; }
    .modal-dialog .close {
      top: 12px;
      right: 8px;
      position: fixed;
      background-color: #0079C2;
      border: none;
      text-shadow: none;
      z-index: 1; }
      .modal-dialog .close .fa {
        color: #FFFFFF; }
  .modal-content {
    border: 0px;
    border-radius: 0px;
    height: 100%;
    width: 100vw;
    overflow: auto; }
  .modal-body {
    padding-bottom: 150px; }
  .modal-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #dedede;
    text-align: center;
    transform: translate3d(0, 0, 0); }
    .modal-footer .btn {
      font-size: 16px;
      min-width: auto; } }

@media (min-width: 480px) {
  .modal-dialog {
    position: relative;
    width: calc(100% - 40px);
    margin: 20px; }
  .ant-fullscreen-modal .modal-dialog {
    width: 100%;
    box-shadow: 1px 0px 4px 1px rgba(0, 0, 0, 0.2); }
  .ant-fullscreen-modal .modal-top .close {
    font-size: 36px;
    padding: 5px 5px 4px 6px; } }

@media (min-width: 801px) {
  .modal-dialog {
    width: 80%;
    max-width: 900px;
    margin: 50px auto; }
  .ant-fullscreen-modal .modal-dialog {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    max-width: 1024px; }
  .ant-fullscreen-modal .modal-top {
    padding: 0px 25px 100px; }
    .ant-fullscreen-modal .modal-top .close {
      padding: 5px 5px 4px 6px;
      font-size: 40px;
      top: 18px;
      right: 18px; } }

@media (min-width: 1024px) {
  .ant-fullscreen-modal .modal-top {
    padding: 0px 60px 100px; } }

/*** Start sub-nav-bar.scss ***/
.ant-fringe-mask, .ant-fringe-mask-left {
  background: -webkit-gradient(linear, left top, right top, color-stop(25%, rgba(248, 248, 248, 0)), color-stop(100%, rgba(238, 238, 238, 0.55)));
  height: 100%;
  position: absolute;
  pointer-events: none;
  right: 0;
  width: 3rem;
  z-index: 2; }

.ant-fringe-mask-left {
  left: 0;
  right: auto;
  background: -webkit-gradient(linear, left top, right top, color-stop(100%, rgba(248, 248, 248, 0)), color-stop(25%, rgba(238, 238, 238, 0.55))); }

.ant-sub-navigation-container, .ant-pattern-library-sub-nav {
  position: fixed;
  z-index: 1000;
  background-color: #0079C2;
  left: 0;
  right: 0;
  top: 65px;
  box-shadow: 0px 2px 10px -1px #666666; }
  .ant-sub-navigation-container .ant-sub-navigation, .ant-pattern-library-sub-nav .ant-sub-navigation {
    overflow-x: auto;
    padding: 0.7rem 0; }
    .ant-sub-navigation-container .ant-sub-navigation::-webkit-scrollbar, .ant-pattern-library-sub-nav .ant-sub-navigation::-webkit-scrollbar {
      display: none; }
    .ant-sub-navigation-container .ant-sub-navigation ul, .ant-pattern-library-sub-nav .ant-sub-navigation ul {
      list-style: none;
      display: flex;
      justify-content: flex-start;
      white-space: nowrap;
      margin: 0;
      padding: 0; }
      .ant-sub-navigation-container .ant-sub-navigation ul li, .ant-pattern-library-sub-nav .ant-sub-navigation ul li {
        display: inline-block;
        padding: 0 0.8rem;
        text-align: center;
        cursor: pointer; }
        .ant-sub-navigation-container .ant-sub-navigation ul li .fa, .ant-pattern-library-sub-nav .ant-sub-navigation ul li .fa {
          color: #FFFFFF;
          margin-right: .5rem;
          font-size: 1rem; }
        .ant-sub-navigation-container .ant-sub-navigation ul li:not(:last-of-type), .ant-pattern-library-sub-nav .ant-sub-navigation ul li:not(:last-of-type) {
          border-right: 1px solid #FFFFFF; }
    .ant-sub-navigation-container .ant-sub-navigation a, .ant-pattern-library-sub-nav .ant-sub-navigation a {
      color: #FFFFFF;
      border-bottom: 0;
      vertical-align: top; }
    .ant-sub-navigation-container .ant-sub-navigation.no-overflow ul, .ant-pattern-library-sub-nav .ant-sub-navigation.no-overflow ul {
      justify-content: center; }
  @media screen and (min-width: 1024px) {
    .ant-sub-navigation-container, .ant-pattern-library-sub-nav {
      top: 85px; } }

.ant-pattern-library-sub-nav .ant-sub-navigation-container, .ant-pattern-library-sub-nav .ant-pattern-library-sub-nav {
  bottom: 0;
  top: auto; }

/*** Start tab.scss ***/
/*bootstrap tab start*/
.tab-content > .tab-pane {
  padding: 10px; }

.tab-controls-container {
  margin-bottom: 50px; }

.tab-controls-container ul {
  list-style: none;
  text-align: center;
  margin: 0px;
  padding: 0px;
  border-bottom: 1px solid #DCDCDC; }

.tab-item-control {
  border-bottom: 5px solid transparent;
  margin: 10px 10px -3px;
  display: inline-block;
  width: 160px;
  padding-bottom: 10px;
  font-weight: 400; }

@media (min-width: 1024px) {
  .tab-item-control {
    margin: 20px 20px -3px;
    width: 195px; } }

@media (min-width: 1200px) {
  .tab-item-control {
    margin: 20px 20px -3px;
    width: 220px; } }

.tab-item-control.active-control {
  position: relative;
  background: #fff;
  border-bottom: 4px solid #FE5E3C; }
  .tab-item-control.active-control h2.tab-label {
    display: table-cell; }
  .tab-item-control.active-control span.tab-label {
    display: none; }

.tab-item-control.active-control .tab-label {
  color: #333;
  font-weight: 600; }

.tab-item-control .tab-label {
  color: #0079C2;
  font-size: 18px;
  vertical-align: middle;
  height: 65px;
  display: table-cell;
  text-align: center;
  width: 220px; }
  @media screen and (min-width: 800px) {
    .tab-item-control .tab-label {
      padding-top: 1.375rem; } }
  @media screen and (min-width: 1024px) {
    .tab-item-control .tab-label {
      padding-top: 1.222rem;
      height: 75px; } }

.tab-item-control h2.tab-label {
  display: none;
  line-height: 1.45em; }

.tab-item-control span.tab-label {
  display: table-cell; }

.tab-item-control .fa {
  font-size: 44px;
  display: block;
  text-align: center;
  margin: 10px 0px 0px;
  color: #FE5E3C;
  position: relative; }

.tab-item-control .fa-envelope {
  font-size: 42px; }

.tab-item-control .fa-lock:before {
  font-size: 52px;
  top: 8px;
  position: relative; }

.tab-content-container {
  clear: both; }

.tab-item {
  display: none; }

.tab-item.active-item {
  display: block; }

.accordion-item-control {
  margin: 0px;
  padding: 2rem 1rem 2rem 0;
  border-bottom: 2px solid #DCDCDC;
  position: relative;
  cursor: pointer; }

.item-control-inner {
  display: table-row; }

.item-control-inner span {
  display: table-cell;
  vertical-align: middle; }

.accordion-item-control.active-control {
  cursor: auto; }
  .accordion-item-control.active-control.open {
    border-bottom: 2px solid #FE5E3C; }
  .accordion-item-control.active-control + .tab-item {
    max-height: 0px;
    padding: 0px;
    overflow: hidden;
    visibility: hidden;
    transition: max-height .35s ease-out, visibility .35s ease-out, padding .35s ease-out .35s; }
  .accordion-item-control.active-control.open + .tab-item {
    max-height: 20000px;
    padding: 2rem .6rem 1.111rem;
    visibility: visible;
    transition: max-height .35s ease-in, visibility .35s ease-in; }

.accordion-item-control .accordion-label {
  color: #0079C2;
  font-size: 1.285rem; }

.accordion-item-control .fa {
  font-size: 1.714rem;
  color: #FE5E3C;
  text-align: center;
  width: 85px; }

/** Arrow -- Removed 5/18 RTD **/
/**
.accordion-item-control.active-control:after,
.accordion-item-control.active-control:before {
	top:100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.accordion-item-control.active-control:after {
	border-color: rgba(255, 255, 255, 0);
	border-top-color: #f8f8f8;;
	border-width: 30px;
	margin-left: -30px;
	border-top-width:20px;
}
.accordion-item-control.active-control:before {
	border-color: rgba(0, 96, 154, 0);
	border-top-color: $gray;
	border-width: 34px;
	margin-left: -34px;
	border-top-width:23px;
} **/
.accordion-item.active-item {
  border-bottom: 2px solid #DCDCDC;
  padding-bottom: 20px; }

.accordion-item.active-item:last-child {
  border-bottom: 0px solid; }

@media screen and (min-width: 800px) {
  .accordion-item.active-item {
    border-bottom: 0px solid; }
  .accordion-item-control.active-control + .tab-item {
    visibility: visible;
    max-height: 3000px;
    padding: .3rem .6rem 1.111rem; } }

@media screen and (max-width: 800px) {
  .tab-item.active-item {
    padding: 2rem 0; } }

.accordion-item-control span.clear {
  display: block;
  clear: both; }

/*** Start tabs.scss ***/
/* Start Blue Tabbed View Tiles */
.tabs.packed .nav-pills, .tabs.packed .tab-content {
  background: #f5f5f5; }

.tabs.packed .tab-content {
  padding-bottom: 30px; }
  @media (min-width: 801px) {
    .tabs.packed .tab-content {
      padding-left: 8.33333%;
      padding-right: 8.33333%;
      padding-bottom: 60px; } }

@media (max-width: 800px) {
  .tabs.packed {
    margin-left: -15px;
    margin-right: -15px; }
    .tabs.packed .tab-content {
      padding-left: 15px;
      padding-right: 15px; } }

.tabs.packed .nav-pills li {
  margin: 0;
  border: 0; }

.nav-pills {
  display: flex; }
  .nav-pills li {
    flex: 1;
    display: flex;
    background: #DCDCDC;
    border-bottom: 0px solid; }
    .nav-pills li a {
      font-weight: 600;
      padding: 20px 8px;
      line-height: 1.3;
      color: #2d2d2d;
      border-radius: 0;
      margin-bottom: 0;
      text-transform: capitalize;
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      border-bottom: 0px; }
    .nav-pills li.active {
      position: relative; }
      .nav-pills li.active a, .nav-pills li.active a:focus, .nav-pills li.active a:active, .nav-pills li.active a:hover {
        background: #00609A; }
      .nav-pills li.active:after {
        content: '';
        width: 30px;
        height: 30px;
        background: #00609A;
        position: absolute;
        bottom: -15px;
        transform: rotate(45deg);
        margin-left: -15px;
        left: 50%; }
  .nav-pills li + li {
    margin: 0; }
  .nav-pills li:nth-of-type(1) {
    border-right: 1px solid #FFF; }
  .nav-pills li:nth-of-type(3), .nav-pills li:nth-of-type(4) {
    border-left: 1px solid #FFF; }

.tab-content {
  padding-top: 60px; }

.tab-pane {
  padding: 0 8px; }
  .tab-pane h5 {
    margin-bottom: 30px; }

.tab-label {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
  display: block; }
  @media (max-width: 479px) {
    .tab-label {
      font-size: 16px; } }

@media (max-width: 800px) {
  .mobile-tabs .text {
    text-align: left; } }

/** Start Colored Tabbed Tiles */
.tab-wrapper {
  margin: 20px 0 30px; }
  .tab-wrapper ul {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center; }
  .tab-wrapper li {
    list-style: none;
    display: inline-block;
    width: 20%; }
  .tab-wrapper .tab-tile {
    border-right: 1px solid white;
    background-color: #eee;
    position: relative;
    cursor: pointer; }
    .tab-wrapper .tab-tile a, .tab-wrapper .tab-tile a:hover {
      color: #333; }
    .tab-wrapper .tab-tile h6 {
      position: relative;
      height: 130px; }
      .tab-wrapper .tab-tile h6 span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center; }
        .tab-wrapper .tab-tile h6 span.fa {
          color: black;
          font-size: 2rem; }
        @media screen and (min-width: 800px) {
          .tab-wrapper .tab-tile h6 span.fa {
            visibility: hidden; } }
    .tab-wrapper .tab-tile.active {
      cursor: default; }
    .tab-wrapper .tab-tile:nth-child(n):before {
      display: block;
      content: " ";
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      transition: top 250ms ease;
      z-index: 0; }
    .tab-wrapper .tab-tile:nth-child(n).active span {
      padding-top: 30px;
      color: white;
      transition: padding-top 230ms ease-in; }
    .tab-wrapper .tab-tile:nth-child(n).active:before {
      top: 0; }
    .tab-wrapper .tab-tile:nth-child(n).active:after {
      display: block;
      content: " ";
      position: absolute;
      border-style: solid;
      border-width: 25px 25px 0 25px;
      left: 50%;
      transform: translateX(-50%); }
    .tab-wrapper .tab-tile:nth-child(5n+1) h6 {
      border-bottom: 30px solid #0079C2; }
    .tab-wrapper .tab-tile:nth-child(5n+1).active:after {
      border-color: #0079C2 transparent transparent transparent; }
    .tab-wrapper .tab-tile:nth-child(5n+1):before {
      background-color: #0079C2; }
    .tab-wrapper .tab-tile:nth-child(5n+2) h6 {
      border-bottom: 30px solid #00609A; }
    .tab-wrapper .tab-tile:nth-child(5n+2).active:after {
      border-color: #00609A transparent transparent transparent; }
    .tab-wrapper .tab-tile:nth-child(5n+2):before {
      background-color: #00609A; }
    .tab-wrapper .tab-tile:nth-child(5n+3) h6 {
      border-bottom: 30px solid #683c7a; }
    .tab-wrapper .tab-tile:nth-child(5n+3).active:after {
      border-color: #683c7a transparent transparent transparent; }
    .tab-wrapper .tab-tile:nth-child(5n+3):before {
      background-color: #683c7a; }
    .tab-wrapper .tab-tile:nth-child(5n+4) h6 {
      border-bottom: 30px solid #a21851; }
    .tab-wrapper .tab-tile:nth-child(5n+4).active:after {
      border-color: #a21851 transparent transparent transparent; }
    .tab-wrapper .tab-tile:nth-child(5n+4):before {
      background-color: #a21851; }
    .tab-wrapper .tab-tile:nth-child(5n+5) h6 {
      border-bottom: 30px solid #cc0033; }
    .tab-wrapper .tab-tile:nth-child(5n+5).active:after {
      border-color: #cc0033 transparent transparent transparent; }
    .tab-wrapper .tab-tile:nth-child(5n+5):before {
      background-color: #cc0033; }
  .tab-wrapper .tab-tile-label {
    display: none; }
    @media screen and (min-width: 801px) {
      .tab-wrapper .tab-tile-label {
        display: inline-block; } }
  .tab-wrapper .tab-body {
    display: none;
    margin-top: 40px; }
    .tab-wrapper .tab-body.active, .tab-wrapper .tab-body:first-of-type {
      display: block; }
    .tab-wrapper .tab-body.inactive {
      display: none; }
    .tab-wrapper .tab-body h5 {
      font-size: 2rem; }
      @media screen and (min-width: 801px) {
        .tab-wrapper .tab-body h5 {
          display: none; } }
    .tab-wrapper .tab-body div {
      font-size: 1rem; }

.tabs.teal .tab-content {
  padding-top: 30px; }
  .tabs.teal .tab-content .tab-pane {
    padding: 0px; }

.tabs.teal ul.nav-pills {
  border-bottom: 1px solid #A3A3A3;
  border-bottom: 1px solid #A3A3A3; }
  .tabs.teal ul.nav-pills li {
    background: transparent;
    align-items: stretch;
    justify-content: center; }
    .tabs.teal ul.nav-pills li a {
      background: transparent;
      color: #0079C2;
      font-weight: 400; }
    .tabs.teal ul.nav-pills li.active {
      background: transparent; }
      .tabs.teal ul.nav-pills li.active:after {
        content: none; }
      .tabs.teal ul.nav-pills li.active a {
        color: #0079C2;
        flex: initial; }
        .tabs.teal ul.nav-pills li.active a:after {
          height: 4px;
          position: absolute;
          width: 100%;
          bottom: 0px;
          background-color: #06A59D;
          content: " ";
          left: 0;
          right: 0; }

/**** Tab Tiles override for blue tabs instead of multicolored tabs ****/
[data-uxd-tabbed-tiles-cmp].tab-wrapper .ant-blue-tabs .tab-tile h6,
[data-uxd-tabbed-tiles-cmp].tab-wrapper .ant-blue-tabs .tab-tile.active h6 {
  border-color: #00609A; }

[data-uxd-tabbed-tiles-cmp].tab-wrapper .ant-blue-tabs .tab-tile.active:before,
[data-uxd-tabbed-tiles-cmp].tab-wrapper .ant-blue-tabs .tab-tile:before {
  background-color: #00609A; }

[data-uxd-tabbed-tiles-cmp].tab-wrapper .ant-blue-tabs .tab-tile.active:after {
  border-color: #00609A transparent transparent transparent; }

/*** Start toggle.scss ***/
/** Toggle Component **/
.pfToggleB .ptLabel {
  display: inline-block;
  background: #FFF;
  width: 40px;
  border-radius: 20px;
  border: 1px solid #666666;
  box-shadow: inset 1px 1px 3px #DCDCDC;
  height: 24px;
  position: relative;
  left: 220px;
  cursor: pointer; }
  .pfToggleB .ptLabel span {
    position: relative;
    top: -24px;
    display: inline-block;
    width: 200px;
    left: -200px; }
  .pfToggleB .ptLabel:before {
    content: " ";
    display: block;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    border: 1px solid #666666;
    margin: -1px;
    transition: margin .15s;
    background: #EEEEEE;
    /* Old browsers */
    background: -moz-radial-gradient(center, ellipse cover, #666666 25%, #FFF 30%);
    /* FF3.6+ */
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(25%, #666666), color-stop(30%, #ffffff));
    /* Chrome,Safari4+ */
    background: -webkit-radial-gradient(center, ellipse cover, #666666 25%, #FFF 30%);
    /* Chrome10+,Safari5.1+ */
    background: -o-radial-gradient(center, ellipse cover, #666666 25%, #FFF 30%);
    /* Opera 12+ */
    background: -ms-radial-gradient(center, ellipse cover, #666666 25%, #FFF 30%);
    /* IE10+ */
    background: radial-gradient(ellipse at center, #666666 25%, #FFF 30%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#444444', endColorstr='#ffffff',GradientType=1 );
    /* IE6-9 fallback on horizontal gradient */
    box-shadow: inset 0px -1px 3px #DCDCDC; }

.pfToggleB .ptOption + .ptLabel.active {
  background-color: #0079C2;
  box-shadow: inset 1px 1px 3px #666666; }

.pfToggleB .ptOption + .ptLabel.focus {
  box-shadow: 0 0 5px #0079C2; }

.pfToggleB .ptOption + .ptLabel.active:before {
  margin: -1px 15px; }

.pfToggleB .optionWrapper {
  margin: 20px 0px;
  overflow: hidden;
  height: 35px;
  padding-top: 3px; }

.pfToggleB .ptOption {
  position: absolute;
  left: -9999px; }

.ptLabel.ptDisabled {
  background-color: #dadada !important;
  cursor: not-allowed !important; }

/** On/Off Switch Component **/
.pfToggleSwitch .ptOption {
  position: absolute;
  left: -9999px; }

.pfToggleSwitch .ptLabel {
  text-align: center;
  border: 1px solid #000;
  border-right: 0px;
  font-weight: 400;
  margin-bottom: 15px;
  float: left;
  padding: 2px 12px 1px;
  font-size: .9rem;
  cursor: pointer; }

.pfToggleSwitch .optionWrapper:first-of-type .ptLabel {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; }

.pfToggleSwitch .optionWrapper:last-of-type .ptLabel {
  border-right: 1px solid #000;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }

.pfToggleSwitch .ptLabel.active,
.pfToggleSwitch .ptLabel.focus {
  background-color: #0079C2;
  color: #FFF;
  cursor: default; }

.pfToggleSwitch .ptLabel.focus {
  box-shadow: 0 0 3px #00609A; }

/*** Start tooltip.scss ***/
.popover {
  min-width: 150px;
  box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.15);
  border: 0px solid transparent; }
  .popover .popover-close {
    position: relative;
    top: -10px;
    right: -10px;
    box-shadow: 8px 5px 5px -5px #d0d0d0;
    float: right;
    z-index: 2;
    border-radius: 40px;
    height: 25px;
    width: 25px;
    background-color: #FFF;
    padding: 0px; }
    .popover .popover-close a {
      text-decoration: none;
      background-color: #FFF;
      color: #002E58;
      display: inline-block;
      border-radius: 20px;
      font-size: 14px;
      z-index: 2; }
      .popover .popover-close a:focus {
        box-shadow: 0px 0px 4px 1px #0079C2;
        outline: none; }
  .popover .right, .popover .left, .popover .top, .popover .bottom {
    border-radius: 0px;
    border: none;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
    z-index: 1; }
  .popover .right {
    margin-left: 5px; }
    .popover .right > .popover-indicator {
      top: 45%;
      left: 0%;
      transform: rotate(45deg); }
  .popover .bottom {
    margin-top: 5px; }
    .popover .bottom > .popover-indicator {
      top: 0%;
      left: 50%;
      transform: rotate(135deg); }
  .popover .left {
    margin-left: -5px; }
    .popover .left > .popover-indicator {
      top: 45%;
      left: 100%;
      transform: rotate(-135deg); }
  .popover .top {
    margin-top: -15px; }
  .popover > .popover-indicator {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    bottom: -1em;
    box-sizing: border-box;
    border: 0.5em solid #FFF;
    box-shadow: -2px 5px 4px -1px rgba(0, 0, 0, 0.1);
    transform-origin: 0 0;
    z-index: 1;
    left: 50%;
    transform: rotate(-45deg); }

input + .popover {
  margin-top: -25px; }
  input + .popover > .popover-indicator {
    left: 77%;
    border: .9em solid #fff;
    bottom: -1.7em; }

.popover-content.error {
  color: #E82D05; }

/*bootstrap tooltip end*/
/*** Form Help Tooltip ***/
#helpForm input {
  display: block;
  clear: both; }
  #helpForm input:focus {
    border: 8px solid #f2c57a;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin: -8px -6px -4px -8px;
    outline: none; }

#helpContainer {
  display: inline; }
  #helpContainer .popover.bottom {
    margin-top: 0px;
    margin-left: 39px;
    opacity: 1.0;
    border: 0px solid transparent;
    border-radius: 6px;
    box-shadow: none;
    padding: 0px; }
  #helpContainer .popover-content {
    color: #444;
    font-size: 14px;
    background-color: #f2c57a;
    text-align: left;
    padding: 10px;
    border: 0px solid transparent;
    border-radius: 6px;
    border-top-left-radius: 0px;
    outline: 0px; }

#helpContainer.popover > .popover-indicator {
  border: solid 0px #FFF; }

/* Tool Tips */
input.popoverTrigger {
  float: left; }

.popover .popover-close {
  position: relative;
  top: -10px;
  right: -10px;
  box-shadow: 8px 5px 5px -5px rgba(0, 0, 0, 0.1);
  float: right;
  z-index: 2;
  border-radius: 40px;
  height: 25px;
  width: 25px;
  background: #FFF;
  padding: 0px;
  text-align: center; }
  .popover .popover-close a {
    text-decoration: none;
    color: #002E58;
    display: inline-block;
    top: 0px;
    left: 0px;
    position: relative;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    border-bottom: none; }
    .popover .popover-close a .fa {
      line-height: 25px; }
    .popover .popover-close a:focus {
      box-shadow: 0px 0px 4px 1px #0079C2;
      outline: none; }

.popover.right, .popover.left, .popover.top, .popover.bottom {
  border-radius: 0px;
  border: none;
  box-shadow: 2px 2px 4px 2px #d0d0d0;
  z-index: 1; }

.popover.right {
  margin-left: 5px; }

.popover.bottom {
  margin-top: 5px; }

.popover.left {
  margin-left: -5px; }

.popover.top {
  margin: -15px 0px 0 -10px; }
  .popover.top > .popover-indicator {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    bottom: -1em;
    left: 50%;
    box-sizing: border-box;
    border: 0.5em solid #FFF;
    box-shadow: -2px 5px 4px -1px rgba(0, 0, 0, 0.1);
    transform-origin: 0 0;
    transform: rotate(-45deg);
    z-index: 1; }

.popContainer {
  display: inline;
  z-index: 20;
  position: relative; }

.iconTip {
  margin-right: 10px; }

input + .popIcon {
  top: 2px;
  position: relative; }

.popIcon {
  display: inline-block;
  margin-left: 5px;
  border-bottom: 0px;
  text-indent: 1px;
  padding-right: 2px; }
  .popIcon .glyphicon,
  .popIcon .fa {
    color: #FE5E3C;
    font-size: 1.4em;
    top: 5px; }
    .popIcon .glyphicon:hover, .popIcon .glyphicon:focus,
    .popIcon .fa:hover,
    .popIcon .fa:focus {
      color: #E82D05; }

/* Progress Bars */
.progress {
  border-radius: 0px;
  height: 30px;
  color: #444;
  font-size: 24px;
  border-bottom: 0px solid;
  box-shadow: none;
  margin-bottom: 12px;
  font-weight: 500; }
  .progress .complete {
    background: #ffc03f;
    color: #444;
    font-size: 20px;
    padding-top: 4px;
    padding-left: 6px;
    text-align: left;
    box-shadow: none; }
  .progress .remaining {
    background: #fedf9f;
    color: #444;
    font-size: 20px;
    padding-top: 4px;
    padding-right: 6px;
    text-align: right;
    box-shadow: none; }

.popover.bottom {
  margin-top: 20px; }
  .popover.bottom > .popover-indicator {
    top: 0px;
    box-shadow: 2px -2px 4px 0px rgba(0, 0, 0, 0.1); }

span.ant-info-icon.fa {
  font-size: 1.571rem;
  position: relative;
  top: 5px;
  left: -32px;
  display: block;
  float: left;
  color: #0079C2;
  z-index: -1;
  width: 0px; }

.ant-text-input.ant-has-notes,
.ant-text-input.ant-has-notes:focus {
  padding-right: 40px;
  z-index: 2;
  position: relative;
  background: transparent;
  float: left; }

a.glossary-term, a .glossary-term {
  border-bottom: 1px dashed; }

/*Styles for indicator as a psuedo element (for tooltips using jQuery version of tooltip)*/
.popover:not(.ant-tooltip-component) .right:before {
  top: 45%;
  left: 0%;
  transform: rotate(45deg); }

.popover:not(.ant-tooltip-component) .bottom:before {
  top: 0%;
  left: 50%;
  transform: rotate(135deg); }

.popover:not(.ant-tooltip-component) .left:before {
  top: 45%;
  left: 100%;
  transform: rotate(-135deg); }

.popover:not(.ant-tooltip-component):before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  bottom: -1em;
  box-sizing: border-box;
  border: 0.5em solid #FFF;
  box-shadow: -2px 5px 4px -1px rgba(0, 0, 0, 0.1);
  transform-origin: 0 0;
  z-index: 1;
  left: 50%;
  transform: rotate(-45deg); }

.popover:not(.ant-tooltip-component) .top:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  bottom: -1em;
  left: 50%;
  box-sizing: border-box;
  border: 0.5em solid #FFF;
  box-shadow: -2px 5px 4px -1px rgba(0, 0, 0, 0.1);
  transform-origin: 0 0;
  transform: rotate(-45deg);
  z-index: 1; }

.popover:not(.ant-tooltip-component) .bottom:before {
  top: 0px;
  box-shadow: 2px -2px 4px 0px rgba(0, 0, 0, 0.1); }

input + .popover:not(.ant-tooltip-component):before {
  left: 77%;
  border: .9em solid #fff;
  bottom: -1.7em; }

#helpContainer.popover:not(.ant-tooltip-component):before {
  border: solid 0px #FFF; }

/*** Start progress-bar.scss ***/
fieldset.ant-progress-bar-cmp .ant-progress-bar-full {
  height: 20px;
  margin-bottom: 20px;
  background-color: #127abe;
  border-radius: 10px;
  /*-webkit-border-top-right-radius: 10px;
      -webkit-border-bottom-right-radius: 10px;
      -moz-border-radius-topright: 10px;
      -moz-border-radius-bottomright: 10px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;*/
  box-shadow: inset 0 1px 2px transparent; }

fieldset.ant-progress-bar-cmp .ant-progress-bar {
  border-right: solid 2px #fff;
  float: left;
  width: 0;
  height: 100%;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  /*border-radius: 10px; Save for later*/
  -webkit-box-shadow: inset 0 -1px 0 transparent;
  box-shadow: inset 0 -1px 0 transparent;
  -webkit-transition: width .6s ease;
  -o-transition: width .6s ease;
  transition: width .6s ease; }
  fieldset.ant-progress-bar-cmp .ant-progress-bar .ant-value-info {
    color: #127abe;
    font-size: 12px;
    position: relative;
    left: 100%;
    transform: translate(-50%);
    top: -22px;
    text-align: center;
    width: 80px; }
    @media screen and (min-width: 801px) {
      fieldset.ant-progress-bar-cmp .ant-progress-bar .ant-value-info {
        font-size: 14px; } }
    fieldset.ant-progress-bar-cmp .ant-progress-bar .ant-value-info .ant-progress-saved {
      font-weight: bold; }
    fieldset.ant-progress-bar-cmp .ant-progress-bar .ant-value-info .ant-progress-days {
      color: #000000;
      margin-top: 25px; }

fieldset.ant-progress-bar-cmp .ant-progress-bar:first-of-type {
  -webkit-border-top-left-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px; }

fieldset.ant-progress-bar-cmp .ant-value-yearly {
  color: #127abe;
  float: right;
  font-size: 12px;
  position: relative;
  right: 10px;
  top: -22px;
  text-align: center;
  width: 100px; }
  @media screen and (min-width: 801px) {
    fieldset.ant-progress-bar-cmp .ant-value-yearly {
      font-size: 14px; } }
  fieldset.ant-progress-bar-cmp .ant-value-yearly .ant-progress-saved {
    color: #127abe;
    font-weight: bold; }
  fieldset.ant-progress-bar-cmp .ant-value-yearly .ant-progress-days {
    color: #000000;
    margin-top: 25px; }

fieldset.ant-progress-bar-cmp .ant-progress-bar-thirty {
  width: 14%;
  position: relative;
  z-index: 10; }
  @media screen and (min-width: 801px) {
    fieldset.ant-progress-bar-cmp .ant-progress-bar-thirty {
      width: 8%; } }

fieldset.ant-progress-bar-cmp .ant-progress-disclaimer {
  margin-top: 45px;
  text-align: center;
  margin-bottom: 45px; }
  fieldset.ant-progress-bar-cmp .ant-progress-disclaimer p {
    font-style: italic; }

fieldset.ant-progress-bar-cmp div .carousel-selector-cmp .carousel-content-container ul .default-select-item-container {
  background-color: transparent;
  margin: 0px;
  min-height: 44px;
  padding: 0px;
  /*-- Button Colors Start --*/
  /*-- Button Colors End --*/ }
  fieldset.ant-progress-bar-cmp div .carousel-selector-cmp .carousel-content-container ul .default-select-item-container label {
    color: #127abe; }
    fieldset.ant-progress-bar-cmp div .carousel-selector-cmp .carousel-content-container ul .default-select-item-container label.active {
      background-color: #127abe;
      color: #fff; }
    fieldset.ant-progress-bar-cmp div .carousel-selector-cmp .carousel-content-container ul .default-select-item-container label:hover {
      background-color: #127abe;
      color: #fff; }
  fieldset.ant-progress-bar-cmp div .carousel-selector-cmp .carousel-content-container ul .default-select-item-container li:focus label {
    color: #fff; }
  fieldset.ant-progress-bar-cmp div .carousel-selector-cmp .carousel-content-container ul .default-select-item-container .select-item {
    float: left;
    left: 0px;
    margin: 0px;
    padding: 0px;
    position: relative;
    top: 0px;
    transform: none;
    width: 100%; }

fieldset.ant-progress-bar-cmp .care-gap-buttons label {
  background-color: #ffffff;
  border: solid 1px #127abe;
  border-radius: 0px;
  color: #666666;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  margin-left: 0px;
  margin-right: 0px;
  overflow: hidden;
  padding: 3px 15px;
  position: relative;
  text-overflow: ellipsis;
  width: 120px;
  white-space: nowrap; }
  fieldset.ant-progress-bar-cmp .care-gap-buttons label:before {
    display: none; }

@media screen and (min-width: 320px) and (max-width: 350px) {
  fieldset.ant-progress-bar-cmp .care-gap-buttons label {
    width: 105px; } }

@media screen and (min-width: 801px) and (max-width: 1024px) {
  fieldset.ant-progress-bar-cmp .care-gap-buttons label {
    width: 105px; } }

fieldset .current-drug-text,
fieldset .alternate-drug-text {
  font-size: 22px; }

fieldset .current-drug-label,
fieldset .alternate-drug-label {
  font-size: 22px;
  font-weight: 600;
  margin-top: 10px; }

fieldset .price-brand,
fieldset .price-generic {
  font-size: 40px;
  font-weight: 600; }

fieldset .price-brand {
  color: #06a59d; }

fieldset .guarantee {
  font-size: 16px;
  font-style: italic; }

fieldset .divider {
  border-left: 0.5px solid grey; }

fieldset .price-generic {
  color: #127abe; }

.mbr-top-of-page-wrapper {
  height: auto; }
  .mbr-top-of-page-wrapper.ant-new-nav-wrapper {
    /* Non-Menu Items START */
    /* Non-Menu Items END */
    /* Hamberger Menu Bars START */
    /* Hamberger Menu Bars END */ }
    .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-logo-wrapper a img {
      padding: 19px 0;
      margin: 0; }
      @media screen and (min-width: 801px) {
        .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-logo-wrapper a img {
          padding: 17px 0; } }
      @media screen and (min-width: 1024px) {
        .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-logo-wrapper a img {
          padding: 22px 0; } }
    .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-task-bar-search-content {
      border-bottom: solid 1px #dcdcdc; }
      @media screen and (min-width: 801px) {
        .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-task-bar-search-content {
          float: right;
          height: 30px;
          position: relative;
          top: 15px;
          width: 250px; } }
      @media screen and (min-width: 1024px) {
        .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-task-bar-search-content {
          top: 30px; } }
      .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-task-bar-search-content input {
        border: 0;
        color: #666;
        -webkit-box-shadow: inset 0 0 0 transparent;
        box-shadow: inset 0 0 0 transparent;
        font-size: 14px;
        position: relative;
        top: -5px;
        left: -10px; }
        @media screen and (min-width: 801px) {
          .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-task-bar-search-content input {
            top: -2px; } }
      .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-task-bar-search-content span.fa {
        color: #666;
        float: right;
        position: relative;
        top: -25px;
        transform: rotateY(180deg); }
    .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-spanish-link a {
      border: 0;
      color: #333;
      font-size: 12px; }
    .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-top-wrapper {
      background-color: #fff;
      height: auto; }
      @media screen and (min-width: 801px) {
        .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-top-wrapper {
          position: relative;
          z-index: 5; } }
      .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-top-wrapper .ant-spanish-link {
        margin-top: 22px; }
        @media screen and (min-width: 801px) {
          .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-top-wrapper .ant-spanish-link {
            margin-top: 5px; } }
      @media screen and (min-width: 801px) {
        .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-top-wrapper .ant-top-nav ul {
          display: flex;
          flex-direction: row;
          justify-content: left; } }
      .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-top-wrapper .ant-top-nav ul li {
        cursor: pointer; }
    .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-menu-button {
      margin-top: 12px;
      height: 40px;
      overflow: hidden; }
      .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-menu-button .ant-menu-toggle {
        margin: 0; }
        .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-menu-button .ant-menu-toggle:hover {
          margin: 0; }
        .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-menu-button .ant-menu-toggle:focus {
          margin: 0; }
        .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-menu-button .ant-menu-toggle .ant-menu-icon {
          background-color: #333; }
          .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-menu-button .ant-menu-toggle .ant-menu-icon:before {
            background-color: #333; }
          .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-menu-button .ant-menu-toggle .ant-menu-icon:after {
            background-color: #333; }
    .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-main-nav {
      padding-top: 15px; }
      @media screen and (min-width: 801px) {
        .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-main-nav {
          padding: 0; } }
      @media screen and (min-width: 801px) {
        .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-main-nav.menu-options {
          margin-left: -10px; } }
      @media screen and (min-width: 1024px) {
        .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-main-nav.menu-options {
          margin-left: -20px; } }
      .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-main-nav li .menu-contents {
        display: none; }
      .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-main-nav li.active {
        background-color: #0079c2; }
        .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-main-nav li.active .menu-contents {
          display: block; }
          @media screen and (min-width: 801px) {
            .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-main-nav li.active .menu-contents {
              width: 90%; } }
          @media screen and (min-width: 1024px) {
            .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-main-nav li.active .menu-contents {
              width: 80%; } }
      @media screen and (min-width: 801px) {
        .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-main-nav ul {
          margin: 0;
          padding: 0; } }
      .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-main-nav ul li {
        display: block;
        padding: 5px 0; }
        @media screen and (min-width: 801px) {
          .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-main-nav ul li {
            display: inline-block;
            margin: 0 0;
            padding: 5px 10px 7px; } }
        @media screen and (min-width: 1024px) {
          .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-main-nav ul li {
            margin: 0 0;
            padding: 5px 20px 7px; } }
        @media screen and (min-width: 801px) {
          .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-main-nav ul li:first-of-type {
            margin-left: 0; } }
        @media screen and (min-width: 801px) {
          .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-main-nav ul li:last-of-type {
            margin-right: 0; } }
        .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-main-nav ul li a {
          font-size: 16px;
          display: inline-block; }
          @media screen and (min-width: 801px) {
            .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-main-nav ul li a {
              color: #333;
              border: 0;
              font-size: 12px;
              padding: 5px 0; } }
          .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-main-nav ul li a:hover {
            color: #0079c2; }
          @media screen and (min-width: 801px) {
            .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-main-nav ul li a:after {
              content: " ";
              width: 0; } }
        .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-main-nav ul li.active > a {
          background-color: #0079c2;
          color: #fff;
          position: relative; }
          @media screen and (min-width: 801px) {
            .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-main-nav ul li.active > a:after {
              content: " ";
              border-bottom: solid 3px #fff;
              position: absolute;
              bottom: -3px;
              height: 3px;
              width: 100%;
              left: 0;
              transition: width 1.5s ease-in-out; } }
    @media screen and (min-width: 801px) {
      .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-navigation-wrapper {
        height: 0;
        position: relative;
        top: -450px;
        transition: top 1.5s;
        -webkit-transition: top 1.5s;
        z-index: 1; } }

/* MENU VISIBLE START */
.ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper {
  background-color: transparent; }
  .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper {
    height: auto;
    /* Non-Menu Items START */
    /* Non-Menu Items END */ }
    .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-top-wrapper {
      background-color: #fff !important; }
    .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-logo-wrapper-col {
      display: block;
      visibility: visible; }
    .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-logo-wrapper {
      opacity: 1; }
    .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-task-bar-search-content {
      margin: 0 15px; }
      @media screen and (min-width: 801px) {
        .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-task-bar-search-content {
          margin: 0; } }
    .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-top-nav {
      background-color: #fff; }
    .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper a .ant-menu-icon {
      background-color: transparent; }
      @media screen and (min-width: 801px) {
        .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper a .ant-menu-icon {
          color: #0079c2; } }
    .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-navigation-wrapper {
      background-color: #fff;
      padding: 15px 0;
      width: 100%;
      height: auto; }
      @media screen and (min-width: 801px) {
        .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-navigation-wrapper {
          background-color: #0079c2;
          top: 0;
          transition: top 1.5s;
          -webkit-transition: top 1.5s; } }
      @media screen and (min-width: 801px) {
        .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-navigation-wrapper .ant-nav-col {
          width: 25%; } }
      @media screen and (min-width: 1024px) {
        .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-navigation-wrapper .ant-nav-col {
          margin-right: 39px;
          width: 21%; } }
      @media screen and (min-width: 1200px) {
        .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-navigation-wrapper .ant-nav-col {
          margin-right: 60px;
          width: 21%; } }
      @media screen and (min-width: 801px) {
        .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-navigation-wrapper .ant-nav-col:first-of-type {
          width: 40%; } }
      @media screen and (min-width: 1024px) {
        .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-navigation-wrapper .ant-nav-col:first-of-type {
          margin-right: 70px;
          width: 34%; } }
      @media screen and (min-width: 1200px) {
        .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-navigation-wrapper .ant-nav-col:first-of-type {
          margin-right: 109px;
          width: 32%; } }
      @media screen and (min-width: 801px) {
        .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-navigation-wrapper .ant-nav-col:last-of-type {
          width: 35%; } }
      @media screen and (min-width: 1024px) {
        .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-navigation-wrapper .ant-nav-col:last-of-type {
          margin: 0;
          width: 31%; } }
      @media screen and (min-width: 1200px) {
        .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-navigation-wrapper .ant-nav-col:last-of-type {
          margin: 0;
          width: 29%; } }
      .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-navigation-wrapper .ant-nav-col.ant-menu-expanded ul {
        padding-bottom: 0; }
      @media screen and (min-width: 801px) {
        .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-navigation-wrapper .ant-main-nav ul li {
          display: block;
          padding: 0; } }
      .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-navigation-wrapper .ant-main-nav ul li a {
        border: 0;
        color: #333;
        padding: 5px 15px; }
        @media screen and (min-width: 801px) {
          .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-navigation-wrapper .ant-main-nav ul li a:hover {
            color: #0079c2; } }
        .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-navigation-wrapper .ant-main-nav ul li a span.fa {
          color: #333;
          transition: transform 0.5s;
          -webkit-transition: transform 0.5s; }
      .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-navigation-wrapper .ant-main-nav ul li.active a {
        color: #fff; }
        .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-navigation-wrapper .ant-main-nav ul li.active a span.fa {
          color: #fff; }
      .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-navigation-wrapper .ant-main-nav ul li.active.ant-menu-expanded > a span.fa {
        transform: rotateX(180deg);
        transition: transform 0.5s;
        -webkit-transition: transform 0.5s; }
      @media screen and (min-width: 801px) {
        .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-navigation-wrapper .ant-main-nav ul li ul.menu-contents div.ant-landing-link {
          margin-bottom: 30px; } }
      .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-navigation-wrapper .ant-main-nav ul li ul.menu-contents div.ant-landing-link a {
        display: block;
        border-bottom: solid 1px rgba(255, 255, 255, 0.8);
        font-size: 16px;
        margin-left: 15px;
        margin-right: 15px;
        padding: 10px 0; }
      .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-navigation-wrapper .ant-main-nav ul li ul.menu-contents li.ant-menu-expanded a span.fa {
        transform: rotateX(180deg); }
      .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-navigation-wrapper .ant-main-nav ul li ul.menu-contents li a {
        font-weight: 600; }
        .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-navigation-wrapper .ant-main-nav ul li ul.menu-contents li a span.fa {
          transform: rotateX(0deg);
          transition: transform 0.5s;
          -webkit-transition: transform 0.5s; }
      .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-navigation-wrapper .ant-main-nav ul li ul.menu-contents li ul.ant-menu-column li {
        margin-left: 0;
        margin-right: 0;
        padding: 0; }
        .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-navigation-wrapper .ant-main-nav ul li ul.menu-contents li ul.ant-menu-column li a {
          background-color: transparent;
          display: block;
          font-weight: 400;
          font-size: 14px; }
          @media screen and (min-width: 1200px) {
            .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-navigation-wrapper .ant-main-nav ul li ul.menu-contents li ul.ant-menu-column li a {
              font-size: 16px; } }
          .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-navigation-wrapper .ant-main-nav ul li ul.menu-contents li ul.ant-menu-column li a:hover {
            background-color: rgba(255, 255, 255, 0.85);
            color: #0079c2; }
        .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-navigation-wrapper .ant-main-nav ul li ul.menu-contents li ul.ant-menu-column li ul {
          margin-left: 15px; }
          .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-navigation-wrapper .ant-main-nav ul li ul.menu-contents li ul.ant-menu-column li ul li a {
            padding-bottom: 3px;
            padding-top: 3px; }
            @media screen and (min-width: 801px) {
              .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-navigation-wrapper .ant-main-nav ul li ul.menu-contents li ul.ant-menu-column li ul li a {
                padding-bottom: 5px;
                padding-top: 5px; } }
      .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-navigation-wrapper .ant-nav-col {
        border: 0; }
        .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-navigation-wrapper .ant-nav-col .ant-menu-title {
          display: block;
          margin-bottom: 10px;
          margin-left: 15px; }
        .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-navigation-wrapper .ant-nav-col li {
          margin: 5px 0; }
          .ant-menu-visible .mbr-page-wrapper .mbr-top-of-page-wrapper.ant-new-nav-wrapper .ant-navigation-wrapper .ant-nav-col li li {
            margin: 0 0 0 15px; }

/* MENU VISIBLE END */
/* code below isnt working */
.mbr-page-wrapper .mbr-top-of-page-wrapper .ant-navigation-wrapper ul ul ul {
  margin: 0; }

.mbr-page-wrapper .mbr-top-of-page-wrapper .ant-navigation-wrapper ul ul.ant-menu-column {
  margin-top: 0; }
