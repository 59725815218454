@use "sass:math";
.border-bottom-lt{
  border-bottom:1px solid $medium-gray;
  padding-bottom:1rem;
}
.er-background-image{
  vertical-align: top;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 4rem 0;
  background-position: center;
  background-color:white;
  min-height:25rem;
}
.er-heading{
  margin-bottom:2rem;
  line-height: 2.5rem;
}
.container-fluid{
  background-color: $white;
  .crd{
    background-color: white;
    margin: 2rem 0;
    border-top: 4px solid $primary-color-light-blue;
    .border-bottom-lt{
      border-bottom:2px solid $medium-gray;
      padding-bottom:0;
    }
  }
}
//reset the ul's
.ul-reset{
  list-style: none;
  padding-left:0;
}

//replaced repeated spacing classes with a loop
$spacerDef: xs .75rem, sm 1.5rem, md 2.5rem;

@each $key in $spacerDef {
  $sizeVal: nth($key, 1);
  $value: nth($key, 2);

  .top-#{$sizeVal} {
    margin-top:$value;
  };
  .right-#{$sizeVal} {
    margin-right:$value;
  };
  .btm-#{$sizeVal} {
    margin-bottom:$value;
  };
  .left-#{$sizeVal} {
    margin-left:$value;
  };
}

// conditional expand collapse styles
.cond-collapse-heading{
  @extend .text-center;
  font-weight:300;
  font-size: 2.223rem;
  padding:2rem calc(1.8rem + 15px) 2rem 15px;
  background-color: $light-gray;
}

.nested-form .cond-collapse-heading{
  @extend .text-left;
  padding-left:0;
}
.fa-angle-wrapper {
  position: absolute;
  left: 0;
}

.gd-notes-slide-container,
.fa-angle-wrapper {
  .fa-angle-left {
      color: #fff;
      border-bottom: none;
      font-size: 2rem;
  }
}
// page headers
$arrowSize: 1.75rem;
.er-page-header {
  color:$base-color;
  padding:$sp2 0;
  justify-content: center;
  position: relative;


  .flex-item:first-child {
    padding-right:$sp1;
  }
  .ant-arrow-previous:before { color: $base-color; }
  a.er-fi { flex:0 1 $sph2; }
  h1.er-fi, .er-header-title {
    font-size: 40px;
    font-weight: 300;
    text-align: center; }
  .er-header-back {
    font-weight: 300;
    position: absolute;
    left:0;
    //IE fix for v-center carat
    @media all and (-ms-high-contrast:none) {
      transform: translateY(-($arrowSize - math.div($arrowSize, 4)));
    }
  }

}

// Override fat arrow with skinny arrow in headers
.er-page-header, .paper-slider-header {
  .ant-arrow-previous {
    font-family: 'FontAwesome' !important;
    color: $base-color;
    font-size:$arrowSize;
  }
  .ant-arrow-previous:before  {
    content: "\f111" !important;
  }
}

//Flex Direction classes
.er-flex-row {
  display: flex;
  flex-direction: row;
  &.j-center {
    justify-content: center;
  }
}
.er-flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.er-flex-column {
  display: flex;
  flex-direction: column;
  &.a-center {
    align-items: center;
  }
}
// prevent word wrapping
.no-wrap{
  white-space: nowrap;
}

.er-display-none {
  display: none;
}
.inline-text-input
.uxd-text-input{
  margin: -.75rem 0;
  label.uxd-text-input__label.is-focused{
    font-size:.7rem;
  }
  input[type="text"]{
    font-size:.8rem;
  }
}
//Group/Case Header
//.flex-item is deprecated please use .er-fi
.group-header {
  font-family: var(--font-sans-serif);
  font-size: 14px;
  border-top: 1px solid #cccccc;
  .er-flex {
    align-items: flex-start;
  }
  //added per a Design Review with David for switch link
  .group-switch-box {
    display: flex;
    flex-direction: column;
    @media screen and (max-width: $breakpoint-sm) {
        display: flex;
        flex-direction: row;
        .c-title {
          margin-right: 5px;
        }
    }
  }
  .switch-container {
    display: flex;
  .group-name {
    display: inline-block;
    min-width: 90px;
    overflow-wrap: break-word;
  }
  .group-switch {
    padding-left: 36px;
    @media screen and (min-width: $breakpoint-md) {
      padding-left: 72px;
    }
  }
  }
  @media screen and (max-width: $breakpoint-md) {
    .er-sub-header {
      flex-direction: column;
      .er-fi, .flex-item {
        &.header-item-switch {
          max-width: 100%;
        }
        &.header-item {
          max-width: 100%;
        }
      }
    }
  }
}
.update-id-form {
  label {
    display: none !important;
  }
  .uif-action-links {
    margin-top: 12px;
  }
  .uxd-error-message {
    font-size: 11px;
    margin-top: 3px;
    margin-left: 10px;
    &:before {
      font-size: 16px;
    }
  }
  .inline-text-input {
    margin-top: 12px;
  }
}
.er-sub-header {
  padding:$sp1 0;
  flex-wrap: wrap;
  .er-fi, .flex-item {
    flex:1 1 100%;
    .c-title { font-weight: 800; }
    .c-title-colon { display:inline; }
    &.header-sub-id{
      padding: 1.25rem 0 1.25rem 0;
      border-left: none;
      margin: -1rem 0;
    }
    &.header-item{
      flex:1 0 100%;
      max-width:inherit;
      padding-left: 7px;
      padding-right: 7px;
    }
    &.header-item-switch{
      flex:1 0 100%;
      padding-left: 7px;
      padding-right: 7px;
    }


    .btn.btn-primary.btn-billing { width:100%; }

  }


  //inline text input for change ID

  &.home .amount-due {
    text-align:center;
    margin-top:$sp1;
    padding:$sp1 0 $sp2;
    border-top:1px solid $gray-2;
    h5 {
      color:$neutral-dark-gray;
      font-size:$sp1+$sp2;
    }
  }
  @media screen and (min-width: $breakpoint-sm) {
    .er-fi, .flex-item {
      .c-title { display:block; }
      .c-title-colon { display:none; }
      &.c-num { flex:1 25%;}
      &.c-name { flex:1 75%; }
      &.header-item{
        flex:1 0 auto;
        max-width:30%;
      }
      &.header-item-switch{
        flex:1 0 auto;
        max-width:40%;
      }
      &.header-sub-id{
        padding: 1rem 0 1rem 1rem;
        border-left: 1px solid $gray-2;
        margin: -1rem 0;
      }
    }
    @media screen and (max-width: $breakpoint-md) {
      .er-fi, .flex-item {
        &.header-item {
          padding-bottom: .5rem;
        }
        &.header-item-switch {
          padding-bottom: .5rem;
        }
        &.header-sub-id {
          padding: 1rem 0 1rem 7px;
          border-left: none;
        }
      }
    }
      &.home {
      .er-fi, .flex-item {
        flex:1 1 auto;
        &.c-name { flex:2; }
      }
      .amount-due {
        border:none;
        margin:0;
        padding:0;
        h5 { font-size: 1.111rem; }
      }
    }
  }
  @media screen and (max-width: $breakpoint-md) {
    .aso-payment-button {
      margin-top: 20px;
    }
  }
}


//Teal Step Navigation
.step-navigation {
  background: $light-gray;
  margin:0;
  padding:0;

  &.er-flex {
    align-items: stretch;
    background-attachment: fixed;
  }

  li {
    list-style: none;
    flex-basis: 20%;
    align-items:center;
    position: relative;
    a, .steppa {
      text-decoration: none;
      border:none;
      color:$neutral-dark-gray;
      flex-basis: 100%;
      padding:$sp1 $sp05;
      @extend .body-sm;
      text-align: center;
      .fa { display:none; }
      &.er-flex {
        //ie fallback
        justify-content: center;
        justify-content: space-evenly;
      }
    }
    &:last-child { border:none; }
    &.active, &.completed {
      background: $accent-color-teal;
      a { color:$base-color; }
    }
    &.active {
      a, .fa { font-weight: 800; }
      .steppa { font-weight: 800; color:$base-color; }
    }
    .ez-sep, .step-sep{
      display:inline-block;
      flex:1 0 1px;
      background:$gray-2;
      height:50%;
    }

    @media screen and (min-width:$breakpoint-xs) {
      overflow:hidden;
      &.active, &.completed {
        a .fa, .steppa .fa {
          display:inline-block;
          color: $base-color;
        }
      }
      &.active:after {
        content:" ";
        position: absolute;
        $caretSize: 15px;
        height: $caretSize;
        width: $caretSize;
        transform: rotate(45deg);
        bottom: -( math.div($caretSize, 2)+1px );
        border-left: math.div($caretSize, 2)solid $base-color;
        border-top: math.div($caretSize, 2) solid $base-color;
        left:calc(50% - #{math.div($caretSize, 2)});
      }
    }
  }
}


//Custom Modals
.modal-component-footer {
  margin-top:$sp2;
  .btn-primary {
    margin-right:$sp1;
  }
}

//mocking 6 col form fields -enrollment flow
.form-section-wrapper{
  display:flex;
  display:-ms-flexbox;
  flex-flow:row wrap;
  .form-item{
    flex:1 1 auto;
    width:100%;
    padding:0 15px;
    .uxd-error-container{
      flex:1 1 100%;
    }
  }
  @media screen and (min-width:$breakpoint-sm) {
    .form-item{
      // flex:1 1 50%;
      width:50%;
      max-width:50%;
    }
  }
}
.alt-row{
  padding:1rem 15px;
  margin:0 -15px;
  &:nth-child(even){
    background-color:$light-gray;
  }
}

//Scroll Box Styles
.er-scroll-box {
  height:447px;
  border:1px solid $medium-gray;
  overflow-y:auto;
  padding:$sp1;
  margin-bottom:$sp2;
  -webkit-overflow-scrolling: touch;
  ul {
    padding-left: 1rem;
  }
}

.center-drop {
  width:100%;
  margin-top:$sp2;
  @media screen and (min-width: $breakpoint-sm) {
      max-width: $sph2*10;
      margin:$sp2 auto 0 auto;
  }
}

.er-max-center-input {
  max-width: 600px;
  margin:0 auto;
  .fwc-input {
    margin-bottom: 1rem;
    .uxd-error-container {
      text-align: left;
      span{
        margin: 0px;
      }
    }
  }
  .fwc-label {
    text-align: left;
  }
}
