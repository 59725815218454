.motif-styles {

  /* Box sizing rules */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /* Remove default padding */
  ul[class],
  ol[class] {
    padding: 0;
  }

  /* Remove default margin */
  body,
  p,
  ul[class],
  ol[class],
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  /* Set core body defaults */
  body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /* Remove list styles on ul, ol elements with a class attribute */
  ul[class],
  ol[class] {
    list-style: none;
  }

  /* A elements that don't have a class get default styles */
  a:not([class]) {
    text-decoration-skip-ink: auto;
    color: $color-primary;
    font-weight: 600;
  }

  a {
    color: $color-primary;
    font-weight: 600;
  }

  /* Make images easier to work with */
  img {
    max-width: 100%;
    display: block;
  }

  /* Natural flow and rhythm in articles by default */
  article > * + * {
    margin-top: 1em;
  }

  /* Inherit fonts for inputs and buttons */
  input,
  textarea,
  select {
    font: inherit;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    font-family: $font-sans-serif;
    font-size: $default-font-size;
    line-height: 1.5;
    color: $color-gray-one;
  }

  /*
   ### DO NOT USE px for spatial values, only use rems in 1/4 increments;
   ### 1rem is 8px;

   1rem = 8px;
   1.5rem = 12px;
   1.75rem = 14px;
   2rem = 16px;
   2.25rem = 18px;
   2.75rem = 22px;
   3rem = 24px;
   3.25rem = 26px;
   4rem = 32px;
   4.75rem = 38px;
  */


  h1, h2, h3 {
    font-family: $font-sans-serif;
  }

  h2, h4 {
    font-weight: 300;
  }

  h1, h3 {
    font-weight: 600;
  }

  h1 {
    font-size: calc(4.75 * #{$fwcu});
  }

  h2 {
    font-size: calc(3.75 * #{$fwcu});
  }

  h3 {
    font-size: calc(3.5 * #{$fwcu});
  }

  h4 {
    font-size: calc(2.75 * #{$fwcu});
  }

  h5 {
    font-size: calc(2.25 * #{$fwcu});
  }

  h6 {
    font-size: calc(2 * #{$fwcu});
  }

  h1, h2, h3, h4, h5, h6 {
    line-height: 1.5; /* 1.5 x font-size */
    margin: 0;
  }

  /* Mobile and Tablet only */
  @media screen and (max-width: 600px) and (hover: none) and (pointer: coarse) {
    h1 {
      font-size: calc(3.5 * #{$fwcu});
    }
    h2 {
      font-size: calc(2.75 * #{$fwcu});
    }
    h3 {
      font-size: calc(2.25 * #{$fwcu});
    }
    h4 {
      font-size: calc(2.75 * #{$fwcu});
    }
  }

  p, div, a, section, ul, ol, dl, figure, caption, nav, header, footer, main, table, tr, td {
    font-size: var(--default-font-size);
  }

  /* Only display content to screen readers
  // See: http://a11yproject.com/posts/how-to-hide-content/ */

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  /* Use in conjunction with .sr-only to only display content when it's focused.
  // Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
  // Credit: HTML5 Boilerplate */

  .sr-only-focusable:active, .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }

  /* iOS "clickable elements" fix for role="button"
  //
  // Fixes "clickability" issue (and more generally, the firing of events such as focus as well)
  // for traditionally non-focusable elements with role="button"
  // see https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile */

  [role="button"] {
    cursor: pointer;
  }


  /* Helper classes */

  .fwc-hide {
    display: none;
  }

  .no-scroll {
    overflow: hidden;
  }

  .sub-font-sm {
    font-size: 14px;
  }
  .sub-font-xs {
    font-size: 12px;
  }
  .italic {
    font-style: italic;
  }
  .text-align-center {
    text-align: center;
  }
//STYLE FOR SEARCH INPUT ON DASH. WILL UPDATE TO .fwc-input-search styles when dash is converted to Motif fully
  .fwc-search-input {
    position: relative;
    display: flex;
    border: solid 1px #979797;
    height: 40px;
    input {
      border: none;
      width: calc(100% - 38px);
      font-size: 14px;
      padding: 0 5px;
      border-radius: 0;
      box-shadow: none;
    }

    button {
      position: absolute;
      right: 2px;
      bottom: 1px;
      font-size: 18px;
      border: none;
      background-color: transparent;
      color: var(--color-gray-three);
      height: 36px;
      width: 27px;
      transform: scaleX(-1);
    }
  }

  .ant-ajax-loader {
    display: block;
    outline: 0 none;
    overflow: hidden;
    z-index: 2000;
    height: auto !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    position: fixed !important;
    top: 50% !important;
    padding: 15px;
    text-align: center;
    background-color: #FFF;
  }

  .fwc-bottom-border {
    border-bottom: 1px solid $color-gray-four;
  }
  .er-pagination {
    a {
      color: $color-white;
      &.active {
        color: #0079C2;
      }
    }
  }
  .searchSubscriber {
    .search-header {
      font-size: 38px;
      font-weight: normal;
    }
    .margin-horizontal {
      margin-left: 5%;
      margin-right: 5%;
    }
  }
  .er-motif-h1 {
    font-family: "Bitter";
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
  }
  .er-motif-h3 {
    font-family: "Bitter";
    font-size: calc(3* #{$fwcu});
    font-style: normal;
    font-weight: 500;
    color: $color-gray-one;
  }
  .er-motif-p {
    font-family: $font-family-Lato !important;
    font-size: 16px;
    font-weight: normal;
    line-height: 22px;
  }
  .er-motif-input {
    font-size: 14px !important;
    font-family: $font-family-Lato !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px #eaeaea;
  }
  .er-motif-label {
    font-size: 14px !important;
    font-family: $font-family-Lato !important;
  }
  .er-motif-mask {
    font-size: 1.5rem;
    top: 25px;
    right: 10px;
  }
  .er-motif-mask-wrapper {
    position: relative;
    width: max-content;
  }
}
.ant-content-wrapper {
  display: block;
  color: white;
  padding: 60px 14px 60px 14px;
  background-image: linear-gradient(to right, #58abdf 1%, #286ce2);
  .large-card {
    overflow: auto;
  }
}
