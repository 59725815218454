.container-fluid-login {
  position: relative;
  min-height: 120vh;
  background-color: #F7F7F7;
  margin-bottom: -4rem;
  margin-left: -15px;
  margin-right: -15px;
  background-image: url(../images/login_background_opt.jpg);
  background-size: cover;
  padding-bottom: 2rem;
}
