%icon-styles {
    height: 3px;
    width: 30px;
    position: relative;
    display: inline-block;
    transition-duration: 0.2s, 0.2s;
    transition-delay: 0.2s, 0s;
}
$transition-val: 0.2s ease;

.hundo {
    height:100%;
}

.logo-holder {
    a {
        border-bottom:none;
        //temp fix for ASO
        display: block;
        min-height: 71px;
    }
}

.hidden-nav {
    display:none;
    @media screen and (min-width:$breakpoint-sm) {
        display:block;
    }
}

.global-header-offset {
    margin-bottom:$header-offset;
    @media screen and (max-width:$breakpoint-xs) {
      margin-bottom: $header-offset-mobile;
    }
    .header-v-center {
        height:100%;
        flex-wrap: wrap;
        @media screen and (min-width:$breakpoint-sm) {
            &:not(.hidden-nav) { height:auto; }
        }
    }
}

.global-header-wrapper {
    position:fixed;
    top:0;
    width:100%;
    background-color:white;
    height:$header-offset;
    color:$primary-color-light-blue;
    align-content: center;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    transition: background-color $transition-val,color $transition-val;
    z-index:1000;
  @media screen and (max-width:$breakpoint-xs) {
    height: $header-offset-mobile;
  }
  .header-col-flex {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
//header redesign


//Hamburger Menu
.er-menu-button{
    font-size: 28px;

    .er-menu-icon-container {
        position: relative;
        margin-right: 0px;
        font-size: 2px;
        top: -5px;
        background-color: rgb(36, 83, 166);
        @extend %icon-styles;
        &:after{
            content:'';
            top:5px;
            background-color: rgb(36, 83, 166);
            @extend %icon-styles;
        }
        &:before{
            content:'';
            top:-9px;
            background-color: rgb(36, 83, 166);
            @extend %icon-styles;

        }
    }

    &.open {
        transition:color $transition-val;
        position: relative;
        z-index:1;

        .er-menu-icon-container{
            background-color:transparent;
            transition:background-color $transition-val;
            top: -8px;
            color:$white;

            &:after{
                top: 0px;
                transform: rotate(45deg);
                transition-property: transform, top;
                background-color: $white;
            }
            &:before{
                color:$white;
                top: 4px;
                transform: rotate(-45deg);
                transition-property: transform, top;
                background-color: $white;

            }
        }
    }
}

.er-menu-toggle{
    text-decoration: none;
    color:rgb(36, 83, 166);
    display: inline-block;
    border: 0px;
    font-weight: 400;
    transition:color $transition-val;
}

.er-menu-body {
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:100vh;
    overflow:hidden;
    .animation-wrapper{
        position:relative;
        top:-1000px;
        left:0;
    }
    &.active{
        padding-top:$header-offset;
        overflow:auto;
        top:0;
        left:0;
        right:0;
        bottom:0;
        background-color: rgb(36, 83, 166);
        .animation-wrapper{
            top:0;
            transition: top .2s ease .2s;

        }

    }
    .hamburger-list-row{
        display:flex;
        flex-flow:row wrap;
        .list-column:first-child:nth-last-child(2),
        .list-column:first-child:nth-last-child(2) ~ div {
        max-width: 50%;
        }
    }
    .list-column{

        flex:1 0 100%;
        border-left:none;
        padding:0;
        .menu-heading{
            font-size:1.5rem;
            margin-bottom:1rem;
            display:block;
            color:$white;
        }
        ul{
            list-style: none;
            padding:0;
            margin:0 0 3rem 0;
            li{
                margin-bottom:1rem;
                a{
                    color:$white;
                    border-bottom:none;

                }
            }
        }
        &:last-of-type{
            padding-right:0;
        }
        &:first-of-type{
            border-left:none;
            padding-left:0;
        }
    }
    .container-fluid{
        background-color: transparent;
    }
}

//Tabbed Navigation
.main-header-tabs {
    font-size:14px;
    ul { padding:0; }
    display: none;
    @media screen and (min-width: $breakpoint-sm) {
        display:block;
    }
  .menu-item {
    a {
      font-weight: normal;
    }
  }
}

.main-header-nav {
    list-style-type: none;
    margin-bottom: 0px;
    color: #333333;
    position: relative;

    &:focus { border:5px red dotted; }

    .menu-item {
        & > a {
            color:#333;
            text-decoration: none;
            border-bottom:none;
            padding:11px;
            font-size:16px;
            display:block;

            &:active  {
                color:$white;
                background: rgb(36, 83, 166);
                color: $white;
                & + .main-header-menu-wrapper {
                    display: block;
                }
            }
        }
        &.active {
           & > a {
                color:$white;
                background: rgb(36, 83, 166);
                color: $white;
            }
            .main-header-menu-wrapper { display:block; }
            .mc-nav-item-count.heading { display:none; }
        }
          .mc-nav-item-count.heading { margin-left: .5rem; }
    }
}

.header-fi-hamburger .menu-heading .mc-nav-item-count {display: none;}

.mc-nav-item-count {
    background: rgba(255,255,255,.85);
    padding: .25rem;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(255,255,255,0);
    color: $primary-color-light-blue;
    border-radius: 1rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: .6rem;
    height: 1rem;
    min-width: 1rem;
    &.heading {
    background-color: $accent-color-red;
    border: 1px solid $accent-color-red;
    color: white;
    }
    &.hamburger {
    position: absolute;
    z-index: 10;
    top: 0;
    // Matching width of the icon
    left: calc(30px - .5rem);
  }
  &.open {display:none;}
}

.main-header-menu-wrapper {
    width: 100%;
    position: absolute;
    left:0;
    top:45px;
    z-index: 1;
    max-width: 1200px;
    margin: 0 auto;
    box-shadow: 0 10px 10px 2px rgba(0,0,0,.2);
    background-color: rgb(36, 83, 166);
    color: $white;
    padding:1.5rem;
    display: none;

    a {  color: $white; }
    h5.menu-heading {
        padding-bottom:.5rem;
        margin-bottom:1.5rem;
        border-bottom: 1px solid rgba(255,255,255,.8);
        .mc-nav-item-count {display:none;}
    }
    ul > li {
        padding-bottom:.5rem;
        a {
            border:none;
            padding: .25rem;
            width: 50%;
            display: inline-block;
            color:$white;
            text-decoration: none;
            &:hover {
                background-color: rgba(255,255,255,.85);
                color: rgb(36, 83, 166);
                .mc-nav-item-count { background: none; }
            }
        }
    }

    .main-header-menu {
        padding:1rem;
    }
}

//Header Items
.header-fi-hamburger {
    position: relative;
    flex-grow:1;
    flex-shrink:1;
    flex-basis: 45px;
    max-width: 45px;
    @media screen and (min-width:$breakpoint-sm) {
         display: none;
    }
}
.header-fi-logo {
    flex-grow:1;
    flex-shrink:1;
    flex-basis: auto;
    &.fully {
    flex-basis: auto;
       padding-left:$sph2;
    }
}
.header-fi-quick {
    flex-grow:1;
    flex-shrink:1;
    flex-basis: auto;
    position: relative;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &.fully {
        flex-grow:0;
        flex-basis: $sph2;

        .quick-links .quick-sub-menu {
            width:400%;
        }
    }
}

@media screen and (min-width:$breakpoint-sm) {
    .header-fi-quick {
        flex-basis: 25%;
    }
    .header-fi-logo { flex-basis: 50%; }
}

// Quick/Popular Links
.quick-links {
    padding:0;
    position: relative;
    justify-content: space-around;
    @media screen and (min-width: 700px) {
      align-self: flex-end;
    }
  .header-task-btn {
    background: transparent;
    border: none;
    .fa {
      font-size: 30px;
    }
  }
  .qt-label {
    font-size: 10px;
  }
  .alert-count {
    font-size: 10px;
    color: #fff;
    background-color: #cc0033;
    width: 14px;
    height: 14px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 4px;
  }

    .quick-tasks {
      padding:0 $sph05;
      position: relative;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      &.qt-notifications {
        .alert-count {
          right: 18px;
        }
      }
    }
  .quick-account {
    padding:0 $sph05;
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

    .quick-btn {
        background:$primary-color-light-blue;
        justify-content: center;
        border:1px solid $primary-color-light-blue;
        border-radius: 50%;
        width:$sp2;
        height:$sp2;
        text-align:center;
        .fa {
            color:$base-color;
        }
        &.active {
            &, &:hover {
                background: $base-color;
                border-color: $primary-color-light-blue;
                .fa { color: $primary-color-light-blue; }
            }
        }
        .fa-th::before {
            // 3x3 solid grid icon
            content: "\e91e";
        }
    }

    .quick-sub-menu {
      h5 {
        font-size: 22px;
        font-weight: 300;
      }
        .quick-title {
            color:$neutral-dark-gray;
        }
        .preferences-pad {
          list-style: none;
          padding: 10px 0 0 20px;
        }
        width: max-content;
        min-width: 200px;
        max-width: 355px;
        transform: translate(-40%);
        position: absolute;
        top:calc(100% + 16px);
        z-index:1;
        background: $base-color;
        padding:$sp1;
        border:1px solid $light-gray;
        box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.08);
        text-align: left;
        li {
            margin-bottom:$sp05;
            &:last-child { margin-bottom:0; }
        }

        &:before {
            content: " ";
            position: absolute;
            height: 20px;
            width: 20px;
            transform: rotate(45deg);
            top: -10px;
            background-color: $base-color;
            right: auto;
            border-top: 1px solid $light-gray;
            border-left: 1px solid $light-gray;
        }
      .activity-container {
        .activity-entry {
          border-bottom: 1px solid #CCCCCC;
          padding-bottom: .5rem;
          font-size: 0.8571rem;
          &:last-of-type {
            border-bottom: none;
            padding-bottom: 0;
          }
        }
        .fa-angle-right {
          display: flex;
          align-items: center;
          font-size: 18px;
          color: #286CE2;
          border-bottom: none;
          text-decoration: none;
        }
        .errd-btn-link {
          color: #333333;
          border: none;
          padding: 0;
          background: none;
          text-decoration: none;
          text-align: left;
        }
      }
      @media screen and (max-width: $breakpoint-xs){
        max-width: 290px;
        transform: translate(0%);
      }
    }

    .quick-tasks .quick-sub-menu:before {
        left: calc(80% + 10px);

        @media screen and (max-width: $breakpoint-xs){
          left: calc(40% + 10px);
        }
    }
    .quick-account {
        .fa.fa-user.sm { transform: translateX(1px); } //icon slightly off centered natively
        .quick-sub-menu:before {
            left: calc(100% - 25px);
        }
    }
  .qt-notifications {
    .quick-sub-menu {
      left: 50%;
      @media screen and (max-width: $breakpoint-xs){
        left: 100% !important;
      }
    }
    .quick-sub-menu:before {
      left: calc(35% + 4px);
      @media screen and (max-width: $breakpoint-xs){
        left: calc(21% + 4px);
      }
    }
  }

   @media all and (-ms-high-contrast:none) {
        // way off in IE
       .quick-tasks span.fa  {
            transform: translateX(-3px);
        }
        .quick-account .fa.fa-user.sm {
            transform: translateX(-4px);
        }
   }
}
@media print {
  .global-header-wrapper  {
    position: absolute;
  }
}
