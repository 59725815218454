// FAQs
.faqs {
    .text-tabs-wrap .text-tabs  {
        border-top:1px solid $gray-2;
        & li {
            border-top:1px solid $gray-2;
            margin-top:-1px;
        }
    }
    .faq-item {
        padding:$sp05 0;
        border-bottom:$er-border;
        .faq-question {
            justify-content: space-between;
            button.er-fi { 
                flex:0 0 auto;
            }
            h5 { 
                font-weight: 400; 
                transition: all 500ms ease; 
                padding-right:$sp1;
            }
        }
        &.active h5 { font-weight: 700; }
        .faq-answer { 
            margin-bottom:0; 
            padding:$sp1 0;
        }
    }
}

.self-bill-faqs {
    @extend .faqs;
    .faq-item { 
        margin-bottom: $sp1;
        padding-bottom: $sp1;
        &:last-child { border: none; }
    }
}   

// Contact
.contact {
    .er-contact-title {
        padding:$sp2+$sp05 0 $sp2 0;
    }
    .er-contact-list {
        padding:$sp1 0;
        flex-wrap: wrap;
        li {
            padding-bottom:$sp05; 
            flex:1 0 100%;
            @media screen and (min-width: $breakpoint-sm) {
                padding-bottom: 0;
                flex:0 0 auto;
                &:first-of-type {
                    padding-right:$sp2;
                }
            }
        }
    }
    .hours-header {
        padding:$sp05 0;
    }
    .fa.xs { transform: translateY($sph0125); }
}
// Getting Started