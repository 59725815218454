@use "sass:math";
.step-navigation {
  background: var(--color-gray-six);
  margin:0;
  padding:0;
  border-radius: var(--border-radius);
  &.er-flex {
    align-items: stretch;
    background-attachment: fixed;
  }

  li {
    list-style: none;
    flex-basis: 20%;
    align-items:center;
    position: relative;
    a, .steppa {
      text-decoration: none;
      border:none;
      color: var(--color-gray-one);
      flex-basis: 100%;
      padding:$sp1 $sp05;
      @extend .body-sm;
      text-align: center;
      .fa { display:none; }
      &.er-flex {
        //ie fallback
        justify-content: center;
        justify-content: space-evenly;
      }
    }
    &:last-child {
      border:none;
    }
    &:first-child {
      border-top-left-radius: var(--border-radius);
      border-bottom-left-radius: var(--border-radius);
    }
    &.active, &.completed {
      background: var(--color-util-two);
      a { color: var(--color-gray-one); }
    }
    &.active {
      a, .fa { font-weight: 800; }
      .steppa { font-weight: 800; color: var(--color-gray-one); }
    }
    .ez-sep, .step-sep{
      display:inline-block;
      flex:1 0 1px;
      background:var(--color-gray-six);
      height:50%;
    }

    @media screen and (min-width:$breakpoint-xs) {
      overflow:hidden;
      &.active, &.completed {
        a .fa, .steppa .fa {
          display:inline-block;
          color: var(--color-gray-one);;
        }
      }
      &.active:after {
        content:" ";
        position: absolute;
        $caretSize: 15px;
        height: $caretSize;
        width: $caretSize;
        transform: rotate(45deg);
        bottom: -( (math.div($caretSize, 2))+1px );
        border-left: math.div($caretSize, 2) solid $base-color;
        border-top: math.div($caretSize, 2) solid $base-color;
        left:calc(50% - #{math.div($caretSize, 2)});
      }
    }
  }
}
