.fwc-expand-wrapper {
    border-top: 1px solid var(--color-gray-four);
  }
  .fwc-expand-last {
    border-bottom: 1px solid var(--color-gray-four);
  }
  .fwc-expand-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: calc(4 * var(--fwcu)) calc(4 * var(--fwcu)) calc(4 * var(--fwcu)) calc(1.5 * var(--fwcu));
  }
  .fwc-expand-label {
    color: var(--color-gray-one);
    line-height: calc(3 * var(--fwcu));
  }
  .fwc-expand-button {
    padding: 0;
    border: none;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    margin: 0 calc(1.75 * var(--fwcu));
    border-radius: 0;
  }
  .fwc-expand-button:hover {
    cursor: pointer;
  }
  .fwc-expand-icon {
    fill: var(--color-primary);
    height: calc(3 * var(--fwcu));
    width: calc(3 * var(--fwcu));
  }
  .fwc-expand-icon.expanded {
    transform: rotateX(180deg);
  }
  .fwc-expand-content {
    overflow: hidden;
    transition: height 0.3s ease;
    padding-right: calc(5 * var(--fwcu));
    padding-left: calc(8 * var(--fwcu));
    height: 0;
  }
  .fwc-expand-drawer {
    display: none;
    padding-bottom: calc(4 * var(--fwcu));
  }