/*
    Style only for Motif Input
    Dependencies: vars.css
*/

/* TODO: Inactive States */
.fwc-label,
.fwc-input .fwc-label,
.fwc-textarea .fwc-label,
.fwc-label span {
  display: block;
  padding: 0;
  border: none;
  margin-bottom: calc(0.5 * var(--fwcu));
  color: var(--color-gray-one);
  font-weight: bold;
  line-height: var(--default-line-height);
}

.fwc-input input {
  font-size: calc(2 * var(--fwcu));
  border: 1px solid var(--color-gray-three);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-light);
  color: var(--color-gray-one);
  padding: calc(0.5 * var(--fwcu)) calc(2 * var(--fwcu));
  height: calc(5 * var(--fwcu));
  width: 100%;
}
.fwc-input input::placeholder {
  color: var(--color-gray-two);
}

/* Error State */
.fwc-input input[aria-invalid="true"],
.fwc-textarea textarea[aria-invalid="true"] {
  border-color: var(--color-alert-error);
}
.fwc-input span[role="alert"],
.fwc-textarea span[role="alert"] {
  color: var(--color-alert-error);
  display: flex;
  align-items: center;
  padding-top: var(--fwcu);
}
.fwc-input span[role="alert"]::before,
.fwc-textarea span[role="alert"]::before {
  color: var(--color-alert-error);
  font-size: calc(var(--default-font-size));
}

/* Disabled State */
.fwc-input.fwc-disabled .fwc-label,
.fwc-textarea.fwc-disabled .fwc-label {
  opacity: var(--disabled-opacity);
}
.fwc-input.fwc-disabled input:disabled,
.fwc-textarea.fwc-disabled textarea:disabled {
  border-color: rgba(var(--color-rgb-gray-three), var(--disabled-opacity));
  background-color: rgba(var(--color-rgb-white), var(--disabled-opacity));
}

/** Input with icon */
.fwc-input.fwc-input-icon .fwc-icon {
  position: absolute;
  padding: calc(1.25 * var(--fwcu));
  font-size: calc(2.5 * var(--fwcu));
  padding-top: calc(1.25 * var(--fwcu));
}
.fwc-input.fwc-input-icon input {
  padding-left: calc(5 * var(--fwcu));
}
.fwc-input.fwc-input-icon .fwc-icon {
  color: var(--color-gray-one);
}

/** Search input with animation */
.fwc-input-search {
  display: flex;
  align-items: center;
}
.fwc-input-search input:not(:focus) {
  transition: all 200ms ease-out;
  width: calc(10 * var(--fwcu));
}
.fwc-input-search input:focus {
  transition: all 200ms ease-in;
  width: calc(22.5 * var(--fwcu));
}
.fwc-input-search input {
  background: transparent;
  border: none;
  outline: none;
  caret-color: var(--color-white);
  color: var(--color-white);
  padding-left: calc(1 * var(--fwcu));
}
.fwc-input-search input::placeholder {
  color: var(--color-white);
}
.fwc-input-search.fwc-icon-search {
  position: absolute;
  left: 0;
  top: calc(0.5 * var(--fwcu));
}
.fwc-input-search.active {
  background-color: var(--color-primary-hover);
  transition: 200ms all ease-out;
  outline: var(--fwc-focus);
}
.fwc-input-search {
  padding: calc(0.5 * var(--fwcu)) calc(2 * var(--fwcu));
  background-color: transparent;
  border-radius: calc(4 * var(--fwcu));
  color: var(--color-white);
  transition: 200ms all ease-in;
  font-weight: 600;
}

.fwc-input input:focus,
input:focus + .fwc-label,
textarea:focus {
  outline: var(--fwc-focus);
}

/* Typeahead empty state */
.fwc-typeahead-empty {
  padding: calc(1.5 * var(--fwcu));
  color: var(--color-gray-two);
}

/** Textarea **/
.fwc-textarea textarea {
  border-radius: calc(var(--fwcu));
  border: 1px solid rgb(234, 234, 234);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  padding: calc(1.25 * var(--fwcu)) 0px 0px calc(1.5 * var(--fwcu));
  font-size: calc(1.75 * var(--fwcu));
  width: 100%;
  height: calc(16 * var(--fwcu));
}
.fwc-textarea textarea::placeholder {
  color: var(--color-gray-two);
  height: calc(2.75 * var(--fwcu));
  line-height: calc(2.75 * var(--fwcu));
}
