// Blue Site Header
.er-header {
  background:$base-color;
  padding:$sp05;
  height:$header-offset;
  position: fixed;
  width:100%;
  z-index:1000;
  box-shadow: 0 1px 2px rgba(0,0,0,.1);
  .headundred { height:100%; }
  .er-menu-button {
    color:$primary-color-light-blue;
    .fa-bars { font-size:$sp2;}
    .menu-txt {
      display:none;
      font-size:$sp1 + $sp05;
    }
  }
  .welcomTxt, .logout-btn {
    padding-top: 10px;
    padding-left: 40px;
  }
  .brandlogo img {
    float: right;
  }

  @media screen and (min-width: $breakpoint-sm) {
    .er-menu-button .menu-txt {
      display:inline-block;
      padding-left:$sp05;
    }
  }
}

.main-header {
  height:85px;
  width:100%;
  background:$base-color;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index:1000;
  top:0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.main-header-easypay{
  height:85px;
  width:100%;
  background:$base-color;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index:1000;
  top:0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.main-wrap {
  margin-top:85px;
}
.app-wrap {
  min-height: 100vh;
}
