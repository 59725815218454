.fwc-table th,
.fwc-table td {
  font-family: var(--font-sans-serif);
  a {
    font-family: var(--font-sans-serif) !important;
  }
  div {
    font-family: var(--font-sans-serif) !important;
  }
}
.fwc-table-overflow {
  overflow-x: auto;
  /* Wrapper for table to scroll in when it can't shrink any further */
  /* Not required */
}
.fwc-table {
  border-spacing: 0;
  width: 100%;
  max-width: 100%;
  margin-bottom: calc(2.5 * var(--fwcu));
  background-color: transparent;
}
.fwc-table tr:first-child th:first-child {
  border-top-left-radius: var(--border-radius);
}
.fwc-table tr:first-child th:last-child {
  border-top-right-radius: var(--border-radius);
}
.fwc-table tr:last-child td:first-child {
  border-bottom-left-radius: var(--border-radius);
}
.fwc-table tr:last-child td:last-child {
  border-bottom-right-radius: var(--border-radius);
}
.fwc-table th {
  text-align: left;
}
.fwc-table th,
.fwc-table td {
  padding: var(--fwcu) calc(4 * var(--fwcu));
}
.fwc-table th {
  background: var(--color-gray-six);
  color: var(--color-gray-two);
  font-weight: bold;
  text-transform: uppercase;
}
.fwc-table td {
  font-size: calc(2 * var(--fwcu));
  font-weight: 400;
  color: var(--color-gray-one);
}
.fwc-table td {
  border-right: 1px solid var(--color-gray-six);
}
.fwc-table td:first-child {
  border-left: 1px solid var(--color-gray-six);
}
.fwc-table tr:last-child td {
  border-bottom: 1px solid var(--color-gray-six);
}
.fwc-table.no-cell-borders td {
  border-right: 0px;
}
.fwc-table.no-cell-borders td:last-child {
  border-right: 1px solid var(--color-gray-six);
}
.fwc-table tr {
  background: var(--color-white);
}
.fwc-table tr:nth-child(2n + 2) {
  background: var(--color-gray-five);
}
.fwc-table .fwc-text-right {
  text-align: right;
}
.fwc-table .fwc-text-center {
  text-align: center;
}

.fwc-table-extensive .hide-lg {
  display: none;
}
.fwc-table button.fwc-icon-caret-filled {
  font-size: calc(1.25 * var(--fwcu));
  border: none;
  padding: 0;
  margin: 0;
  margin-left: var(--fwcu);
  color: var(--color-gray-one);
  transform: rotate(0deg);
  transition: transform 250ms ease;
  background: transparent;
}
.fwc-table button.fwc-icon-caret-filled:hover {
  cursor: pointer;
}
.fwc-table button.fwc-icon-caret-filled.descending {
  transform: rotate(180deg);
}
@media screen and (max-width: 600px) {
  /** Overrides for base .fwc-table styles */
  .fwc-table td,
  .fwc-table th {
    padding: var(--fwcu) calc(2 * var(--fwcu));
  }
  .fwc-table.fwc-table-extensive td {
    border: none;
    padding: 0 calc(2 * var(--fwcu));
  }
  .fwc-table.fwc-table-extensive tr:last-child td {
    border: none;
  }
  .fwc-table.fwc-table-extensive tr:nth-child(2n + 2) {
    background: var(--color-white);
  }
  .fwc-table.fwc-table-extensive tr:last-child td:first-child {
    border-bottom-left-radius: 0;
  }

  .fwc-table-extensive table,
  .fwc-table-extensive thead,
  .fwc-table-extensive tbody,
  .fwc-table-extensive th,
  .fwc-table-extensive td,
  .fwc-table-extensive tr {
    display: block;
    font-size: calc(1.75 * var(--fwcu));
  }
  .fwc-table-extensive .hide-lg {
    display: block;
    padding: 0 calc(2 * var(--fwcu));
    font-weight: bold;
  }
  .fwc-table-extensive tbody tr td:nth-child(2) {
    /** Styles for the first visible "header" on mobile view */
    padding-top: calc(3 * var(--fwcu));
    padding-bottom: calc(0.5 * var(--fwcu));
  }
  .fwc-table-extensive tbody tr td:last-child {
    padding-bottom: calc(3 * var(--fwcu));
  }
  .fwc-table-extensive ul li:last-child {
    margin-bottom: 0;
  }
  .fwc-table-extensive td.table-data-bm {
    padding-bottom: calc(3 * var(--fwcu));
  }
  .fwc-table-extensive thead tr {
    /** Hide table headers visually but leave on the page for accessibility */
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .fwc-table-extensive tbody tr {
    margin-bottom: calc(1.5 * var(--fwcu));
    border-radius: var(--border-radius);
    border: 1px solid var(--color-gray-six);
  }
  .fwc-table-extensive tbody tr td:first-child {
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    background-color: var(--color-gray-six);
    color: var(--color-gray-two);
    padding: calc(1.5 * var(--fwcu));
  }
  .fwc-table-extensive td {
    border-bottom: 1px solid var(--color-gray-six);
    position: relative;
    white-space: normal;
    text-align: left;
  }
  .fwc-table-extensive td:first-child {
    padding-left: calc(1.5 * var(--fwcu));
  }
}
