
.vid-container {
  width: 850px;
  max-width: 850px;
  max-height: 480px;
  @media screen and (max-width: 600px){
    max-width: 400px;
    max-height: 295px;
  }
}

#fade {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 1001;
  -moz-opacity: 0.8;
  opacity: .80;
  filter: alpha(opacity=80);
}

#light {
  display: none;
  left: 0;
  right: 0;
  z-index: 1002;
  overflow: visible;
}

#tutorial-video {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  z-index: 1005;
  top: -30px;
  bottom: 0;
  margin-top: 10%;
}

.video-iframe {
  height: 480px;
  width: 850px;
  border: 0
}

.close-button {
  padding: 8px 16px;
  color: white;
  font-size: 32px;
  z-index: 9999;
  cursor: pointer;
}

.topright {
  position: absolute;
  right: -33px;
  top: -47px;
}
