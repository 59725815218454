$splnk: 38px;
$sppad: 4px;
.er-pagination {

    display:flex;
    justify-content: flex-start;
    // use spacer to prevent link shift when moving from first position
    a, .erp-sgif {
        flex-basis: $splnk;
        flex-shrink:0;
        font-weight: 400;
        color:$base-color;
        display:block;
        line-height:$splnk;
        height:$splnk;
        text-align: center;
        margin-right:$sppad;
    }
    a {
        .fa {
            font-family: 'FontAwesome';
            color: $base-color;
        }
        .fa.fa-angle-double-left-new {
          transform: rotate(180deg);
        }
        &, &:link, &:visited {
            background:$primary-color-light-blue;
            border:1px solid $primary-color-light-blue;
            color: #FFFFFF;
        }
        &.active, &.active:hover, &.active:visited:hover {
            background:$base-color;
            color:$primary-color-light-blue;
            text-decoration: none;
            cursor: default;
        }
        &:hover, &:visited:hover {
            background:$primary-color-blue;
            text-decoration: underline;
            .fa {
                text-decoration: underline;
             }
         }
        &:last-child {
            margin-right:0;
        }
    }

    .erp-bck, .erp-fwd {
        display:none;
    }
    @media screen and (min-width: $breakpoint-sm) {
        .erp-bck, .erp-fwd {
            display:block;
        }
    }

}

.erp-total-pages {
    color:$gray-2;
    padding-top:$sp05;
}
