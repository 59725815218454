.fwc-select-list {
  position: relative;
}
.fwc-select-list select {
  border: 1px solid var(--color-gray-three);
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  color: var(--color-primary);
  padding: calc(0.5 * var(--fwcu)) calc(1.25 * var(--fwcu));
  height: calc(5 * var(--fwcu));
  text-align: left;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-light);
  cursor: pointer;
  background-repeat: no-repeat;
  background-color: var(--color-white);
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgPHBhdGggZD0iTTAsMTIuOCBDMCwxNC41NjczMTEyIDEuNDMyNjg4OCwxNiAzLjIsMTYgTDMuMiwxNiBMMCwxNiBaIE0xNiwxMi44IEwxNiwxNiBMMTIuOCwxNiBDMTQuNTY3MzExMiwxNiAxNiwxNC41NjczMTEyIDE2LDEyLjggTDE2LDEyLjggWiBNMTYsMy4yIEMxNiwxLjQzMjY4ODggMTQuNTY3MzExMiwwIDEyLjgsMCBMMTIuOCwwIEwxNiwwIFogTTAsMCBMMy4yLDAgQzEuNDMyNjg4OCwwIDAsMS40MzI2ODg4IDAsMy4yIEwwLDMuMiBMMCwwIFoiLz4KICAgIDxwYXRoIGZpbGwtcnVsZT0ibm9uemVybyIgZD0iTTkuNjQ1NDgzMzkgOS42TDYuNCA5LjZDNi4yMzAyNjE0NCA5LjYgNi4wNjc0NzQ5NCA5LjY2NzQyODM4IDUuOTQ3NDUxNjUgOS43ODc0NTE2NSA1LjcxMDY3MDYyIDEwLjAyNDIzMjcgNS42OTgyMDg0NSAxMC40MDAzODc2IDUuOTEwMDY1MTggMTAuNjUxODM5Nkw1Ljk0NzQ1MTY1IDEwLjY5MjU0ODQgNy41NzAxOTMzNCAxMi4zMTUyOUM3LjgwNjk3NDQgMTIuNTUyMDcxMSA4LjE4MzEyOTM0IDEyLjU2NDUzMzIgOC40MzQ1ODEzMSAxMi4zNTI2NzY1TDguNDc1MjkwMDUgMTIuMzE1MjkgMTAuMDk4MDMxNyAxMC42OTI1NDg0QzEwLjIxODA1NSAxMC41NzI1MjUxIDEwLjI4NTQ4MzQgMTAuNDA5NzM4NiAxMC4yODU0ODM0IDEwLjI0IDEwLjI4NTQ4MzQgOS45MDI2MDQyMiAxMC4wMjQ0MDI1IDkuNjI2MTg3MTcgOS42OTMyNDczNiA5LjYwMTc1NTQyTDkuNjQ1NDgzMzkgOS42ek04LjQ3NTI5MDA1IDMuMzg3NDUxNjVDOC4yMzg1MDg5OSAzLjE1MDY3MDYxIDcuODYyMzU0MDggMy4xMzgyMDg0NSA3LjYxMDkwMjA4IDMuMzUwMDY1MThMNy41NzAxOTMzNCAzLjM4NzQ1MTY1IDUuOTQ3NDUxNjUgNS4wMTAxOTMzNEM1LjgyNzQyODM4IDUuMTMwMjE2NjQgNS43NiA1LjI5MzAwMzE0IDUuNzYgNS40NjI3NDE3IDUuNzYgNS44MDAxMzc0NyA2LjAyMTA4MDkgNi4wNzY1NTQ1MyA2LjM1MjIzNjA2IDYuMTAwOTg2MjdMNi40IDYuMTAyNzQxNyA5LjY0NTQ4MzM5IDYuMTAyNzQxN0M5LjgxNTIyMTk1IDYuMTAyNzQxNyA5Ljk3ODAwODQ1IDYuMDM1MzEzMzEgMTAuMDk4MDMxNyA1LjkxNTI5MDA1IDEwLjMzNDgxMjggNS42Nzg1MDg5OSAxMC4zNDcyNzQ5IDUuMzAyMzU0MDggMTAuMTM1NDE4MiA1LjA1MDkwMjA4TDEwLjA5ODAzMTcgNS4wMTAxOTMzNCA4LjQ3NTI5MDA1IDMuMzg3NDUxNjV6Ii8+CiAgPC9nPgo8L3N2Zz4K"), linear-gradient(var(--color-primary), var(--color-primary));
  background-position: 95% 50%;
  background-size: calc(2 * var(--fwcu)) calc(2 * var(--fwcu));
  font-weight: bold;
}
/*
    Background images are base64'd versions of fwc-icon-select inversed. Color of arrows, and inversed corners comes from image and matches background-color of select element.
    The 2nd background-image (gradient) is set to a solid primary color
*/

.fwc-select-list.dark select {
  color: var(--color-white);
  background-color: var(--color-gray-one);
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8ZyBmaWxsPSIjMzMzIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgPHBhdGggZD0iTTAsMTIuOCBDMCwxNC41NjczMTEyIDEuNDMyNjg4OCwxNiAzLjIsMTYgTDMuMiwxNiBMMCwxNiBaIE0xNiwxMi44IEwxNiwxNiBMMTIuOCwxNiBDMTQuNTY3MzExMiwxNiAxNiwxNC41NjczMTEyIDE2LDEyLjggTDE2LDEyLjggWiBNMTYsMy4yIEMxNiwxLjQzMjY4ODggMTQuNTY3MzExMiwwIDEyLjgsMCBMMTIuOCwwIEwxNiwwIFogTTAsMCBMMy4yLDAgQzEuNDMyNjg4OCwwIDAsMS40MzI2ODg4IDAsMy4yIEwwLDMuMiBMMCwwIFoiLz4KICAgIDxwYXRoIGZpbGwtcnVsZT0ibm9uemVybyIgZD0iTTkuNjQ1NDgzMzkgOS42TDYuNCA5LjZDNi4yMzAyNjE0NCA5LjYgNi4wNjc0NzQ5NCA5LjY2NzQyODM4IDUuOTQ3NDUxNjUgOS43ODc0NTE2NSA1LjcxMDY3MDYyIDEwLjAyNDIzMjcgNS42OTgyMDg0NSAxMC40MDAzODc2IDUuOTEwMDY1MTggMTAuNjUxODM5Nkw1Ljk0NzQ1MTY1IDEwLjY5MjU0ODQgNy41NzAxOTMzNCAxMi4zMTUyOUM3LjgwNjk3NDQgMTIuNTUyMDcxMSA4LjE4MzEyOTM0IDEyLjU2NDUzMzIgOC40MzQ1ODEzMSAxMi4zNTI2NzY1TDguNDc1MjkwMDUgMTIuMzE1MjkgMTAuMDk4MDMxNyAxMC42OTI1NDg0QzEwLjIxODA1NSAxMC41NzI1MjUxIDEwLjI4NTQ4MzQgMTAuNDA5NzM4NiAxMC4yODU0ODM0IDEwLjI0IDEwLjI4NTQ4MzQgOS45MDI2MDQyMiAxMC4wMjQ0MDI1IDkuNjI2MTg3MTcgOS42OTMyNDczNiA5LjYwMTc1NTQyTDkuNjQ1NDgzMzkgOS42ek04LjQ3NTI5MDA1IDMuMzg3NDUxNjVDOC4yMzg1MDg5OSAzLjE1MDY3MDYxIDcuODYyMzU0MDggMy4xMzgyMDg0NSA3LjYxMDkwMjA4IDMuMzUwMDY1MThMNy41NzAxOTMzNCAzLjM4NzQ1MTY1IDUuOTQ3NDUxNjUgNS4wMTAxOTMzNEM1LjgyNzQyODM4IDUuMTMwMjE2NjQgNS43NiA1LjI5MzAwMzE0IDUuNzYgNS40NjI3NDE3IDUuNzYgNS44MDAxMzc0NyA2LjAyMTA4MDkgNi4wNzY1NTQ1MyA2LjM1MjIzNjA2IDYuMTAwOTg2MjdMNi40IDYuMTAyNzQxNyA5LjY0NTQ4MzM5IDYuMTAyNzQxN0M5LjgxNTIyMTk1IDYuMTAyNzQxNyA5Ljk3ODAwODQ1IDYuMDM1MzEzMzEgMTAuMDk4MDMxNyA1LjkxNTI5MDA1IDEwLjMzNDgxMjggNS42Nzg1MDg5OSAxMC4zNDcyNzQ5IDUuMzAyMzU0MDggMTAuMTM1NDE4MiA1LjA1MDkwMjA4TDEwLjA5ODAzMTcgNS4wMTAxOTMzNCA4LjQ3NTI5MDA1IDMuMzg3NDUxNjV6Ii8+CiAgPC9nPgo8L3N2Zz4K"), linear-gradient(var(--color-gray-five), var(--color-gray-five));
}
.fwc-select-list select:disabled {
  border-color: rgba(var(--color-rgb-gray-three), var(--disabled-opacity));
  background-color: rgba(var(--color-rgb-white), var(--disabled-opacity));
  pointer-events: none;
}
.fwc-select-list.dark select:disabled {
  background-color: rgba(var(--color-rgb-gray-one), var(--disabled-opacity));
}
.fwc-select-list.fwc-disabled label {
  opacity: var(--disabled-opacity);
}

/*Focus styles*/
.fwc-select-list select:focus {
  outline: var(--fwc-focus);
}
