
.chat-agent-typing {
  margin: 1rem;
}
.typing-icon {
  height: 70px;
}
.chat-only-container {
  position: relative;
  background-color: red;
  margin: 3rem auto;
  width: 744px;
  box-shadow: 0 20px 36px 0 rgba(0, 0, 0, 0.24);
  border-radius: 4px 4px 0 0;
  background: white;
  max-height: 100%;
  height: 0px;
  font-family: "Lato",sans-serif !important;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #333;
  font-style: normal;
  transition: height 0.5s;
    &.maximized {
    height: 490px;
  }
  &.minimized {
    height: 60px;
    width: 60px;
    box-shadow: none;
    background: none;
    bottom: 7rem;
    right: 7.5rem;
    @media screen and (max-width: 800px) {
      bottom: 2rem;
      right: 2.5rem;
    }
    .chat-titlebar {
      align-items: center;
      border-radius: 30px;
      height: 60px;
      justify-content: center;
      width: 60px;
    }
    .chat-toggle {
      margin-left: 0;
    }
    .chat-unread-messages {
      width: 20px;
      height: 20px;
      border-radius: 10px;
      background: #d5367e;
      font-size: 12px;
      color: $white;
      align-items: center;
      justify-content: center;
      display: flex;
      position: relative;
      top: 11px;
      left: 36px;
    }
  }
  .chat-titlebar {
    width: 100%;
    height: 52px;
    color: $primary-text-color;
    padding: 0.5rem;
    border-radius: 4px 4px 0 0;
    font-size: .95rem;
    .chat-toggle {
      margin-left: auto;
      &.er-expand-plus {
        padding: 0;
        color: $white;
        margin: -1rem 0 0 auto;
        font-size: 2rem;
      }
    }
  }
  .chat-titlebar1{
    background: #2453a6;
  }
  .agent-message {
    background-color: #f7f7f7;
    padding: .75rem 1rem;
    margin: .75rem 2rem .75rem 3rem;
    border-radius: 35px 35px 35px 0;
    word-wrap: break-word !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    &.action-selection {
      background-color: #286ce2;
      color: $white;
      border-radius: 35px 35px 0 35px;
      margin: .75rem 1rem .75rem 4rem;
      word-wrap: break-word !important;
    }
    &.timestamp {
      background-color: white;
      text-align: center;
      padding: .5rem 1rem;
      color: #666666;
    }
  }
  .chat-convo-container {
    height: calc(100% - 130px);
    overflow: auto;
    padding-bottom: 50px;
    .right {
      margin-left: auto;
    }
  }
  .chat-actions {
    margin-bottom: 1rem;
    // margin: auto 1rem 0 2rem;
    margin: 0rem 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    .action-button {
      display: inline-block;
      border-radius: 15px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
      border: solid 1px #949494;
      background-color: $white;
      // font-size: .85rem;
      font-weight: bold;
      padding: 0.25rem 0.9rem;
      margin: .25rem 0.5rem;
    }
  }
  .chat-input-container {
    padding-right: 60px;
    border-top: 1px solid #dcdcdc;
    height: 72px;
    background-color: #ffffff;
    position: absolute;
    width: 744px;
    position: relative;
    .chat-box-input-textarea {
      width: 100%;
      resize: none;
      border: none;
      overflow: hidden;
      padding-left: 10px;
      padding-top: 5px;
      outline: none;
      font-size: 1rem;
    }
    .chat-btn-send {
      position: absolute;
      right: 2rem;
      padding-right: 14px;
      font-size: 0.5rem;
      cursor: pointer;
      background-image:url(../images/iconography-messaging-send.png);
      background-image: 
      -webkit-image-set(
        url(../images/iconography-messaging-send@2x.png) 2x,
        url(../images/iconography-messaging-send@3x.png) 3x,
      );
      width: 3rem;
      height: 2rem;
      background-repeat: no-repeat;
      background-position: center;
    }
    p{
      position: absolute;
      padding-left: 10px;
      padding-top: 5px;
      opacity: 0.5;
      margin-bottom: 0em;
      font-weight: 500;
      }
  }
  .typing-icon {
    height: 70px;
  }
  .chat-btn-send {
    padding-right: 14px;
    color: $primary-color-dark-blue;
    font-size: 2.5rem;
    cursor: pointer;
    margin-top: 6px;
   
  }
  .chat-notice-text {
    color: #0079c2;
    font-style: italic;
  }
  .eva-clickable-buttons {
    display: inline-block;
    margin: 0 10px 20px 0;
  }
  .eva-bot-options {
    border-radius: 20px;
    margin: 10px 5px 20px 10px;
    text-align: center;
    vertical-align: middle;
    padding: 5px 25px 5px 25px;
    color: #0075c9;
    border: 1px solid #949494;
    background-color: rgb(255, 253, 253);
  }
  .chat-title {
    width: 100%;
    height: 26px;
    margin: 4px 25px 4px 9px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #fff;
  }
  @keyframes animate {
    0%   { bottom:60px;width: 0rem;}
    25%   { bottom:60px;width: 6rem;}
    50%   { bottom:60px;width: 12rem;}
    100% { bottom:100px;width: 18rem; }
  }
  .chat-intro-block{
    width: 18rem;
    margin: 0 0 7px;
    padding: 15px 15px 15px 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5);
    border: solid 1px rgba(148, 148, 148, 0.15);
    background-color: #fff;
    position: absolute;
    z-index: -1;
    bottom: 96px;
    right: 0.4rem;
    animation-name: animate;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
    animation-timing-function: ease;
    border-radius: 16px 40px 4px 40px;
    display: block;
      font-family: Lato;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      color: #333;
      h4{
        font-weight: 900;
        margin: 0.5rem 0rem;
        font-size: 14px;
      }
    .close-intro-block{
      background: transparent;
      position: absolute;
      right: -0.4rem;
      bottom: -37px;
      border: 0;
      img{
        width: 26px;
        height: 26px;
      }
    }
  }
  .displayMsg{
    position: relative;
  }
  .iconDisplay{
    margin: auto 1rem 0 0.5rem;
    display: flex;
    position: absolute;
    bottom: 0rem;
  }
  .agent-connect-loader{
    margin: 6rem;
    p {
      text-align: center;
      margin-top: 10px;
      font-family: Lato;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.11px;
      text-align: center;
      color: #333;
    }
  }
  .end-chat{
    width: 100%;
    div{
      justify-content: center;
    }
    p {
      text-align: center;
      margin: 6px;
    }
    button {
      width: 110px;
      height: 40px;
      margin: 10px;
      font-size: 16px;
      font-weight: bold;
      border-radius: 8px;
      border: solid 2px #f0f5ff;
      &.btn-yes {
        background: #0053da;
        color: white;
      }
      &.btn-no {
        color: #286ce2;
      }
    }
  }
  .end-chat-label {
    position: absolute;
    bottom: 78px;
    background: white;
    text-align: right;
    height: 33px;
    padding: 8px;
    border-radius: 15px;
    opacity: 0.7;
    font-weight: bold;
    color: #286ce2;
    right: 10px;
    cursor: pointer;
  }
  
  .agent {
    border-radius: 8px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 24px 30px;
    margin: auto;
    width: 87%;
    .oval {
      width: 78px;
      height: 78px;
      margin: 0 16px 0 0;
      padding: 3px;
      border-radius: 40px;
      background-image: linear-gradient(to right, #2ab5ca, #286ce2 98%);
      position: relative;
      .name {
        color: #333;
        width: 70px;
        background: white;
        height: 70px;
        border-radius: 45px;
        position: absolute;
        top: 4px;
        left: 4px;
        h4 {
          top: 14px;
          position: absolute;
          left: 13px;
          font-family: Bitter;
          font-size: 32px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.19;
          letter-spacing: normal;
          text-align: center;
          color: #333;
        }
      }
    }
    .agent-info {
      font-family: Lato;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: 0.11px;
      color: #333;
    
    }
  }
  
  .agent-name {
    width: 35px;
    height: 35px;
    padding: 3px;
    border-radius: 40px;
    background-image: linear-gradient(to right, #2ab5ca, #286ce2 98%);
    position: relative;
    color:#333333;
    .first-last-name {
      width: 29px;
      background: white;
      height: 29px;
      border-radius: 45px;
      position: absolute;
      top: 3px;
      left: 3px;
      h4 {
        top: 6px;
        position: absolute;
        left: 5px;
        font-size: 14px;
        font-weight: 550;
      }
    }
    &.m-l{
      margin: 0 16px 0 0;
    }   
  }
}

.new-chat-container {
  z-index: 99999991;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 375px;
  box-shadow: 0 20px 36px 0 rgba(0, 0, 0, 0.24);
  border-radius: 4px 4px 0 0;
  background: white;
  max-height: 100%;
  height: 0px;
  font-family: "Lato",sans-serif !important;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #333;
  font-style: normal;
  transition: height 0.5s;
    &.maximized {
    height: 650px;
  }
  &.minimized {
    height: 60px;
    width: 60px;
    box-shadow: none;
    background: none;
    bottom: 7rem;
    right: 7.5rem;
    @media screen and (max-width: 800px) {
      bottom: 2rem;
      right: 2.5rem;
    }
    .chat-titlebar {
      align-items: center;
      border-radius: 30px;
      height: 60px;
      justify-content: center;
      width: 60px;
    }
    .chat-toggle {
      margin-left: 0;
    }
    .chat-unread-messages {
      width: 20px;
      height: 20px;
      border-radius: 10px;
      background: #d5367e;
      font-size: 12px;
      color: $white;
      align-items: center;
      justify-content: center;
      display: flex;
      position: relative;
      top: 11px;
      left: 36px;
    }
  }
  .chat-titlebar {
    width: 100%;
    height: 52px;
    color: $primary-text-color;
    padding: 0.5rem;
    border-radius: 4px 4px 0 0;
    font-size: .95rem;
    .chat-toggle {
      margin-left: auto;
      &.er-expand-plus {
        padding: 0;
        color: $white;
        margin: -1rem 0 0 auto;
        font-size: 2rem;
      }
    }
  }
  .chat-titlebar1{
    background: #2453a6;
  }
  .agent-message {
    background-color: #f7f7f7;
    padding: .75rem 1rem;
    margin: .75rem 2rem .75rem 3rem;
    border-radius: 35px 35px 35px 0;
    word-wrap: break-word !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    &.action-selection {
      background-color: #286ce2;
      color: $white;
      border-radius: 35px 35px 0 35px;
      margin: .75rem 1rem .75rem 4rem;
      word-wrap: break-word !important;
    }
    &.timestamp {
      background-color: white;
      text-align: center;
      padding: .5rem 1rem;
      color: #666666;
    }
  }
  .chat-convo-container {
    height: calc(100% - 130px);
    overflow: auto;
    padding-bottom: 50px;
    .right {
      margin-left: auto;
    }
  }
  .chat-actions {
    margin-bottom: 1rem;
    // margin: auto 1rem 0 2rem;
    margin: 0rem 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    .action-button {
      display: inline-block;
      border-radius: 15px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
      border: solid 1px #949494;
      background-color: $white;
      // font-size: .85rem;
      font-weight: bold;
      padding: 0.25rem 0.9rem;
      margin: .25rem 0.5rem;
    }
  }
  .chat-input-container {
    padding-right: 60px;
    position: absolute;
    border-top: 1px solid #dcdcdc;
    width: 100%;
    height: 72px;
    background-color: #ffffff;
    bottom: 0 !important;
    .chat-box-input-textarea {
      width: 100%;
      resize: none;
      border: none;
      overflow: hidden;
      padding-left: 10px;
      padding-top: 5px;
      outline: none;
      font-size: 1rem;
    }
    .chat-btn-send {
      position: absolute;
      right: 0;
      padding-right: 14px;
      font-size: 0.5rem;
      cursor: pointer;
      background-image:url(../images/iconography-messaging-send.png);
      background-image: 
      -webkit-image-set(
        url(../images/iconography-messaging-send@2x.png) 2x,
        url(../images/iconography-messaging-send@3x.png) 3x,
      );
      width: 3rem;
      height: 2rem;
      background-repeat: no-repeat;
      background-position: center;
    }
    p{
      position: absolute;
      padding-left: 10px;
      padding-top: 5px;
      opacity: 0.5;
      margin-bottom: 0em;
      font-weight: 500;
      }
  }
  .typing-icon {
    height: 70px;
  }
  .chat-btn-send {
    position: absolute;
    right: 0;
    padding-right: 14px;
    color: $primary-color-dark-blue;
    font-size: 2.5rem;
    cursor: pointer;
    margin-top: 6px;
   
  }
  .chat-notice-text {
    color: #0079c2;
    font-style: italic;
  }
  .eva-clickable-buttons {
    display: inline-block;
    margin: 0 10px 20px 0;
  }
  .eva-bot-options {
    border-radius: 20px;
    margin: 10px 5px 20px 10px;
    text-align: center;
    vertical-align: middle;
    padding: 5px 25px 5px 25px;
    color: #0075c9;
    border: 1px solid #949494;
    background-color: rgb(255, 253, 253);
  }
  .chat-title {
    width: 100%;
    height: 26px;
    margin: 4px 25px 4px 9px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #fff;
  }
  @keyframes animate {
    0%   { bottom:60px;width: 0rem;}
    25%   { bottom:60px;width: 6rem;}
    50%   { bottom:60px;width: 12rem;}
    100% { bottom:100px;width: 18rem; }
  }
  .chat-intro-block{
    width: 18rem;
    margin: 0 0 7px;
    padding: 15px 15px 15px 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5);
    border: solid 1px rgba(148, 148, 148, 0.15);
    background-color: #fff;
    position: absolute;
    z-index: -1;
    bottom: 96px;
    right: 0.4rem;
    animation-name: animate;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
    animation-timing-function: ease;
    border-radius: 16px 40px 4px 40px;
    display: block;
      font-family: Lato;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      color: #333;
      h4{
        font-weight: 900;
        margin: 0.5rem 0rem;
        font-size: 14px;
      }
    .close-intro-block{
      background: transparent;
      position: absolute;
      right: -0.4rem;
      bottom: -37px;
      border: 0;
      img{
        width: 26px;
        height: 26px;
      }
    }
  }
  .displayMsg{
    position: relative;
  }
  .iconDisplay{
    margin: auto 1rem 0 0.5rem;
    display: flex;
    position: absolute;
    bottom: 0rem;
  }
  .agent-connect-loader{
    position: relative;
    top: 140px;
    p {
      text-align: center;
      margin-top: 10px;
      font-family: Lato;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.11px;
      text-align: center;
      color: #333;
    }
  }
  .end-chat{
    width: 100%;
    div{
      justify-content: center;
    }
    p {
      text-align: center;
      margin: 6px;
    }
    button {
      width: 110px;
      height: 40px;
      margin: 10px;
      font-size: 16px;
      font-weight: bold;
      border-radius: 8px;
      border: solid 2px #f0f5ff;
      &.btn-yes {
        background: #0053da;
        color: white;
      }
      &.btn-no {
        color: #286ce2;
      }
    }
  }
  .end-chat-label {
    position: absolute;
    bottom: 78px;
    background: white;
    text-align: right;
    height: 33px;
    padding: 8px;
    border-radius: 15px;
    opacity: 0.7;
    font-weight: bold;
    color: #286ce2;
    right: 10px;
  }
  
  .agent {
    border-radius: 8px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 24px 30px;
    margin: auto;
    width: 87%;
    .oval {
      width: 78px;
      height: 78px;
      margin: 0 16px 0 0;
      padding: 3px;
      border-radius: 40px;
      background-image: linear-gradient(to right, #2ab5ca, #286ce2 98%);
      position: relative;
      .name {
        color: #333;
        width: 70px;
        background: white;
        height: 70px;
        border-radius: 45px;
        position: absolute;
        top: 4px;
        left: 4px;
        h4 {
          top: 14px;
          position: absolute;
          left: 13px;
          font-family: Bitter;
          font-size: 32px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.19;
          letter-spacing: normal;
          text-align: center;
          color: #333;
        }
      }
    }
    .agent-info {
      font-family: Lato;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: 0.11px;
      color: #333;
    
    }
  }
  
  .agent-name {
    width: 35px;
    height: 35px;
    padding: 3px;
    border-radius: 40px;
    background-image: linear-gradient(to right, #2ab5ca, #286ce2 98%);
    position: relative;
    color:#333333;
    .first-last-name {
      width: 29px;
      background: white;
      height: 29px;
      border-radius: 45px;
      position: absolute;
      top: 3px;
      left: 3px;
      h4 {
        top: 6px;
        position: absolute;
        left: 5px;
        font-size: 14px;
        font-weight: 550;
      }
    }
    &.m-l{
      margin: 0 16px 0 0;
    }   
  }
}
