//$font-sans-serif: 'Motif Lato', sans-serif;
//using OpenSans for ER currently
$font-sans-serif: "Open Sans", "open-sans", sans-serif, sans-serif;
$font-serif: 'Motif Garamond', serif;
$color-brand: #0079C2;
$color-primary: #286CE2;
$color-util-one: #D20A3C;
$color-util-two: #53B1A3;
$color-util-three: #2453A6;
$color-util-four: #37475A;
$color-dviz-one: #00D795;
$color-dviz-two: #3BB273;
$color-dviz-three: #108808;
$color-dviz-four: #511284;
$color-dviz-five: #CB0042;
$color-dviz-six: #CB2A73;
$color-dviz-seven: #FF4D61;
$color-dviz-eight: #F2BC35;
$color-gray-one: #333333;
$color-gray-two: #666666;
$color-gray-three: #949494;
$color-gray-four: #CCCCCC;
$color-gray-five: #F7F7F7;
$color-black: #000000;
$color-white: #FFFFFF;
$color-btn-second-border: #F0F5FF;
$fwcu: 8px;
$default-font-size: 16px;
$sub-font-small: 12px;

$breakpoint-sm: 801px !default;
$breakpoint-md: 1024px !default;
$breakpoint-xs: 480px !default;
$breakpoint-lg: 1200px !default;

$box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.08);
$border-radius: $fwcu;

//motif specific colors
$ant-motif-highlight-ocean: #286ce2;
$ant-brand-primary: $ant-motif-highlight-ocean;
$ant-motif-highlight-mauve: #d20a3c;
$ant-motif-highlight-green: #53b1a3;
$ant-motif-highlight-midnight-blue: #2453a6;
$ant-motif-highlight-dark-blue: #37475a;

/** Motif Primary Colors **/
$ant-blue: #0079c2;
$ant-rhino-blue-lt: #2453a6;
$ant-rhino-blue: #37475a;
$ant-sky-blue: #48c9ff;
$ant-light-blue: #58abdf;

/** Motif Secondary Colors **/
$ant-teal: #0a8194;
$ant-dark-teal: #005d6b;
$ant-bermuda-teal: #81cfc2;

/** Accent colors for Motif **/
$ant-motif-accent-red: #cc0033;
$ant-motif-accent-green: #078855;
$ant-motif-accent-orange: #f78d2f;
$ant-motif-accent-yellow: #fed74b;

$support-info: $ant-blue;
$support-error: $ant-motif-accent-red;
$support-success: $ant-motif-accent-green;
$support-danger: $ant-motif-accent-red;
$support-warning: $ant-motif-accent-orange;

$colors: () !default;

$ant-motif-mine-shaft: #333333;
$ant-motif-dark-gray: #555555;
$ant-motif-dove-gray: #666666;
$ant-motif-dusty-gray: #949494;
$ant-medium-gray: #dcdcdc;
$ant-motif-silver: #cccccc;
$ant-motif-gallery: #eeeeee;
$ant-motif-light-gray: #f7f7f7;
$ant-motif-white: $color-white;
$ant-motif-black: $color-black;

$ant-motif-grays: () !default;
$ant-motif-grays: (
  100: $ant-motif-light-gray,
  200: $ant-motif-silver,
  250: $ant-medium-gray,
  300: $ant-motif-dusty-gray,
  400: $ant-motif-dove-gray,
  500: $ant-motif-dark-gray,
  600: $ant-motif-mine-shaft
);

/**  Anthem Font Variables  **/

// Font Families
$font-family-Lato: "Lato", sans-serif;
$font-family-Montserrat: "Montserrat", sans-serif;
$font-family-OpenSans: "Open Sans", "open-sans", sans-serif;

// Font Weights
$font-weight-light: 300;
$font-weight-semi: 600;
$font-weight-bold: 700;

// Heading Font sizes
$font-size-h1: 2.285rem !default;
$font-size-h2: 1.857rem !default;
$font-size-h3: 1.571rem !default;
$font-size-h4: 1.571rem !default;
$font-size-h5: 1.285rem !default;
$font-size-h6: 1.1428rem !default;

$all-headings: (h1, h2, h3, h4, h5, h6);
$all-text-content: (p, span);

$base-font-sizes: (
  mobile: 12px,
  tablet: 14px,
  desktop: 16px
);
// Can't point to calculate rem function as bootstrap is using px values
$font-size-mobile: 16px;
$fonts-size-xs: 14px;
$font-size-small: 20px;
$font-size-header: 28px;
$padding: 60px;

