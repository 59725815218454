/*
    Style only for Motif Toggle
    Dependencies: vars.css
*/

/* TODO: find a better place for this */
fieldset {
  border: 1px solid var(--color-gray-three);
  border-radius: var(--border-radius);
  background: white;
  box-shadow: var(--box-shadow-light);
}
fieldset legend {
  font-weight: bold;
  color: var(--color-gray-one);
}

.fwc-radio {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}
/* TODO: Items are slightly off center in Chrome */

.fwc-radio input {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  display: block;
  height: calc(1.75 * var(--fwcu));
  width: calc(1.75 * var(--fwcu));
  padding: 1px;
  border-radius: 100%;
  margin-right: var(--fwcu);
  border-width: 1px;
  border-style: solid;
  border: 1px solid var(--color-gray-three);
  cursor: pointer;
}

.fwc-radio label {
  cursor: pointer;
  margin-bottom: 0px;
}

.fwc-radio input[disabled] {
  border-color: rgba(var(--color-rgb-gray-three), var(--disabled-opacity));
}
.fwc-radio input[disabled] + label {
  opacity: var(--disabled-opacity);
  cursor: default;
  pointer-events: none;
}

.fwc-radio input:checked {
  background: radial-gradient(ellipse at center, var(--color-primary) 54%, white 59%);
}

.fwc-radio input:focus {
  outline: var(--fwc-focus);
}
