.er-mask-wrapper{
    position:relative;
    .er-mask-toggle{
        color:$gray-2;
        position:absolute;
        font-size:2rem;
        top: 1.5rem;
        right: 25px;
        border:none;
        padding:0;
    }
}

// from pl partials/variables
// $breakpoint-xs: 480px !default;
// $breakpoint-sm: 801px !default;
// $breakpoint-md: 1024px !default;
// $breakpoint-lg: 1200px !default;

@media screen and ( min-width:$breakpoint-sm ) and (max-width:$breakpoint-md){

    .er-mask-wrapper {
        .er-mask-toggle{
            font-size:1.8rem;
            top: 1.5rem;
            right: 0;
        }
    }
}

