.ez-pay-group-header {
    border-bottom:2px solid $gray-2;
    .group-switch {
        margin-left: $sp2*2;
    }
}
.ez-progress {
    background-color:$light-gray;
    background-image: linear-gradient(to right, $accent-color-teal 0%, $accent-color-teal 50%, $light-gray 50%);
    &.last {
        background:$accent-color-teal;
    }
      //override for bar that doesn't span full width
    &.only-bar{
        background-color:transparent;
        background-image:none;
    }
}
.ez-navigation {
  @extend .step-navigation
}
.ez-page-header {
    @extend .er-page-header;
    height:$sph2*2;
}
.ez-pay .er-alert { top:$sph2*2; }

.ez-pay {
    padding-bottom:$sp2;
    //ez-landing
    .ez-landing {
        .ez-landing-options {
            margin-top:$sp2;
            flex-direction: column;
            align-items: flex-start;
        }
        .ez-landing-item {
            flex:1 1 auto;
            text-align: center;
            margin-bottom:$sp1;
            &.left {
                border-bottom:1px solid $light-gray;
                padding-bottom:$sp2;
            }
            &.right {
                margin-top:$sp2;
            }
        }

        .ez-landing-note {
            background: #F7F7F7;
            padding:$sp1;
            margin:$sp2*2;
            line-height: 1.5rem;
        }
        .ez-landing-icons {
            margin:$sp1 0;
            width:$sph1*3;
            height:$sph1*3;
        }
        @media screen and ( min-width:$breakpoint-xs ) {
            .ez-landing-options {
                flex-direction: row;
            }
            .ez-landing-icons {
                width:132px;
                height:132px;
            };
            .ez-landing-item {
                &.left {
                    padding-right:$sp1;
                    padding-bottom:0;
                    border-bottom:none;
                }
                &.right {
                    padding-left:$sp1;
                    margin-top:0;
                    border-left:1px solid $light-gray;
                }
            }
        }
    }

    .ez-info, .ez-search {
        .ez-info-options {
            flex-flow: wrap;
            .er-fi {
                flex-basis: 100%;
            }

        }

        @media screen and ( min-width:$breakpoint-sm ) {
           .ez-info-options {
               margin:$sp1 $sp2*2;
               flex-flow: nowrap;
               &.er-flex {
                   align-items: flex-start;
               }
                .er-fi {
                    flex-basis: 50%;
                    &:nth-child(2) {
                        padding-left:$sp1;
                    }
                }
            }
        }
    }

    .ez-search {
        .ant-lg-select .pfSelect .psButton { width:100%; }
        .psButton span.psActiveOption {
            padding-left:$sp05;
        }
        .uxd-text-input { margin-top:-.7rem; }
        .ez-search-count {
            margin:$sp2 0;
        }

        .ez-search-results {
            &.er-expand-table {
                border:none;
                .er-table-row-cont:last-child { border-bottom: $billing-border; }
            }

            .er-table-drawer {
                padding-bottom:$sp1;
                dd, dt {
                    font-size:$sp1;
                    display:inline-block;
                }
                .exp-drawer-cont {
                    div { margin-bottom:$sp05;}
                }
            }
        }
        .er-expand-table {
            .er-table-row, .er-table-header {
                .er-fi {
                   flex-basis: 30%;
                   &.er-fi-sub { flex-basis: 40%; }
                    @media screen and ( min-width:$breakpoint-sm ) {
                        flex-basis: 16.666%;
                        &.er-fi-sub { flex-basis: 16.666%; }
                    }
                }
            }
        }
        .ez-search-input {
            margin-top:$sp2;
            @media screen and ( min-width:$breakpoint-sm ) {
                margin-top:0;
            }
        }
    }

    .alert-message {
        margin-top: $sp2;
    }

    .ez-payment {
        .payInFullCon {
            text-align: center;
            .pfCheckbox { display:inline; }
                .pcLabel {
                margin:$sp1 0;
            }
        }
        .er-expand-table .er-table-row .er-fi.er-fi-sgif {
            flex-basis: $sph2;
        }

        .er-label {
            font-size: 18px;
            font-weight: 600;
        }
        .ez-amount-input {
            align-items:flex-end;
            justify-content: flex-end;
            label {
                margin:0;
                padding-right:$sp1;
            }
            .uxd-text-input {
                margin:0;
                width:auto;
                input { text-align:right; }
            }
        }
        .uxd-error-container {
            label { text-align: right; }
            padding-top:$sp05;
        }
        .grsInfoContainer {
            margin-left: 10px;
            margin-bottom: 10px;
            margin-right: 55px;
        }
    }

    .ez-payment-note {
        margin-top:$sp2;
        background:$light-gray;
        padding:$sp2;
        .payment-note-list {
            margin-top:$sph1;
            .er-fi:first-child {
                flex-basis:calc(50% - #{$sph2});
            }
            .er-fi:last-child {
                flex-basis: 50%;
            }
        }
        .payment-note-more { margin-top:$sph2; }
        ul {
            padding:0;
            li {
                border-bottom:$billing-border;
                padding:$sp1 0;
                &:last-child {
                    border-bottom: none;
                }
            }
        }
        .showMoreList {
            li {
                &:first-child {
                    border-top:$billing-border;
                }
            }
        }
        &.sticky-margin {
            margin-bottom:$sph2*4;
        }
    }

    .ez-method {
        .ez-account-type {
            margin-bottom:$sp1;
            div[data-uxd-radio-input-cmp]:first-child {
                .prLabel { padding-left:0; }
            }
        }
        .ez-same-as { margin-top:$sp2; }
        .ez-email-fields {
            margin-top:$sp2;
            legend { border:none; }
        }
        .ez-two-col {
            flex-direction: column;
            align-items: stretch;
        }
        @media screen and ( min-width:$breakpoint-sm ) {
            .ez-method-item {
                width:calc(50% - #{$sph1});
            }
           .ez-two-col {
               flex-direction: row;
                .er-fi {
                    &:first-child { padding-right:$sph1; }
                    flex: 1 0 50%;
                }
            }

        }
    }

    .ez-conf {

        .ez-tbl-header {
            padding:$sp1;
            border-bottom:$billing-border;
            h3 { margin-right:$sp1; }
            a { @extend .body-sm; }
        }
        .er-table {
            .er-fi {
                &:nth-child(1), &:nth-child(3) {
                    flex-basis: 30%;
                }
                &:nth-child(2)  {
                    flex-basis: 40%;
                }
            }
        }

        .er-tbl-footer {
            text-align: right;
        }

        .er-table-drawer {
            padding:$sp1;
            border-bottom:$billing-border;
            dd, dt {
                font-size:$sp1;
                display:inline-block;
            }
        }

        .authorizeError {
            padding: 0 0 $sph05 $sph1;
        }

        .authorization-conf {
           padding:0 $sp1+$sp05;
           margin:0;
        }

        .ez-auth-form {
            .pcLabel, .prLabel {
                font-weight: 300;
                position: relative;
                padding-left: $sp1 + $sp05;
            }
            .pcLabel:before, .prLabel:before {
                position: absolute;
                background-color: #fff;
                left: 0;
                top: .2rem;
            }
            .pfCheckbox { margin-bottom:$sp05; }
        }

        .ez-conf-note {
            @extend .body-sm;
            border:1px solid $gray;
            height: $sph2*3;
            overflow-y:auto;
            margin-bottom: $sp2;
            padding:$sp1;
            padding-left:$sp2;
            li {
                padding-bottom:$sp05;
                &:first-child {
                    padding-left:0;
                    list-style: none;
                }
            }
        }

        @media screen and ( min-width:$breakpoint-sm ) {
            .ez-marg {
                margin-left: $sp2;
                margin-right: $sp2;
            }
        }
    }

    .ez-confirm {
        .negative-value {
            background-color: #d20a3c;
            color:white;
            font-family: Lato;
            font-size: 10px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1px;
            text-align: center;
            padding: 4px 6px 3px 7px;
            border-radius: 2px;
          }

          .positive-value{

            background-color: #108808;
            color:white;
            font-family: Lato;
            font-size: 10px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1px;
            text-align: center;
            padding: 4px 6px 3px 7px;
            border-radius: 2px;
          }
        margin-bottom:$sp2;
        .er-tbl-footer {
            text-align: left;
        }
        .ez-conf-print {
            margin:$sp2 0;
        }
        .er-table {
            .er-fi {
                flex-basis: 100%;
                padding-bottom:$sp05;
            }
            .er-table-row {
                flex-flow: column;
                align-items: flex-start;

            }
        }

        @media screen and ( min-width:$breakpoint-sm ) {
            .er-tbl-footer {
                text-align: right;
            }
            .er-table {
                .er-fi {
                    flex-basis: 20%;
                    flex-shrink: 1;
                    padding-bottom:0;
                    &.ez-conf-date {
                        flex-basis: 30%;
                    }
                    &.ez-conf-amount {
                        flex-basis: 10%;
                    }
                }
                .er-table-row {
                    flex-flow: row;
                    align-items: center;
                }
            }
        }
    }

}

.ez-submit {
    padding:$sp1;
    left:0;
    right:0;
    position: fixed;
    transition:all 500ms ease;
    width: 100%;
    z-index: 100;
    bottom:-200px;
    justify-content: space-around;
    flex-direction: column;
    .totalAmt {
        color: $base-color;
        text-align: center;
        margin-bottom:$sp1;
    }
    .er-page-actions {
        padding:$sp1 0;
    }
    &.sticky {
        bottom: 0;
    }
    &.inline {
        position:static;
        margin-bottom:$sph1;
    }
    .ezeSubmitActions {
        margin-bottom:$sp05;
        .btn-secondary-dk { margin-right:$sp05; }
    }
    .ezeCancel {
        margin-top:$sp025;
        color: $base-color;
      &:hover,
        &:focus,
        &:active {
            color:$base-color !important;
            outline: none !important;
        }
    }
    @media screen and ( min-width:$breakpoint-sm ) {
        flex-direction: row;
        .totalAmt { margin-bottom:0;}
        .ezeSubmitActions { margin-bottom: 0; }
    }
}

.sticky-tracker {
    height:1px;
    opacity: 0;
    &.push {
        margin-bottom:$sph2*2;
    }
}

.ez-multi-group-modal {
    .pfRadio {
        text-align: left;
    }
    .prLabel {
        display:block;
        float:none;
        margin-left:0;
    }
    .btn-primary {
        margin-right:$sp1;
    }
    .modal-component-footer {
        margin-top:$sp2;
    }
}

.ez-bank-name{
    margin-left: 79px;
    margin-bottom: 26px;
}


