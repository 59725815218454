//@import "../../../node_modules/@anthem/fwc/dist/assets/styles/components/fwc-checkbox";
//

/*
    Style only for Motif Checkbox
    Dependencies: icons.css, vars.css
*/

.fwc-checkbox input {
  position:absolute;
  left:-99999px;
}
.fwc-checkbox input[disabled] + label {
  opacity: 0.3;
  cursor: default;
  pointer-events: none;
}
.fwc-checkbox input[disabled] + label::before {
  cursor: default;
  pointer-events: none;
}
.fwc-checkbox label {
  font-weight: normal;
  font-size: 14px;
  display:flex;
  cursor:pointer;
  align-items:center;
  justify-content:flex-start;
  color: #333333;
}

.fwc-checkbox label::before {
  content: "\eb31";
  color: rgba(255,255,255, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: var(--fwcu);
  background-color: white;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-gray-three);
  border-radius: calc(.5*var(--fwcu));
  cursor: pointer;
  font-family: 'employer-portal-motif' !important;
  font-size: calc(1.25*var(--fwcu));
  line-height: 1;
  width: calc(2*var(--fwcu));
  height: calc(2*var(--fwcu));

}

.fwc-checkbox input:checked + label:before {
  color: white;
  background-color:var(--color-primary);
}

.fwc-checkbox input:focus + label:before {
  outline: 2px solid var(--color-primary);
  outline-offset: 4px;
}
