@import "../../../src/fwc-assets/styles/components/fwc-input";


//FWC PL OVERRIDES
.fwc-error-container {
  margin-top: 5px;
  .epm-icon-attention{
    &:before {
      font-size: 14px;
      color: var(--color-dviz-five);
      font-weight: 400;
      margin-right: 5px;
      line-height: 1.2em;
      vertical-align: middle;
    }
  }

  .epm-icon-delete{
    &:before {
      font-size: 14px;
      color: var(--color-dviz-five);
      font-weight: 400;
      margin-right: 5px;
      line-height: 1.2em;
      vertical-align: middle;
    }
  }

  .fwc-error-message {
    text-align: left;
    text-indent: -22px;
    margin-left: 0;
    font-size: 14px;
    color: var(--color-dviz-five);
    font-weight: 400;
  }
  .fwc-error-message-color-change {
    text-align: left;
    text-indent: -22px;
    &:before{
      @extend %err-pseudo;
      content: "\f24b";
    }
    &-green{
      color: $accent-color-teal;
      &:before{
        @extend %err-pseudo;
        content: "\f17b";
      }
    }
    margin-left: 0;
    font-size: 14px;
    color: var(--color-dviz-five);
    font-weight: 400;
  }
}
.fwc-autocomplete {
  .fwc-search-input {
    background: white;
    border-radius: 8px;
    border-color: var(--color-gray-four);
    font-size: 14px;
    height: fit-content;
    padding: 0px 10px;
    align-items: center;
    .epm-icon-search-1 {
      font-size: 20px;
      color: var(--color-gray-three);
      margin-right: 10px;
      transform: scaleX(-1);
      width: 27px;
    }
    .epm-icon-delete {
      font-size: 16px;
      color: var(--color-gray-two);
      cursor: pointer;
    }
  }
  input::-webkit-calendar-picker-indicator {
    display: none !important;
  }
}
.fwc-smart-search {
  input::-webkit-calendar-picker-indicator {
    display: none !important;
  }
}
.label-w-tooltip {
  display: flex;
  label {
    margin-right: 5px;
  }
}
.icon-modified{
  display: flex;
  align-items: center;
  .fa{
    color:$neutral-dark-gray;
    font-size:1.5rem;
    margin-right:5px;
  }
}
