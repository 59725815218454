.fwc-header-page-slotted,
.fwc-header-label .fwc-label {
  color: var(--color-white);
}
.fwc-header-page-slotted .fwc-row {
  width: 100%;
}
.fwc-header-page-slotted .fwc-header-page-sub {
  font-size: calc(2 * var(--fwcu));
  margin-top: var(--fwcu);
}
