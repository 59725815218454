.cdk-overlay-container .cdk-overlay-pane {
    width: 100%;
  }

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}

.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-pane {
  display: -webkit-box;
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  pointer-events: auto;
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  -webkit-transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}

@media screen and (-ms-high-contrast: active) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.6;
  }
}

.cdk-overlay-transparent-backdrop,
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.uxd-filter-button-group {
    // 1. Filter Button Group
    $data-filter-border-color: map-get($ant-motif-grays, 300);
    display: inline-flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: flex-start;
    width: auto;
  
    // * 2. Checkbox / Radio filters
    .uxd-filter-button {
      display: flex;
      background: $white;
      font-family: $font-family-Lato;
      font-size: 1rem;
      -webkit-appearance: none;
      padding: spacer(4) spacer(7);
      align-items: center;
      justify-content: center;
      border-top: thin solid $data-filter-border-color;
      border-bottom: thin solid $data-filter-border-color;
      border-right: none;
      border-left: none;
  
      &:nth-child(2) {
        border-left: thin solid $data-filter-border-color;
        border-right: thin solid $data-filter-border-color;
      }
  
      &:first-child {
        border-left: thin solid $data-filter-border-color;
      }
  
      &:nth-child(3) {
        border-right: thin solid $data-filter-border-color;
      }
  
      &.is-checked,
      &.selected {
        border: thin solid $ant-motif-highlight-ocean;
        color: $ant-motif-highlight-ocean;
        z-index: 20;
      }
    }
  
    // 3. Filter button add-ons (icons, alignment, etc.)
    .icon {
      font-size: calculateRem(20px, 14px);
      color: highlight("highlight-two");
      margin-right: spacer(4);
    }
  }
  
  // **
  // * Filter Button Label
  .uxd-filter-label {
    display: block;
    font-family: $font-family-Lato;
    font-size: 12px;
    font-weight: bold;
    color: gray("300");
  }
  
  /** override .cdk-overlay-pane padding styles */
  .ant-filter-mobile-overlay {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  
  // **
  // * Filter Panel
  // *
  // * 1. comment
  // *
  .btn.btn-filter {
    min-width: 250px;
    color: map-get($ant-motif-grays, 300);
    border: 1px solid map-get($ant-motif-grays, 300);
    background: $white;
    padding: 1rem 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    .motif-sort-down-filled {
      color: map-get($ant-motif-grays, 600);
    }
 
  }
  .filter-panel {
    background: #fbfbfb;
    width: 100%;
  
    .filter-header {
      width: 100%;
      background-image: url('/assets/images/motif/blue-tall.png');
      background-repeat: no-repeat;
      background-size: cover;
      font-weight: 300;
      color: $white;
      font-size: calculateRem(28px);
      letter-spacing: 0;
      line-height: calculrateRem(34px);
      padding: calculateRem(51px) 0 calculateRem(20px) calculateRem(15px);
    }
    .filter-container {
      padding: calculateRem(32px);
      max-height: 75vh;
      overflow-y: scroll;
    }
    .filter-reset {
      display: flex;
      align-items: center;
  
      .motif-icon.motif-recurring-appointment {
        margin-right: calculateRem(10px);
        font-size: calculateRem(18px);
        color: map-get($ant-motif-grays, 300);
      }
  
      .filter-reset-text {
        font-weight: 600;
      }
    }
    .filter-reset.desktop, .filter-cancel.desktop {
      display: none;
    }
  
    .filter-buttons {
      padding: calculateRem(24px) calculateRem(32px);
      box-shadow: 0 6px 20px 0 rgba(51, 60, 70, 0.3);
      position: fixed;
      bottom: 0;
      width: 100vw;
      background-color: #fbfbfb;
      z-index: 999;
      .epm-icon-recurring-appointment {
        color: var(--color-gray-three);
      }
  
      .filter-apply-cancel {
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
  
      @media screen and (min-width: $breakpoint-xs) {
        position: relative;
        width: 100%;
        box-shadow: none;
      }
    }
  
    .reset-filters {
      display: flex;
      align-items: center;
      background: transparent;
      color: color("blue");
      font-weight: bold;
  
      .reset-icon {
        margin-right: 4px;
        color: gray("400");
        font-size: 1.5rem;
      }
  
      &__text {
        text-decoration: underline;
      }
  
      &:not(:focus) {
        border: none;
        outline: none;
      }
    }
  
    .uxd-filter-label {
      display: block;
      font-family: "Lato";
      font-size: 12px;
      font-weight: bold;
      color: gray("300");
    }
  }
  .cdk-overlay-container {
    .ant-filter-desktop-overlay.cdk-overlay-pane {
      padding: 1rem 0;
    }
  }
  
  @media screen and (min-width: $breakpoint-xs) {
    .filter-panel {
      box-shadow: 0 26px 90px 0 rgba(51, 60, 70, 0.3);
      width: calculateRem(400px);
  
      .filter-reset.mobile, .filter-cancel.mobile {
        display: none;
      }
      .filter-cancel.desktop {
        display: inline-block;
      }
      .filter-buttons {
        border-top: 1px solid $ant-motif-silver;
        display: flex;
        align-items: center;
        justify-content: space-between;
  
        .filter-reset.desktop {
          display: inline-block;
        }
        .filter-apply-cancel {
          display: inline-block;
        }
      }
    }
  }
  
  @media screen and (min-width: $breakpoint-md) {
    .filter-panel {
      width: calculateRem(600px);
    }
  }
  
  @media screen and (min-width: $breakpoint-lg) {
    .filter-panel {
      width: calculateRem(775px);
    }
  }
  
  .filter-panel-trigger {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: calculateRem(14px);
    font-weight: 400;
    font-family: $font-family-Lato;
    padding: spacer(4) spacer(13);
   
  
   
  
    &::after {
      font-family: $motif-font-family;
      content: $motif-sort-down-filled;
    }
  
    &.is-expanded {
      &::after {
        font-family: $motif-font-family;
        content: $motif-sort-up-filled;
      }
    }
  }
