.eet-setup-container {
 padding: 0 calc(100% - 86%);
  .uxd-error-container {
    margin-top: 8px;
  }
  .er-flex_input {
    margin-bottom: 12px;
  }
  @media screen and (max-width: 1240px){
    padding: 0 calc(100% - 95%);
  }
  @media (min-width: 801px) {
    .er-flex_input {
      flex: 0 1 47%;
    }
  }
  .eet-select-product-group {
    .pcLabel,
    .prLabel {
      font-weight: normal;
    }
  }
  .eet-sub-head {
    font-size: 24px;
    font-weight: normal;
    margin-bottom: .85rem;
    margin-top: 2rem;
    &.no-top-margin {
      margin-top: 0;
    }
  }
  h1 {
    margin-top: 2rem;
  }
  .ant-search-input {
    width: 100%;
    border-bottom: 1px solid !important;
  }
  .er-reg-select {
    padding-bottom: 0;
  }
  .ant-search-button {
    display: none;
  }
  .ant-text-input {
    border: none;
    border-radius: 0;
  }
  .er-user-information{
	  .psDropdown {
	    width: 100%;
	    margin-left: 0;
	    margin-top: 37px;
	  }
  }
  .er-flex-form_wrapper {
    justify-content: space-between !important;
  }
  .select-group-container {
    fieldset span {
      display: flex;
      flex-direction: column;
    }
  }
}
.er-user-agreement {
  .uxd-text-input label.uxd-text-input__label.is-focused {
    color: var(--color-gray-one);
    font-weight: bold;
    font-size: var(--default-font-size);
    font-family: var(--font-sans-serif);
  }
  .uxd-text-input label.uxd-text-input__label {
    position: unset;
    padding-left: 0;
    transition: none;
    color: var(--color-gray-one);
    font-weight: bold;
    font-size: var(--default-font-size);
    font-family: var(--font-sans-serif);
  }
  .uxd-text-input input {
    font-size: calc(2 * var(--fwcu));
    border: 1px solid var(--color-gray-three);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow-light);
    color: var(--color-gray-one);
    padding: calc(0.5 * var(--fwcu)) calc(2 * var(--fwcu));
    height: calc(5 * var(--fwcu));
    width: 100%;
  }
  .uxd-text-input input:focus {
    outline: var(--fwc-focus);
  }
  .uxd-text-input .bottom-bar {
    display: none;
  }
  .uxd-text-input .uxd-error-message {
    margin-left: 10px;
    font-size: var(--default-font-size);
  }
  my-date-picker .mydp .btnpicker {
    margin-right: 5px;
  }
  .mydp input {
    border: none !important;
    box-shadow: none;
  }
  .mydp a {
    text-decoration: none;
  }
}
