// site footer
.er-footer-margin {
  margin-top: 4rem;
}
.er-footer {
    padding:($sp2 + $sp1) 0;
    background-color:$primary-color-light-blue;
    color:$base-color;
    bottom:0;
    a {  
        border-bottom:none;
    }
    .ant-social {
        display: flex;
        align-items:center;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom:$sp1 + $sp2;
        .fa {
            margin: 0 10px;
            height: 3rem;
            width: 3rem;
            background-color: #fff;
            color: $primary-color-light-blue;
            border-radius: 50%;
            text-align: center;
            font-size: 1.714rem;
            padding-top: .643rem;
        }
        .social-fi {
            margin-top:$sp1 + $sp2;
        }
    }
    .ant-copyright {
        font-size:.778rem;
    }
    .ant-app-promo {
        @media screen and (max-width: 575px) {
            margin:auto;
        }
    }
    .ant-footer-link-list li:last-child {
        @media screen and (min-width:$breakpoint-xs) {
            margin-bottom:0;
        }
        
    }
    .taglines a {
        text-decoration: underline;
        color: $base-color;
    }
}
