/*
    First step to getting a shared SCSS across both projects.
    TODO: Refine as necesarry
*/

@import './partials/variables';
@import './partials/pl-overrides';
@import './partials/structure';
@import './partials/footer';
@import './partials/text-mask';
@import './partials/easypay';
@import './partials/expandTable';
@import './partials/print';
@import './partials/er-select-box';
@import './partials/pagination';
@import './partials/range-slider';
@import'./partials/header';
@import './partials/datePicker';
@import './partials/support';
@import './partials/chat';
@import './motif-styles/motif-styles';
@import './partials/video';



//Move
.bg-primary-dark {
    background: $primary-color-dark-blue;
}
.bg-primary-light {
    background:$primary-color-light-blue;
}
.bg-gray-medium {
    background:$medium-gray;
}
.bg-gray-light {
    background:$light-gray;
}
.bg-base {
    background:$base-color;
}

//TODO: check if this is in pattern
body{
    background:$base-color;
}

a { cursor: pointer; }

.txt-right { text-align: right; }
.txt-btn {
    border:none;
    background:none;
}
.er-pos-rel { position: relative; }
.er-amt-clr { color: $neutral-dark-gray; }
.er-local-time {
    color:$gray;
    @extend .body-sm;
}
.er-ul {
    list-style:none;
    margin:0;
}
.er-cents {
    display: flex;
    align-items: flex-start;
    text-align: right;
    span {
        @extend .body-sm;

    }
}
.er-single-space {
    margin-bottom:0;
    padding-bottom:0;
}

.er-btn-lnk {
    color:$primary-color-light-blue;
    border:none;
    padding:0;
    background:none;
    text-decoration: none;
    text-align:left;
    span {
        border-bottom: 1px solid;
    }
    &:active, &:hover {
        outline: 0;
        color:$primary-color-dark-blue;
        background:none;
    }
    &:focus {
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
        color:$primary-color-dark-blue;
        background:none;
    }
}

.er-flex {
  display: flex;
  align-items: center;

  &.j-between {
    justify-content: space-between;
  }
  &.j-center {
    justify-content: center;
  }
  &.j-end {
    justify-content: flex-end;
  }
  &.a-start {
    align-items: flex-start;
  }
  &.a-end {
    align-items: flex-end;
  }
  &.a-center {
    align-items: center;
  }
}
.er-fi { flex:1 1 auto; }

.er-table-header {
    @extend .body-sm;
    font-weight: 800;
}

.er-word-break {
    word-wrap: break-word;
}
.er-break-all {
    word-break: break-all;
}

.er-no-scroll {
    overflow:hidden;
    height:100vh;
}

.er-page-title {
    padding:$sp2 $sp1;
}

.er-content-header {
  font-size: $font-size-header;
}

//One plus to rule the all!
.er-expand-plus {
    padding-bottom: 15px;
    font-size: 2.5rem;
    color:$primary-color-blue;
    font-family: 'FontAwesome';
    &:before {
        display:inline-block;
        content: "\002b";
    }
    &.open:before {
        content: "\2212";
    }
    &.hidden {
        display:none;
    }
  }

// alerts
.er-alert {
    position: fixed;
    top:$header-offset;
    padding-left:0;
    padding-right:0;
    left:0;
    right:0;
    z-index: 200;

    .er-alert-close {
       border:none;
       background:none;
        margin-left: auto;
       .fa-times { color: $gray-2;}
    }
    .exception-error-id {
        padding-top: 10px;
    }
        .media-left{
            background-color:$primary-color-blue;
        }

    .ant-anthem-alert .media-body {
        padding:$sp1;
        & > p {
            color:$gray;
            & > span {
                 @extend .er-flex;
                justify-content: space-between;
                align-items: flex-start;
                span { flex: 1;}
            }
        }
        // use for custom template container class
        .er-custom-alert-container {
            flex-basis: 100%;
        }
    }

    @media screen and (min-width: $breakpoint-sm) {
        // padding-left:15px;
        // padding-right:15px;
    }
}

.er-dynamic-alert {
    @extend .er-alert;
    top:-($header-offset + $sph1);
    transition:top 500ms ease-in-out;


    .container-fluid{
        background-color:transparent;
    }
    &.da-open {
        top:$header-offset;

    }
    .ant-anthem-alert{
        margin-bottom:0;
    }
}
.paper-slider {
    //Slider header is 10px smaller
    .er-dynamic-alert.da-open {
         top:$sph1+$sph2;
         font-size: 1rem;

         .ant-anthem-alert .media-body{
             flex-basis: 100%;
         }
    }
}

.er-loader {
    display:none;
    & > div {
        opacity:0;
        transition:opacity 500ms ease;
    }
    &.open {
        display:block;
        & > div { opacity: 1; }
    }
    .ant-ajax-load { padding: $sp2; }
}

.er-expand-toggle {
    color:$primary-color-blue;
    font-family: 'FontAwesome';
    padding:0;
    &::before {
        display:inline-block;
        content: "\e925";
        transform:rotate(90deg);
    }
    &.open:before {
        transform:rotate(-90deg);
    }
    &.hidden {
        display:none;
    }
}

.er-inline-alert {
    p { margin-bottom:$sp1; }
}

.er-modal {
    .modal-content {
        padding: 3rem 1rem 1rem;
    }
    .modal-header, .modal-body {
        text-align: center;
    }
    .modal-header .close {
        position: absolute;
        top:$sp1;
        right:$sp1;
        border-color:$accent-color-salmon;
        background:$accent-color-salmon;
        .fa { color:$base-color; }
        &:focus { box-shadow: 0px 0px 5px 2px $accent-color-red; }
    }
    .modal-component-footer.er-flex {
        justify-content: center;
    }

    .modal-body { padding-bottom:0; }
    .modal-footer {
        position: static;
        background: none;
        transform: none;
    }

    @media screen and (min-width: $breakpoint-xs) {
        & .modal-header .close {
           background:$base-color;
           .fa { color: $accent-color-salmon; }
        }
    }
}
.mobile-strong, .er-strong { font-weight: 800; }

.er-page-actions {
    justify-content: center;
    flex-wrap: wrap;
    padding:$sp2 0;

    .er-fi { width:100%; }
    &.no-padding{
        padding:0;
    }
    &.prm-left {
        .btn-secondary { margin:$sp1 0 0 0; }
    }
    &.prm-right {
        flex-direction: column-reverse;
        .btn-secondary { margin:$sp1 0 0 0; }
    }

    &.tertiary {
        padding-bottom:$sp1;
    }

    @media screen and (min-width: $breakpoint-sm) {
        flex-wrap: nowrap;
        padding:$sp4 0;
        .er-fi {
            flex:0 0 auto;
            width:auto;
        }
        &.prm-left {
            .btn-secondary{ margin:0 0 0 $sp1; }
        }
        &.prm-right {
            flex-direction: row;
            .er-fi { width:auto; }
            .btn-secondary{ margin:0 $sp1 0 0; }
        }
    }
}

.btn-dkz-secondary {
    @extend .btn-primary;
    background-color:$primary-color-blue;
    border-color:$primary-color-blue;
    &:hover {
        background-color:$primary-color-dark-blue;
        border-color:$primary-color-dark-blue;
    }
}

@media screen and (min-width: $breakpoint-sm) {
    .mobile-title, .er-break-sm-hide { display:none; }
    .mobile-strong { font-weight: 400; }
}


.text-underline {
  text-decoration: underline;
}

.profile-text-btn {
    border:none;
    background:none;
    outline: none;
}
.fa-angle-double-left-new:before {
  content:"\f10e"
}

.logo-parent{
    display: flex;
    .logo-child2{
        display: flex;
        align-items: center;
        justify-content: center;
        .logo-brand-item{
            margin-right: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .co-branded-image{
            max-width: 13vw;
        }
    }

    .double-image{
        margin-right: 10px;
    }

    .border-cobrand {
        margin-left: 0;
        margin-right: 10px;
        border-right: 2px solid #d6d6d6 !important;
        max-height: 60px;
        height: 60px;
    }

    .borderless-cobrand {
        margin-left: 0;
        margin-right: 10px;
        max-height: 60px;
        height: 60px;
    }

    /* Medium level laptops ands desktops */
    @media screen and (max-width: 1365px) and (min-width: 1024px){
        .logo-brand-img-container{
            img {
                max-width: 25vw;
            }
        }
    }

    /* tablet, landscape iPad, lo-res laptops ands desktops */
    @media only screen and (max-device-width : 1024px)  {
        .logo-child-exists svg{
            max-width: 200px;
            max-height: 100px;
        }
        .co-branded-image{
            max-width: 200px;
            max-height: 100px;
        }
    }

    /* smartphones, Android phones, landscape iPhone */
    @media only screen and (max-device-width : 480px) {
        .logo-child-exists svg {
            max-width: 125px;
            max-height: 50px;
        }
        .co-branded-image{
            max-width: 125px;
            max-height: 50px;
        }
        .border-cobrand{
            margin-left: 1px;
            margin-right: 7px;
        }
        .logo-brand-img-container{
            img {
                max-width: 125px;
                max-height: 50px;
            }
        }
    }

}
a.ant-arrow-previous {
  border-bottom: none;
  text-decoration: none}
a.ant-arrow-previous:before {
  content: "\e920";
  padding-right: 4px; }

a.ant-arrow-next {
  border-bottom: none;
  text-decoration: none}
a.ant-arrow-next:after {
  content: "\e925";
  padding-left: 4px; }

a.ant-arrow-up {
  border-bottom: none;
  text-decoration: none}
a.ant-arrow-up:after {
  content: "\f113";
  padding-left: 4px; }

a.ant-arrow-down {
  border-bottom: none;
  text-decoration: none}
a.ant-arrow-down:after {
  content: "\f110";
  padding-left: 4px; }
