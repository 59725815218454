$er-blue-cyan:$primary-color-light-blue;
.coverage-select-box-wrapper{
  margin:auto;
  width:80%;
  .pcLabel{
    float: inherit;
  }
}
.oss-checkbox-box-group{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1.25rem;
    margin-bottom: 3rem;
    .sr-only{
      position:absolute;
    }
  }
  
  .oss-checkbox-box{
    display: block;
    max-width: 100%;
    position: relative;
    flex:1 0 auto;
  
  
    //position relative on the individual box for overlay absolute positioning
    .oss-checkbox-box__inner {
      border:2px solid $er-blue-cyan;
      border-radius: 10px;
      margin-top: .55rem;
      // background-color: #00609A;
      position: relative;
      // color: #FFFFFF;
      // text-align: center;
      font-weight: 300;
    }
    input.oss-checkbox-box__input:checked{
      //animation is only set with the active class to have no removal animation.
      & ~ label {
        // background-color: $er-blue-cyan;
        &:before {
          border:1px solid black;
          text-align: center;
         // transition: opacity 1ms linear, left 750ms linear, transform 750ms linear, color 750ms linear;
          color: $er-blue-cyan;
          opacity: 100;
         // transform: translateY(-50%) scale(1);
          left: 10px;
          box-shadow: 0 0 3px #0079c2;
        }
      }
  
      //this is the styling that occurs when it is made active, it makes it fully visible (opacity 100) and then becomes transparent
      //animation is only set with the active class to have no removal animation.
      // & ~ .oss-checkbox-box__overlay {
      //   opacity: 100;
      //   background-color: transparent;
      //   transition: background-color 1.25s linear;
      //   &:after {
      //     color: transparent;
      //     transition: color 1.25s linear;
      //   }
      // }
    }

    input.oss-checkbox-box__input:disabled{
      //animation is only set with the active class to have no removal animation.
      & ~ label {
        // background-color: $er-blue-cyan;
        &:before {
          border:1px solid #cbc6c6;
        }
      }

    }
  
    //style focus and hover
    input.oss-checkbox-box__input:focus + label.oss-checkbox-box__label,
    label.oss-checkbox-box__label:hover
    {
      box-shadow:0 0 3px 2px $er-blue-cyan;
    }
  
    label.oss-checkbox-box__label{
        width: 100%;
        bottom: 0;
        top: 0;
        left: 0;
        padding-top: 25px;
        padding-bottom: 25px;
        padding-left: 70px;
        padding-right: 33px;
        margin-bottom: 0px;
        cursor: pointer;
        font-weight: 600;
  
        //z-index to show over the overlay when triggered
        &:before {
          content: "\f17b";
          z-index: 1;
          display: block;
          position: absolute;
          width: 40px;
          height: 40px;
          line-height: 40px;
          left: 10px;
          top: 50%;
          border-radius: 10px;
          font-size: 25px;
          font-family: 'FontAwesome';
         // opacity: 0;
          transform: translateY(-50%) scale(.6);
          color: transparent;
          border: solid 1px black;
           // background-color: $er-blue-cyan;
        }
  
    }
  
    //hide the input
    input.oss-checkbox-box__input {
      position: absolute;
      left: -9999px;
    }
  
    div.oss-checkbox-box__overlay {
      opacity: 0;
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      pointer-events: none;
      background-color: transparentize($er-blue-cyan, .1);
  
      &:after {
        content: "\f17b";
        font-family: 'FontAwesome';
        font-size: 5rem;
        height: 100%;
        color: $er-blue-cyan;
        display: flex;
        align-items: center;
        transform: translateX(-50%);
        position: absolute;
        left: 50%;
      }
    }
  }
  // @media screen and (min-width: $breakpoint-sm) {
  
  //   .oss-checkbox-box-group{
  //     flex-direction: row;
  //     flex-wrap: wrap;
  //     justify-content: center;
  //     margin-top: 0px;
  //     margin-bottom: 3.5rem;
  //   }
  //   .oss-checkbox-box{
  //     min-width: 220px;
  //     max-width: 46%;
  //     margin-left: .5rem;
  //     margin-right: .5rem;
  
  //     //position relative on the individual box for overlay absolute positioning
  //     .oss-checkbox-box__inner {
  //       height: 0;
  //       padding-bottom: 100%;
  //       margin-top: 1.75rem;
  //     }
  //     input.oss-checkbox-box__input:checked{
  //       & ~ label {
  //         background-color: $er-blue-cyan;
  //         &:before {
  //           transition: opacity 1ms linear, top 750ms linear, transform 750ms linear, color 750ms linear;
  //           transform: translateX(-50%) scale(1);
  //           left: 50%;
  //           top: -25px;
  //         }
  //       }
  //     }
  
  //     label.oss-checkbox-box__label {
  //       position: absolute;
  //       display: flex;
  //       justify-content: center;
  //       flex-direction: column;
  //       padding-top: 0px;
  //       padding-bottom: 0px;
  //       padding-left: 6%;
  //       padding-right: 6%;
  //       margin-bottom: 0px;
  
  //       //make the text larger for the emphasized text
  //       span {
  //         font-size: 2.5rem;
  //       }
  
  //       &:before {
  //         width: 60px;
  //         height: 60px;
  //         line-height: 60px;
  //         font-size: calculateRem(32px);
  //         top: 10px;
  //         left: 50%;
  //         transform: translateX(-50%) scale(.6);
  //       }
  //     }
  //     div.oss-checkbox-box__overlay:after {
  //       font-size: 12rem;
  //     }
  //   }
  // }
  // @media screen and (min-width: $breakpoint-lg) {
  //   .oss-checkbox-box {
  //     min-width: 220px;
  //     max-width: 30.33%;
  //   }
  // }
