.er-form, .er-btn{
    margin:2rem 0;
    width:100%;

}
.er-btn{
    &-secondary{
        margin:3rem 0 0 0;
    }
}

.er-dropDown {
    .pfSelect {
        button {
            border: none;
            border-radius: 0;
            border-bottom:  1px solid #000;
            background-color: transparent;
            .psArrow {
                border: none;
            }
        }
    }
}

@media screen and ( min-width:$breakpoint-xs ){
    .er-btn{
        width:auto;
        &-secondary{
            margin: 2rem 1rem 2rem 0;
        }
    }
}
// from pl partials/variables
// $breakpoint-xs: 480px !default;
// $breakpoint-sm: 801px !default;
// $breakpoint-md: 1024px !default;
// $breakpoint-lg: 1200px !default;

//Structural Styles
.er-padding-top_sm {
  padding-top: 1.5rem;
}
