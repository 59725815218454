.er-groupcase-list{
    text-align: left;
    display: flex;
    padding-top: 1rem;
    dd {
        padding-left: 1rem;
    }
}

.er-btn-reverse {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
    .er-btn{
        margin: 1rem .5rem 1rem 0;
    }
    @media screen and ( min-width:$breakpoint-xs ){
        .er-btn{
            margin: 2rem 1rem 2rem 0;
        }
    }
}

.er-reg-background-image{
  .container-fluid {
    background-color: transparent;
  }
}

//registration main header
.er-reg-page-header {
    color:$base-color;
    padding:$sp2 0;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    font-weight: 200;

    .flex-item:first-child {
        padding-right:$sp1;
    }
}

//For first element after main header
// .er-header-offset { margin-top:$header-offset; }

.er-reg-error {
    text-align: left;
}

.er-reg-select {
    padding-bottom: 1rem;
    legend {
        @extend .sr-only;
    }
}

// Flex Input Styles
.er-flex-form_wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.er-justify-center{
	justify-content: space-around;
}

.er-user-agreement {
  .er-flex-form_wrapper {
    justify-content: space-around;
  }
  .grp-eff-date-cont {
    position: relative;

    shared-app-date-picker {
      width: 100%
    }

    .tool-tip-cont {
      position: absolute;
      right: -35px;
      top: 30px;
    }
  }
}


  .er-flex-center {
  display: flex;
  align-items: center;
}

.er-flex_input {
  flex: 0 1 100%;
}

@media (min-width: $breakpoint-sm) {
  .er-flex_input {
    flex: 0 1 45%;
    &--small {
      flex: 0 1 15%;
    }
  }
}

//Toggle
.er-toggle {
  a {
    vertical-align: middle;
    display: table-cell;
    padding: .6rem;
    border-bottom: 0 solid transparent;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
  .fa:before {
    content: "\002b";
    font-weight: 700;
    top: -2px;
    position: relative;
  }
}

.er-group-information {
  @media screen and (min-width: $breakpoint-xs) {
    .er-btn-reverse .er-btn {
      margin: 2rem 1rem 2rem 1rem;
    }
  }
}

//Border bottom class
.er-user-information,
.er-group-information,
.er-user-agreement {
  .border-bottom-ur {
    border-bottom:2px solid $medium-gray;
    padding-bottom: 2rem;
  }

  @media screen and (min-width: 801px) {
    .er-page-actions {
      padding: 4rem 0 0;
    }

    .er-cancel-link {
      padding-top: 1.5rem;
    }
  }
  .grp-eff-message {
    margin-top: -2rem;
    margin-bottom: 3rem;
    font-size: 15px;
  }
}

//Grey box for form
.er-gray-box {
  background-color: $light-gray;
  padding: 2rem 0 2rem 1.8rem;
  margin-top: 2.2rem;

  p {
    margin: 0;
  }
}

//Radio button label and toggle overrides
.er-user-information {
  div[data-uxd-form-group-cmp] {
    legend {
      color: $primary-color-light-blue;
      border-bottom: none;
      font-size: 1rem;
    }

    .prLabel {
      padding-left: 0;
    }
  }
  @media screen and (min-width: 1024px) {
    .toggle-wrapper {
      align-items: center;
    }
  }
}

.er-member-controls__wrapper {
  display: flex;
  flex-flow: row wrap;

  .er-member-child {
    width: 100%;
    padding-bottom: 2rem;
    .pfCheckbox legend {
      margin-bottom: 0.7143rem;
      border: none;
      font-size: 1rem;
      font-weight: 600;
    }
  }

  @media screen and (min-width: $breakpoint-sm) {
    .er-member-child {
      width: 50%;
    }
  }
}

.er-checkbox-parent:not(.align-nasco) {
  .alignVertical:not(.er-select-all) {
    margin-left: -15px;
    display: block;
  }
}

.align-nasco {
  .alignVertical:not(.er-select-all) {
    display: block;
    max-height: 200px;
    overflow-y: scroll;
    margin-left: -15px;
    box-shadow: inset 0 -12px 10px -10px rgba(0, 0, 0, 0.27);
  }
}

@media screen and (min-width: $breakpoint-sm) {
  .align-nasco {
    .alignVertical:not(.er-select-all) {
      max-height: 262px;
      box-shadow: none;
      .col-md-2 {
        padding-right: 0px;
      }
    }
  }
}

.bill-division-check{
  margin-left: 15px;
}

//User agreement Scss
.authorization-bottom {
  max-width:800px;
  margin:0 auto;
  padding:0 $sp1;
}

.authorization-notes {
  height:150px;
  border:1px solid $medium-gray;
  overflow-y:auto;
  padding:$sp1;
  margin-bottom:$sp2;
  -webkit-overflow-scrolling: touch;
  ul {
    padding-left: 1rem;
  }
}

.auth-note-text {
  text-transform: uppercase;
  padding-bottom: .2rem;
  font-weight: 600;
  font-size: .9rem;
}

.er-verification-name {
  text-transform: capitalize;
}

.user-id-wrapper {
  display: flex;
  align-items: flex-start;
  .user-id-input-container {
    min-width: 380px;
  }
  .id-tool-tip {
    padding-top: 30px;
  }
}

.er-flex_input {
  .input-disabled {
    label.uxd-text-input__label.is-focused {
      color: $black;
    }
    .bottom-bar {
      display: none;
    }
  }
}
//override for expand icon
app-employer-registration div[data-uxd-expand-collapse-wcs] .collapse-block .ant-collapse.blue h3.ant-collapse-heading .ant-collapse-button .fa{
  color: $primary-color-blue;
  //match .er-expand-plus
  font-size: 2.5rem;
  &:before {
    font-weight: 400;
  }
}

.gd-user-access-level-container {
  // display: flex;
  flex-wrap: wrap;
  padding: 20px;
  .uxd-error-container {
      flex-basis: 100%;
  }
  .gd-user-access-level-static {
      flex-basis: 100%;
      font-size: 1rem;
      font-weight: 600;
  }
  .gd-user-access-level-all-entities {
      flex-basis: 50%;
      align-self: center;
  }
  .gd-user-access-level-filter {
      display: flex;
      flex-basis: 38%;
      justify-content: flex-end;
      position: relative;
      >div {
          width: 100%;
      }
      input {
          padding-right: 20px;
          box-shadow: none;
      }
      .fa {
          position: absolute;
          right: 0;
          font-size: 1.1rem;
          color: #000000;
          top: 30px;
      }
  }
  .user-access-level-entities {
      width: 100%;
      max-height: 250px;
      overflow: auto;
      // justify-content: space-evenly;
      flex-basis: 100%;
      // display: grid;
      // grid-template-columns: repeat(4, 1fr);
      // grid-column-gap: 10px;
      // grid-row-gap: 10px;
      // .user-access-level-entities-disabled {
      //     font-size: 1rem
      // }
      display: flex;
      flex-flow: wrap;
      .user-access-column {
        margin-top: 1rem;
        flex-basis: 25%;
      }
  }
}
.rmb-heading {
  padding-top: 3.5rem;
  padding-bottom: 1rem;
  font-weight: 800;
}
.rmb-container {
  padding-left: 20px;
}
.rmb-divider {
  border:1px solid $medium-gray;
  max-width: 88%;
  margin-top: 2rem;
}
.rmb-sub-head {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 0.5rem;
  color: #0079c2;
}
.rmb-sub-head.top-section {
  margin-top: 0;
}

.uxd-error-container .uxd-error-message-list{
    text-align: left;
    &[hidden] { display:none; }
    font-size: 0.8571rem;
    color: $accent-color-red;
    font-weight: 400;
    margin: 5px 0px;
}