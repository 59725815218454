@keyframes selectorfadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
data-uxd-input-suffix-dir my-date-picker{
    position: absolute;
    right:0;
    top:25px;
    @media screen and (min-width: $breakpoint-sm){
        top:30px;
    }

}
.mydp {
    line-height: 1.1;
    display: inline-block;
    position: relative;
    
    .header {
        display: flex;
    }

    .selector {
        position: absolute;
        right: 0;
        padding: 0;
        // TODO: this should be dynamic based on postion on screen
        top: -220px;
        padding-bottom: 5px;
        border: 1px solid $gray;
        z-index: 100;
        animation: selectorfadein 0.1s;
        &:focus {
            outline: none;
        }
    }
    .selectorarrow {
        background: $base-color;
    }

    .selectiongroup {
        position: relative;
        display: table;
        border: none;
        border-spacing: 0;
       // background-color: $base-color;
    }

    .selection {
        width: 100%;
        outline: none;
        //background-color:$base-color;
        display: table-cell;
        position: absolute;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-left: 6px;
        border: none;
        color: $gray;
    }

    .invaliddate {
        background-color: $light-gray;
    }
}

.mydp .caltable tbody tr:nth-child(6) td:first-child,
.mydp .monthtable tbody tr:nth-child(4) td:first-child,
.mydp .yeartable tbody tr:nth-child(7) td:first-child {
    border-bottom-left-radius: 4px;
}

.mydp .caltable tbody tr:nth-child(6) td:last-child,
.mydp .monthtable tbody tr:nth-child(4) td:last-child,
.mydp .yeartable tbody tr:nth-child(7) td:last-child {
    border-bottom-right-radius: 4px;
}

.mydp .btnleftborderradius {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.mydp ::-ms-clear {
    display: none;
}

.mydp .selbtngroup {
    position: relative;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
    height: auto;
    display: table-cell;
    font-size: 0;
}

.mydp .btnpicker,
.mydp .btnclear,
.mydp .btndecrease,
.mydp .btnincrease {
    height: 100%;
    width: 26px;
    border: none;
    padding: 0;
    outline: 0;
    font: inherit;
    -moz-user-select: none;
}

.mydp .btnleftborder {
    border-left: 1px solid $medium-gray;
}

.mydp .btnpickerenabled,
.mydp .btnclearenabled,
.mydp .btndecreaseenabled,
.mydp .btnincreaseenabled,
.mydp .headertodaybtnenabled,
.mydp .headerbtnenabled,
.mydp .yearchangebtnenabled {
    cursor: pointer;
}

.mydp .selectiondisabled,
.mydp .btnpickerdisabled,
.mydp .btncleardisabled,
.mydp .btndecreasedisabled,
.mydp .btnincreasedisabled,
.mydp .headertodaybtndisabled,
.mydp .headerbtndisabled,
.mydp .yearchangebtndisabled {
    cursor: default;
    opacity:0;
    pointer-events: none;
}

.mydp .selectiondisabled {
    background-color: $base-color;
}

.mydp .btnpicker,
.mydp .btnclear,
.mydp .btndecrease,
.mydp .btnincrease,
.mydp .headertodaybtn {
    background: $base-color;
}

.mydp .header {
    width: 100%;
    background-color: $primary-color-light-blue;
}

.mydp .caltable,
.mydp .monthtable,
.mydp .yeartable {
    table-layout: fixed;
    width: 100%;
    height: calc(100% - 30px);
    background-color: $base-color;
    font-size: 14px;
}

.mydp .caltable,
.mydp .monthtable,
.mydp .yeartable,
.mydp .weekdaytitle,
.mydp .daycell,
.mydp .monthcell,
.mydp .yearcell {
    border-collapse: collapse;
    line-height: 1.1;
}

.mydp .weekdaytitle,
.mydp .daycell,
.mydp .monthcell,
.mydp .yearcell {
    text-align: center;
}

.mydp .weekdaytitle {
    font-weight: 700;
    padding-top: 12px;
    padding-bottom: 12px;
    vertical-align: middle;
    overflow: hidden;
    white-space: nowrap;
}

.mydp .weekdaytitleweeknbr {
    width: 20px;
    border-right: 1px solid $gray-2;
}

.mydp .monthcell {
    background-color: $light-gray;
    overflow: hidden;
    white-space: nowrap;
}

.mydp .yearcell {
    background-color: $light-gray;
    width: 20%;
}

.mydp .daycell .datevalue {
    background-color: inherit;
    vertical-align: middle;
    padding-top: 5px;
    padding-bottom: 5px;
}

.mydp .daycell .datevalue span {
    vertical-align: middle;
}

.mydp .daycellweeknbr {
    font-size: 10px;
    border-right: 1px solid $medium-gray;
    cursor: default;
    color: $neutral-dark-gray;
}

.mydp .inlinedp {
    position: relative;
    margin-top: -1px;
}

.mydp .prevmonth,
.mydp .nextmonth {
    display: none;
}

.mydp .disabled {
    cursor: default !important;
    color: $medium-gray;
    background: $light-gray;
}

.mydp .highlight {
    color: $accent-color-red;
}

.mydp .dimday {
    opacity: 0.5;
}

.mydp .markdate {
    position: absolute;
    width: 4px;
    height: 4px;
}

.mydp .markcurrday,
.mydp .markcurrmonth,
.mydp .markcurryear {
    text-decoration: underline;

}
.mydp .monthvalue {
    padding-top: 15px;
    padding-bottom: 15px;
}

.mydp .yearvalue {
    padding-top: 10px;
    padding-bottom: 10px;
}

.mydp .selectedday .datevalue,
.mydp .selectedmonth .monthvalue,
.mydp .selectedyear .yearvalue {
    background-color: $primary-color-light-blue;
    color: $white;
}

.mydp .yearchangebtncell {
    text-align: center;
    background-color: $light-gray;
}

.mydp .headerbtn,
.mydp .headerlabelbtn,
.mydp .yearchangebtn {
    background: transparent;
    border: none;
}

.mydp .headerlabelbtn {
    color: $white;
    outline: none;
    cursor: default;
    &.monthlabel {
        margin-right: 10px;
    }
}

.mydp,
.mydp .headertodaybtn {
    border: 1px solid $medium-gray;
}

.mydp .headertodaybtn {
    padding: 0 4px;
    font-size: 11px;
    height: 22px;
    min-width: 60px;
    max-width: 84px;
    overflow: hidden;
    white-space: nowrap;
}

.mydp button::-moz-focus-inner {
    border: 0;
}

.mydp .headerbtn:focus,
.mydp .monthlabel:focus,
.mydp .yearlabel:focus {
    color: $primary-text-color-inverse;
    outline: none;
}

.mydp .daycell:focus,
.mydp .monthcell:focus,
.mydp .yearcell:focus {
    background-color: $primary-color-light-blue;
    color: $base-color;;
}

.mydp .icon-mydpcalendar,
.mydp .icon-mydpremove {
    font-size: 16px;
}

.mydp .icon-mydpleft,
.mydp .icon-mydpright,
.mydp .icon-mydpup,
.mydp .icon-mydpdown {
    color: $base-color;
    padding-top: 6px;
    padding-bottom: 6px;
    width: 40px;
}
.mydp .icon-mydpup,
.mydp .icon-mydpdown {
    color: $primary-text-color-inverse;
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 1.8rem;
}

.mydp .btndecrease .icon-mydpleft,
.mydp .btnincrease .icon-mydpright {
    font-size: 16px;
}

.mydp .icon-mydptoday {
    color: $neutral-dark-gray;
    font-size: 11px;
}

.mydp table {
    display: table;
    border-spacing: 0;
}

.mydp table td {
    padding: 0;
}

.mydp table,
.mydp th,
.mydp td {
    border: none;
}

.mydp .monthlabel,
.mydp .yearlabel,
.mydp .inputnoteditable,
.mydp .daycell,
.mydp .monthcell,
.mydp .yearcell {
    cursor: pointer;
}

.mydp .mydpicon {
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.mydp .icon-mydpright {
    float: right;
}

.mydp .icon-mydpleft {
    float: left;
}

//TOOD: Replace with PL fa classes
.mydp .icon-mydptoday:before {
    content: "\e802";
}

.mydp .icon-mydpup:before {
    content: "\f0d9";
}
.mydp .icon-mydpup, .mydp .icon-mydpdown {
    color: #0079C2;
    font-family: 'FontAwesome';
    font-size: 1.389rem;
}
    .mydp .icon-mydpup:focus, .mydp .icon-mydpdown:focus {
    color: #333;
    outline: none;
}
.mydp .icon-mydpdown:before {
    content: "\f0da";
}

.mydp .icon-mydpremove:before {
    content: "\e806";
}

.mydp .fa-calendar {
    line-height: 1;
    border-bottom: none;
} 

my-date-picker {
    width: auto;
    line-height: 1;
    position: absolute;
    bottom: $sph05;
    right: 0;
    .mydp {
        line-height: inherit;
        border: none;

        .btnpicker {
            color: $primary-color-light-blue;
            height: auto;
            width: auto;

        }
    }
}

.month-picker{
    .month-picker__button {
        border-bottom: none;
        padding: 0px;
        .fa-calendar{
            font-size:2.5rem;
        }
    }
    .month-picker--calendar-wrapper{
        position:relative;
        .ui-datepicker{
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            td span, td a {
                padding: .6rem;
            }
        .ui-datepicker-title{
            margin:0;
        }

        .ui-datepicker-year-selector--container{
            text-align: center;
            background-color:$base-color;
            color:black;
            padding-top: 1rem;
            padding-bottom: .4rem;
            a{
            border-bottom: none;
            }
            .ui-datepicker-year--display{
                margin:0 20px;
            }
            .fa{
                color:black;
            }
        }
        }
    }
}

// Anthem Overrides
.ant-date-picker__date-text-labels {
    min-width: calc(100% - 80px);
    font-size: 1rem;
    display: flex;
    justify-content: center;
}

//date-picker position
.date-picker-cont {
    position: relative;
    right: 10px;
    top: 10px;
}