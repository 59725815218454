@import "../../../src/fwc-assets/styles/components/fwc-header-page";
// Standard Page Header
.page-header {
  &-wrapper {
    background-image: url('../images/page-header-bg-center.png');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-color: $ant-motif-highlight-ocean;
    display: flex;
    flex-direction: column;
    position: relative;

    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('../images/page-header-bg-left.png');
      background-position: left bottom;
      background-repeat: no-repeat;
    }
    &:after{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('../images/page-header-bg-right.png');
      background-position: right bottom;
      background-repeat: no-repeat;
    }
  }

  &-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    color: $color-white;
    h1 {
      font-weight: normal;
    }
    .page-head {
      font-family: $font-family-Montserrat;
      color: $color-white;
      font-size: $font-size-h1;
      font-weight: 500;
    }

    .page-header-text-container {
      display: none;

      @media only screen and (min-width: $breakpoint-sm) {
        align-items: center;
        color: $color-white;
        display: flex;
        flex-direction: row;
        text-align: right;

        p,
        a {
          color: $color-white;
          margin: 0px;
        }

        .page-header-icon {
          font-size: 2.7rem;
          margin-left: 1.75rem;
        }
      }
    }
  }
}

.back-action-wrapper {
  padding: 20px 0;
  .fa-long-arrow-left {
    color: $color-primary;
    font-size: 14px;
    font-size: 28px;
    margin-right: 10px;
  }
  .back-text {
    color: $color-primary;
    font-size: 14px;
    font-weight: bold;
  }
}
.motif-page-header-container {
  padding-left: 15px;
  padding-right: 15px;
  background: var(--color-primary);
  @media screen and (min-width: $breakpoint-sm) {
    width: 83.333%;
    max-width: 1170px;
    margin: 0 auto;
    float: none;
  }
  h1 {
    font-size: calc(4.75 * var(--fwcu));
    line-height: calc(5.75 * var(--fwcu));
    font-weight: 600;
    font-family: var(--font-serif, sans-serif);
    color: white;
    background: var(--color-primary);
  }
  @media screen and (max-width: 600px) and (hover: none) and (pointer: coarse) {
    h1 {
      font-size: calc(3.75*var(--fwcu));
      line-height: calc(4.75*var(--fwcu));
    }
  }
  .crumb-backdrop {
    background-color: var(--color-white);
  }
}
.crumb-backdrop {
  background: var(--color-white);
}
